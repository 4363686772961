import { Store } from './main'
import UserHelper from '@/helpers/UserHelper'

interface UserWrapper {
    preFetch: boolean,
    user: any | null,
    darkMode: boolean | null
}

class UserStore extends Store<UserWrapper> {
  protected data (): UserWrapper {
    return {
      preFetch: false,
      user: null,
      darkMode: null
    }
  }

    /**
     * Read user data from local storage
     */
    fetchData = async () => {
      const user = UserHelper.getUser()
      userStore.setData(user)
    }

    setData (user: any) {
      this.state.user = user
    }

    setPreFetch (prefetch: boolean) {
      this.state.preFetch = prefetch
    }

    setDarkMode (value: boolean) {
      this.state.darkMode = value
    }

    clear () {
      this.state.user = null
      this.state.preFetch = false
    }
}

export const userStore: UserStore = new UserStore()
