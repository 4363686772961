import { Store } from './main'
import Utils from '@/helpers/Utils'

interface UnreadNotificationWrapper {
    preFetch: boolean,
    number: number
}

class UnreadNotificationStore extends Store<UnreadNotificationWrapper> {
  protected data (): UnreadNotificationWrapper {
    return {
      preFetch: false,
      number: 0
    }
  }

    fetchData = () => {
      return new Promise((resolve, reject) => {
        Utils.request()
          .get('/count-unread-notifications/')
          .then((response) => {
            this.state.preFetch = true
            unreadNotificationStore.setData(response.data)
            resolve(true)
          })
          .catch((error) => {
            if (error.response){
              Utils.showErrorMessage(error.response)
            }
            if ( typeof(reject) != "undefined" ){
              console.log("reject", reject)
              reject(true)
            }
            
          })
      })
    }

    setData (number: number) {
      this.state.number = number
    }

    clear () {
      this.state.number = 0
      this.state.preFetch = false
    }
}

export const unreadNotificationStore: UnreadNotificationStore = new UnreadNotificationStore()
