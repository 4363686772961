<template>
          <Multiselect
            v-model="state.value"
            :createTag="false"
            :placeholder="props.placeholder || 'Select or input new tag'"
            :searchable="true"
            mode="tags"
            :options="state.options"
          />
</template>

<script>

import { reactive, onMounted } from 'vue'
// import Utils from '@/helpers/Utils';
import Config from '@/helpers/Config'
import Multiselect from '@vueform/multiselect'
import Utils from '@/helpers/Utils'
import Services from '@/helpers/Services'
export default {
  name: 'SelectContactTag',
  components: {
    Multiselect
  },
  props: {
    init_tags: Array,
    init_contact_email: String,
    placeholder: String,
    options: Array
  },
  setup (props) {
    const state = reactive({
      fetching: false,
      value: Utils.clone(props.init_tags),
      options: {}

    })

    onMounted(() => {
      Services.listContact().then(response => {
        const temp = {}
        response.forEach(element => {
          temp[element.id] = element.name
          if (props.init_contact_email && element.email === props.init_contact_email && state.value.length === 0) {
            state.value.push(element.id)
          }
        })
        state.options = temp
      })
    })

    return {
      state,
      Config,
      props
    }
  }
}
</script>
