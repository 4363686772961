import router from '@/router'
import { unreadNotificationStore } from '@/stores/UnreadNotificationStore'
import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'
import LocalStorage from '@/helpers/LocalStorage'
import Config from '@/helpers/Config'

const logout = () => {
  setUser(null)
  setToken('')
  unreadNotificationStore.clear()
  scheduleListViewStore.reset()

  // Remove dark mode if it is turned on
  const body = document.getElementsByTagName('body')[0]
  body.classList.remove('dark')

  router.push({
    name: 'SignIn'
  })
}

function setToken (token: string): void {
  LocalStorage.set(Config.LOCAL_TOKEN_KEY, token)
}

function getToken (): string {
  return LocalStorage.get(Config.LOCAL_TOKEN_KEY)
}

function setDarkMode (value: boolean): void {
  LocalStorage.setObject(Config.LOCAL_DARKMODE_KEY, value)
}

function getDarkMode (): any {
  return LocalStorage.getObject(Config.LOCAL_DARKMODE_KEY)
}

function setUser (user?: object | null): void {
  LocalStorage.setObject(Config.LOCAL_USER_KEY, user)
}

function getUser (): any {
  return LocalStorage.getObject(Config.LOCAL_USER_KEY)
}

function getUserTimezoneOffset (): string {
  const user = LocalStorage.getObject(Config.LOCAL_USER_KEY)
  return user.profile.timezone_offset
}

export default {
  logout,
  setToken,
  getToken,
  setUser,
  getUser,
  getUserTimezoneOffset,
  setDarkMode,
  getDarkMode
}
