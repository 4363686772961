<template>
  <span class="badge bg-success" v-if="props.status =='GOOD'" >Good</span>
  <span class="badge bg-warning" v-else-if="props.status =='WARNING'" >Warning</span>
  <span class="badge bg-danger" v-else-if="props.status =='ERROR'" >Error</span>

</template>

<script>
import {

  defineComponent
} from 'vue'

export default defineComponent({
  name: 'StatusIndicator',
  props: {
    status: String
  },
  setup (props) {
    return {
      props
    }
  }
})
</script>
