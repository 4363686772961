<template>
   <div class="row">
      <div class="col-12 mb-4">
        <p class="mb-1">Subject:</p><input type="text" required  v-model="state.form_data.subject" class="form-control">
      </div>
      <div class="col-12 mb-4">
        <p class="mb-1" >Content:</p><textarea  required  v-model="state.form_data.content" class="form-control"></textarea>
      </div>
      <div class="col-12 mb-4">
        <p class="mb-1">Recipients:</p>
        <SelectEmailTag ref="selectEmailTag" :placeholder="'Select emails'" :init_tags="state.form_data.emails" />
      </div>
   </div>
</template>

<script>
// This is for Daily, Hourly and Minutely
import { defineComponent, reactive, ref } from 'vue'
import SelectEmailTag from '@/views/components/SelectEmailTag'
export default defineComponent({
  name: 'SendEmail',
  components: {
    SelectEmailTag
  },
  props: {
    options: Object
  },
  setup (props) {
    const defaultData = {
      subject: '',
      content: '',
      emails: []
    }

    const getValue = () => {
      const result = {}
      for (const key in defaultData) {
        result[key] = state.form_data[key]
      }
      result.emails = selectEmailTag.value.state.value
      if (result.emails.length === 0) {
        throw new Error('Recipients is required')
      }

      return result
    }

    const formData = {
      ...defaultData,
      ...props.options
    }

    const state = reactive({
      form_data: formData
    })
    const selectEmailTag = ref()
    return { props, state, getValue, selectEmailTag }
  }
})
</script>
