<template>

  <HistoryResult v-if="state.history_id" :history_id="state.history_id" v-on:close-form="closeForm"></HistoryResult>

  <div  class="empty-result" v-if="state.list.length == 0 && !state.working" >
    <img src="/images/empty-box.svg" />
    <p>No results yet</p>
  </div>

  <div v-else >
      <table class="mobile-table table" v-bind:class="{'list-uptime-for-schedule':props.schedule_id}">
          <thead>
            <Column :schedule_id="props.schedule_id" ></Column>
          </thead>

          <tbody>

            <Skeleton :count_column="state.count_column"  v-if="state.working" ></Skeleton>
            <tr v-else v-for="item in state.list" :key="item.id">
              <td>
                <i class="bi bi-arrow-up-circle-fill icon-green" v-if="item.category =='UP'" ></i>
                <i class="bi bi-arrow-down-circle-fill icon-red" v-if="item.category =='DOWN'" ></i>
              </td>
              <td >
                {{Utils.localReadableTime(item.created)}}
              </td>
              <td>
                {{Utils.localReadableTime(item.updated)}}
              </td>
              <td>
                {{item.diff}}
              </td>
              <td>
                <a class="btn btn-outline-primary btn-sm" v-if="item.history" v-on:click="viewHistory(item)" >
                View
                </a>
              </td>

            </tr>
          </tbody>
      </table>
  </div>
      <Pagination
        v-if="!state.working"
        :data="state.pagination"
        v-on:update:page="setPage"
      ></Pagination>

</template>

<script>
import {
  defineComponent,
  reactive,
  onMounted
  //   ref,
} from 'vue'

import Column from '@/views/components/UptimeLogList/Column'

import Pagination from '@/views/components/Pagination.vue'

import HistoryResult from '@/views/components/HistoryResult.vue'

import Skeleton from '@/views/components/Skeleton.vue'

import Config from '@/helpers/Config'
import Utils from '@/helpers/Utils'

export default defineComponent({
  name: 'NotificationEmbbed',

  components: {
    Column,
    Pagination,
    Skeleton,

    HistoryResult

  },
  props: {
    schedule_id: String
  },
  setup (props) {
    const state = reactive({
      working: false,
      http_result: null,
      count_column: props.schedule_id ? 3 : 4,
      pagination: reactive({
        limit: props.schedule_id ? Config.SHORT_LIMIT : Config.LIMIT,
        current_page: 1,
        total_entries: 0
      }),
      list: []
    })

    const fetchList = () => {
      if (state.working) {
        return
      }
      state.working = true

      const formData = {
        limit: state.pagination.limit,
        offset: (state.pagination.current_page - 1) * state.pagination.limit
      }

      if (props.schedule_id) {
        formData.schedule = props.schedule_id
      }

      Utils.request()
        .get('/changelog/?' + Utils.serialize(formData))
        .then((response) => {
          state.pagination.total_entries = response.data.count
          var processedList = response.data.results
          processedList.map(item => {
            item.diff = Utils.dateDiffReadable(item.updated, item.created)
            return item
          })
          state.list = processedList
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.working = false
        })
    }
    const setPage = (page) => {
      state.pagination.current_page = page
      fetchList()
    }

    onMounted(() => {
      fetchList()
    })

    const closeForm = () => {
      state.history_id = null
    }

    const viewHistory = (item) => {
      state.history_id = item.history.id
      console.log('state.history_id', state.history_id)
    }

    return {
      state,
      setPage,
      Utils,
      fetchList,
      props,
      viewHistory,
      closeForm
    }
  }
})
</script>
