
import { Contact } from '@/models/interfaces'
import Alert from './Alert'
import Utils from './Utils'

function removeSchedule (schedule: any) {
  return new Promise((resolve, reject) => {
    Alert.confirm('Once deleted, you are not able to recover', 'Are you sure ?').then((willDelete) => {
      if (willDelete) {
        Alert.loading('Deleting')
        Utils.request()
          .delete(`/schedule/${schedule.id}/`)
          .then(() => {
            resolve(null)
          })
          .catch((error) => {
            Utils.showErrorMessage(error.response)
            reject()
          })
          .finally(() => {
            Alert.close()
          })
      }
    })
  })
}

function updateSchedule (schedule: any): Promise<any> {
  Alert.loading('Working')
  return new Promise((resolve, reject) => {
    Utils.request()
      .put(`/schedule/${schedule.id}/`, schedule)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        Utils.showErrorMessage(error.response)
        reject()
      })
      .finally(() => {
        Alert.close()
      })
  })
}

function removeContact (contact: any): Promise<any> {
  return new Promise((resolve, reject) => {
    Alert.confirm('Once deleted, you are not able to recover', 'Are you sure ?').then((willDelete) => {
      if (willDelete) {
        Alert.loading('Deleting')
        Utils.request()
          .delete(`/contact/${contact.id}/`)
          .then(() => {
            resolve(null)
          })
          .catch((error) => {
            Utils.showErrorMessage(error.response)
            reject()
          })
          .finally(() => {
            Alert.close()
          })
      }
    })
  })
}

function listContact ():Promise<Contact[]> {
  const formData = {
    ordering: '-created'
  }

  return new Promise((resolve, reject) => {
    Utils.request()
      .get('/contact/?' + Utils.serialize(formData))
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        Utils.showErrorMessage(error.response)
        reject()
      })
  })
}

function updateContact (contact: any): Promise<any> {
  return new Promise((resolve, reject) => {
    Utils.request()
      .put(`/contact/${contact.id}/`, contact)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        Utils.showErrorMessage(error.response)
        reject()
      })
  })
}

function availableEmails () {
  const formData = {

  }

  return Utils.request()
    .get('/available-emails/?' + Utils.serialize(formData))
}

export default {
  removeSchedule,
  removeContact,
  updateSchedule,
  listContact,
  updateContact,
  availableEmails
}
