<template>

    <div class="col-8 col-md-4">
      <p class="mb-1" >Every</p>
      <div class="form-group row align-items-center">

        <div class="col-lg-6 col-6">
          <input
            type="number"
            placeholder="months"
            required
            class="form-control"
            v-model="state.formData.INTERVAL"
          />
        </div>
        <div class="col-lg-2 col-2">
          <label class="col-form-label">month(s)</label>
        </div>
      </div>
    </div>

  <div class="row mt-3">
    <div class="col-6 col-md-2">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          value="BYMONTHDAY"
          v-model="state.formData.mode"
          id="BYMONTHDAY"
        />
        <label class="form-check-label" for="BYMONTHDAY"> On </label>
      </div>
    </div>
    <div class="col-6 col-md-2">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          id="NOTBYMONTHDAY"
          v-model="state.formData.mode"
          value="NOTBYMONTHDAY"
        />
        <label class="form-check-label" for="NOTBYMONTHDAY"> On the </label>
      </div>
    </div>
  </div>
  <!-- end row -->
  <div class="row mt-3" v-if="state.formData.mode == 'BYMONTHDAY'">
    <div class="col-3">
      <select
        class="form-select"
        v-model="state.formData.BYMONTHDAY"
      >
        <option v-for="day in 31" :key="day" v-bind:value="day">
          {{ day }}
        </option>
      </select>
    </div>
  </div>
  <!-- end row -->
  <div class="row mt-3" v-if="state.formData.mode == 'NOTBYMONTHDAY'">
    <div class="col-3">
      <select class="form-select" v-model="state.formData.BYSETPOS">
        <option
          v-for="(position_text, postion) in Config.RRULE_MAP_POSITION"
          :key="postion"
          v-bind:value="postion"
        >
          {{ position_text }}
        </option>
      </select>
    </div>
    <div class="col-3">
      <select class="form-select" v-model="state.formData.BYDAY">
        <option
          v-for="(day_text, day) in Config.RRULE_MAP_DAY"
          :key="day"
          v-bind:value="day"
        >
          {{ day_text }}
        </option>
      </select>
    </div>

  </div>
  <!-- end row -->
</template>

<script>
import { defineComponent, reactive } from 'vue'
import Config from '@/helpers/Config'
export default defineComponent({
  name: 'MONTHLY',
  props: {
    data: Object
  },
  setup (props) {
    let formData = {
      mode: 'BYMONTHDAY',
      repeat_type: 'YEARLY',
      BYMONTHDAY: Config.RRULE_DEFAULT_MONTHDAY,
      BYSETPOS: Config.RRULE_DEFAULT_POSITION,
      BYDAY: Config.RRULE_DEFAULT_DAY,
      INTERVAL: Config.DEFAULT_INTERVAL
    }

    formData = {
      ...formData,
      ...props.data
    }
    if (props.data.BYDAY) {
      formData.mode = 'NOTBYMONTHDAY'
    }

    const getValue = () => {
      const result = {
        FREQ: 'MONTHLY',
        INTERVAL: formData.INTERVAL
      }

      if (formData.mode === 'BYMONTHDAY') {
        result.BYMONTHDAY = formData.BYMONTHDAY
      } else {
        result.BYDAY = formData.BYDAY
        result.BYSETPOS = formData.BYSETPOS
      }

      return result
    }

    const state = reactive({
      working: false,
      formData
    })
    return { state, Config, getValue }
  }
})
</script>
