<template>
  <AnonymousLayout>
    <h1 class="auth-title">
      Set new password
    </h1>
    <form
      autocomplete="off"
      @submit.prevent="setPassword"
    >
      <p class="auth-subtitle mb-4">
        Hi <b>{{ currentLocation.params.email }}</b>, please fill below fields to process reset new password
      </p>
      <div class="form-group position-relative has-icon-left mb-4">
        <input
          ref="passwordRef"
          v-model="state.password"
          type="password"
          name="new-password"
          class="form-control form-control-xl"
          required
          placeholder="New Password"
        >
        <div class="form-control-icon">
          <i class="bi bi-shield-lock" />
        </div>
      </div>

      <div class="form-group position-relative has-icon-left mb-4">
        <input
          v-model="state.confirm_password"
          type="password"
          class="form-control form-control-xl"
          name="confirm-new-password"
          required
          placeholder="Confirm New Password"
        >
        <div class="form-control-icon">
          <i class="bi bi-shield-lock" />
        </div>
      </div>

      <button
        class="btn btn-primary btn-block btn-lg shadow-lg mt-5 vertical-center"
        type="submit"
        :disabled="state.working"
      >
        <Spinner :show="state.working" />
        &nbsp; Set Password
      </button>

      <div
        v-if="state.errors.length"
        class="list-errors mt-3"
      >
        <p>Please correct the following error(s):</p>
        <ul>
          <li
            v-for="error in state.errors"
            :key="error"
          >
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="text-center mt-5 text-lg fs-5">
        <p class="text-gray-600">
          Already have an account? <router-link
            class="font-bold"
            :to="{ name: 'SignIn' }"
          >
            <i class="fa fa-sign-in-alt fa-lg" />&nbsp;&nbsp;Sign In
          </router-link><br>.
        </p>
      </div>
    </form>
  </AnonymousLayout>
</template>

<script>
import {
  defineComponent,
  reactive,
  computed,
  ref,
  onMounted
} from 'vue'
import {
  useRoute
} from 'vue-router'
import Utils from '@/helpers/Utils'
import AnonymousLayout from '@/views/layouts/Anonymous.vue'
import Alert from '@/helpers/Alert'
import Spinner from '@/views/components/Spinner'

export default defineComponent({
  name: 'SetNewPassword',
  components: {
    AnonymousLayout,
    Spinner
  },
  setup () {
    // const delay = (t) => new Promise(resolve => setTimeout(resolve, t))
    const route = useRoute()
    const currentLocation = computed(() => {
      const {
        ...rest
      } = route
      return rest
    })

    const checkForm = () => {
      state.errors = []

      if (!state.password) state.errors.push('password is required.')
      if (state.password !== state.confirm_password) state.errors.push('Confirm password is not match with password.')

      return state.errors.length === 0
    }

    const state = reactive({
      email: '',
      password: '',
      working: false,
      errors: []
    })

    const setPassword = () => {
      // e.preventDefault();

      if (!checkForm() || state.working) {
        return
      }
      state.working = true
      const formData = {
        email: currentLocation.value.params.email,
        secret: currentLocation.value.params.secret,
        password: state.password
      }

      Utils.request(false).post('/user-password-reset/', formData).then(() => {
        state.password = ''
        state.confirm_password = ''
        // state = initData
        Alert.alert('Success', 'New password have been set')
      }).catch(error => {
        Utils.showErrorMessage(error.response)
      }).finally(() => {
        state.working = false
      })
    }

    const passwordRef = ref(null)

    onMounted(() => {
      passwordRef.value.focus()
    })

    // await delay(4000)
    return {
      passwordRef,
      currentLocation,
      state,
      setPassword
    }
  }
})
</script>
