import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Config from './helpers/Config';
import './styles/custom.scss'
import '@vueform/multiselect/themes/default.css'
import 'v-calendar/style.css';
import Spinner from "@/views/components/Spinner.vue";

const app = createApp(App)
app.use(router)

function getTitle (vm:any) {
    const { page_title } = vm.$options
 
    if (page_title) {
      let result = typeof page_title === 'function'
        ? page_title.call(vm)
        : page_title
        return Config.TITLE + " - " + result;
    }
}
// https://medium.com/@Taha_Shashtari/the-easy-way-to-change-page-title-in-vue-6caf05006863
app.mixin({
    created() {
        const title = getTitle(this)
        if (title) {
          document.title = title
        }
    }
  });

// global registration - can be used anywher
app.component('Spinner', Spinner) 


app.mount('#app')
