<template>
  <AnonymousLayout>
    <h1 class="auth-title">
      Forgot Password
    </h1>
    <p class="auth-subtitle mb-4">
      Input your email and we will send you reset password link.
    </p>

    <form
      autocomplete="off"
      @submit.prevent="reset"
    >
      <div class="form-group position-relative has-icon-left mb-4">
        <input
          ref="emailRef"
          v-model="state.email"
          type="email"
          class="form-control form-control-xl"
          name="email"
          required
          Placeholder="Your email"
        >
        <div class="form-control-icon">
          <i class="bi bi-envelope" />
        </div>
      </div>

      <button
        class="btn btn-primary btn-block btn-lg shadow-lg mt-5"
        type="submit"
        :disabled="state.working"
      >
        <Spinner :show="state.working" />Reset
      </button>
    </form>
    <div class="text-center mt-5 text-lg fs-5">
      <p class="text-gray-600">
        Remember your account? <router-link
          class="font-bold"
          :to="{ name: 'SignIn' }"
        >
          Sign In
        </router-link>.
      </p>
    </div>
  </AnonymousLayout>
</template>

<script>
import AnonymousLayout from '@/views/layouts/Anonymous.vue'
import {
  defineComponent,
  reactive,
  ref,
  onMounted
} from 'vue'
import Utils from '@/helpers/Utils'
import Alert from '@/helpers/Alert'

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    AnonymousLayout
  },
  setup () {
    const state = reactive({
      email: '',
      working: false
    })

    const reset = () => {
      // e.preventDefault();

      if (state.working) {
        return
      }
      state.working = true
      const formData = {
        email: state.email
      }
      Utils.request(false).post('/user-password-reset-request/', formData).then(() => {
        state.email = ''
        Alert.alert('Success', 'Please check your email for further construct')
      }).catch(error => {
        Utils.showErrorMessage(error.response)
      }).finally(() => {
        state.working = false
      })
    }
    const emailRef = ref(null)

    onMounted(() => {
      emailRef.value.focus()
    })

    return {
      state,
      emailRef,
      reset
    }
  }
})
</script>
