<template>
  <MainLayout>

    <div class="page-title mb-3">

          <h3 class="inline-block" >Import/Export</h3>

    </div>
    <section class="section status-overview force-icon-left">
    <input
      type="file"
      id="csv-file"
      style="display: none"
      ref="csvFileRef"
      accept=".csv"
      v-on:change="handleFileUpload('uptime-robot')"
    />

    <input
      type="file"
      id="json-file"
      style="display: none"
      ref="jsonFileRef"
      accept=".json"
      v-on:change="handleFileUpload('import-json')"
    />

      <div class="row">
          <div class="col-12">
            <div class="card">
                <div class="card-body pointer px-1 py-3" v-on:click="exportSchedules">

                      <div class="stats-icon blue mx-3">
                          <i class="bi bi-cloud-arrow-down"></i>
                      </div>
                      <div >
                        <h6 class="text-muted font-semibold">Export
            monitors</h6>
                      <small class="mb-0">Export all monitors to json file</small>
                      </div>

                  </div>

            </div>
          </div>
          <div class="col-12">
            <div class="card">
                <div class="card-body pointer px-1 py-3" v-on:click="$refs.jsonFileRef.click()">

                    <div class="stats-icon purple mx-3">
                        <i class="bi bi-cloud-arrow-up"></i>
                    </div>

                    <div>
                        <h6 class="text-muted font-semibold">Import monitors</h6>
                        <small class=" mb-0">Import from json file</small>
                    </div>
                </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card">
                <div class="card-body pointer px-1 py-3" v-on:click="$refs.csvFileRef.click()">

                    <div class="stats-icon purple mx-3">
                        <i class="bi bi-cloud-arrow-up"></i>
                    </div>

                    <div>
                        <h6 class="text-muted font-semibold">Import from Uptime Robot</h6>
                        <small class=" mb-0">Import monitors from Uptime Robot</small>
                    </div>
                </div>

            </div>
          </div>

          <div class="col-12">
            <div class="card">
                <div class="card-body pointer px-1 py-3" v-on:click="state.show_import_pingdom= true">

                  <div class="stats-icon purple mx-3">
                      <i class="bi bi-cloud-arrow-up"></i>
                  </div>

                  <div>
                      <h6 class="text-muted font-semibold">Import from Pingdom</h6>
                      <small class=" mb-0">Import uptime items from Pingdom</small>
                  </div>
                </div>

            </div>
          </div>

          <div class="col-12">
            <div class="card">
                <div class="card-body pointer px-1 py-3" v-on:click="resetSchedules">

                    <div class="stats-icon red mx-3">
                        <i class="bi bi-exclamation-circle"></i>
                    </div>

                    <div>
                        <h6 class="text-muted font-semibold">Reset data</h6>
                        <small class=" mb-0">Remove all your monitors</small>
                    </div>

                </div>
            </div>
          </div>

      </div>

       <ImportPingDom v-if="state.show_import_pingdom" v-on:close-form="onDoneImportPingDom"></ImportPingDom>

    </section>
  </MainLayout>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref
} from 'vue'

import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'
import { tagStore } from '@/stores/TagStore'
import MainLayout from '@/views/layouts/Main.vue'
import ImportPingDom from '@/views/home/ImportPingDom.vue'
import Utils from '@/helpers/Utils'
import Alert from '@/helpers/Alert'

export default defineComponent({
  page_title: 'Import  & export schedules',
  name: 'ImportExport',
  props: [],
  components: {
    MainLayout,
    ImportPingDom
  },
  setup () {
    const state = reactive({
      working: false,
      show_import_pingdom: false
    })

    const exportSchedules = () => {
      Utils.request()
        .get('/export-schedules/')
        .then((response) => {
          console.log(response)
          Utils.download(response.request.response, 'APIBOT_export.json')
        })
        .catch((error) => {
          console.log('error', error)
          Utils.showErrorMessage(error.response)
        })
    }

    const resetSchedules = () => {
      Alert.confirm(
        'This action will remove all your monitors and notifications',
        'Are you sure ?'
      ).then((willDelete) => {
        if (willDelete) {
          Alert.loading('Reseting')
          Utils.request()
            .delete('/reset-schedules')
            .then(() => {
              refreshStore().then(() => {
                scheduleListViewStore.resetHomeLoadListTimes()
              })
              Alert.showSuccessToast('Your monitors have been reseted')
            })
            .catch((error) => {
              Utils.showErrorMessage(error.response)
            })
            .finally(() => {
              Alert.close()
            })
        }
      })
    }

    const handleFileUpload = (type:string) => {
      const ref = type === 'uptime-robot' ? csvFileRef : jsonFileRef

      if (!ref || !ref.value) {
        return
      }
      const path = type === 'uptime-robot' ? '/import-uptimerobot/' : '/import-schedules/'

      const formData = new FormData()
      // eslint-disable-next-line no-use-before-define
      const refValue = ref?.value as any
      if (refValue != null && typeof (refValue.files) !== 'undefined') {
        formData.append('file', refValue.files[0])  // eslint-disable-line
      }

      Alert.loading('Importing')
      Utils.uploadFile(path, formData)
        .then((response) => {
          Alert.showSuccessToast(
            `${response.data.success} imported, ${response.data.failed} failed`
          )
          refreshStore()
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          Alert.close()
          state.working = false
          if (ref && ref.value) {
            const refValue = ref?.value as any
            refValue.value = null
          }
        })
    }

    const refreshStore = () => {
      return new Promise((resolve, reject) => {
        Promise.all([tagStore.fetchList(true), scheduleListViewStore.fetchList()]).then((values) => {
          scheduleListViewStore.setList(values[1])
          resolve(null)
        }).catch(() => {
          reject(new Error('Get list data fail'))
        })
      })
    }

    const importPingdom = () => {
      console.log('import pingdom')
      Alert.inputText('Input Pingdom API token').then((token) => {
        console.log('token', token)
      })
    }

    const onDoneImportPingDom = (refresh:boolean) => {
      console.log('refresh', refresh)
      state.show_import_pingdom = false
      if (refresh) {
        refreshStore()
      }
    }

    const csvFileRef = ref(null)
    const jsonFileRef = ref(null)

    return {
      state,
      exportSchedules,
      resetSchedules,
      importPingdom,
      onDoneImportPingDom,
      csvFileRef,
      jsonFileRef,
      handleFileUpload
    }
  }

})
</script>
