import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/home/Home.vue'
import History from './views/history/History.vue'
import Contact from './views/contact/Contact.vue'
import NewContact from './views/contact/New.vue'
import EditContact from './views/contact/Edit.vue'

import Notification from './views/notification/Notification.vue'
import ImportExport from './views/importExport/ImportExport.vue'
import ViewSchedule from './views/schedule/View.vue'
import NewSchedule from './views/schedule/New.vue'

import SignIn from './views/user/SignIn.vue'
import SignUp from './views/user/SignUp.vue'
import ForgotPassword from './views/user/ForgotPassword.vue'
import VerifyAccount from './views/user/VerifyAccount.vue'
import SetNewPassword from './views/user/SetNewPassword.vue'
import UpdateProfile from './views/user/UpdateProfile.vue'
import VerifyAlertContact from './views/user/VerifyAlertContact.vue'
import Pricing from './views/pricing/Pricing.vue'

import NotFound from './views/NotFound.vue'
// import Actions from './views/action/Actions.vue'
import MiddleWaves from '@/helpers/MiddleWaves'
import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'

const routerHistory = createWebHistory()
// const delay = (t) => new Promise(resolve => setTimeout(resolve, t))

const router = createRouter({
  history: routerHistory,
  routes: [

    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },
    {
      path: '/history',
      name: 'History',
      component: History,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },
    {
      path: '/notifications',
      name: 'Notifications',
      component: Notification,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },
    {
      path: '/import-export',
      name: 'ImportExport',
      component: ImportExport,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },

    {
      path: '/monitor/new',
      name: 'NewSchedule',
      component: NewSchedule,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: Contact,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },
    {
      path: '/contact/new',
      name: 'NewContact',
      component: NewContact,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },
    {
      path: '/contact/:id',
      name: 'EditContact',
      component: EditContact,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },
    {
      path: '/monitor/:id/:action?',
      name: 'ViewSchedule',
      component: ViewSchedule,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },
    {
      path: '/update-profile',
      name: 'UpdateProfile',
      component: UpdateProfile,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing,
      beforeEnter (to, from, next) {
        MiddleWaves.checkLogin(to, from, next)
        // next()
      }
    },

    // {
    //   path: '/contact',
    //   component: Contact
    // },
    { path: '/sign-in', name: 'SignIn', component: SignIn, props: true },
    { path: '/sign-up', name: 'SignUp', component: SignUp, props: true },
    { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword, props: true },
    { path: '/verify-account/:email/:secret', name: 'VerifyAccount', component: VerifyAccount, props: true },
    { path: '/verify-alert-contact/:email/:secret', name: 'VerifyAlertContact', component: VerifyAlertContact, props: true },
    { path: '/reset-new-password/:email/:secret', name: 'SetNewPassword', component: SetNewPassword, props: true },

    // { path: '/scenario-detail/:id', name: 'Actions', component: Actions, props: true },
    { path: '/:data(.*)', component: NotFound, name: 'NotFound' }
  ]
})

router.beforeEach((to, from) => {
  if (from.name == 'Home') {
    // store scroll position

    scheduleListViewStore.setScrollPosition(window.scrollY)
  }
})

export default router
