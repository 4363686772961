<template>
  <header class="mb-3 main-header">
    <a @click="toggleSidebar" class="pointer burger-btn d-inline-block d-xxl-none">
      <i class="bi bi-justify fs-3"></i>
    </a>
    <div class="float-end">

      <dropdown ref="profileDropdown" :ulClass="'dropdown-menu-end'">
          <template v-slot:slink>

            <a
              @click="profileDropdown.toogle()"
              class="profile-dropdown-link pointer"
            >

              <span class="align-vertical-center"
                >{{ state.user.user.first_name }} {{ state.user.user.last_name }}
                 &nbsp;
                 <img src="/images/no-image.svg" />
              </span>
            </a>
          </template>
          <template v-slot:sbody>
            <li class="dropdown-item">
              <router-link  :to="{ name: 'UpdateProfile' }" class="align-vertical-center" >
                <i class="bi bi-person"></i> &nbsp;&nbsp;Profile
              </router-link>
            </li>

            <li class="dropdown-item">
              <a class="align-vertical-center pointer" @click="UserHelper.logout()">
                <i class="bi bi-box-arrow-right"></i> &nbsp;&nbsp;Logout</a>
            </li>
          </template>
        </dropdown>
    </div>
  </header>
</template>

<script  lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import Dropdown from '@/views/components/Dropdown.vue'
import { userStore } from '@/stores/UserStore'
import UserHelper from '@/helpers/UserHelper'

export default defineComponent({
  name: 'HeaderNavBar',
  components: {
    Dropdown
  },
  setup () {
    const toggleSidebar = () => {
      const appEl = document.getElementById('sidebar')
      if (appEl) {
        appEl.classList.toggle('active')
      }
    }
    const profileDropdown = ref()
    const state = reactive({
      user: userStore.getState()
    })

    return {
      toggleSidebar,
      state,
      UserHelper,
      profileDropdown

    }
  }
})
</script>
