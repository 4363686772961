<template>
  <modal :dialogClass="'modal-xl modal-dialog-scrollable'" v-on:close-modal="closeModal">
    <template v-slot:modaltitle>
       Select contacts
    </template>
    <template v-slot:modalbody>
      <SelectContactTag ref="selectContactTag" :placeholder="'Select alert contact'" :init_tags="[]" />

      <button type="submit" class="btn btn-primary w-100 me-1  mt-5 mb-5" v-on:click="selectedContact">OK</button>

    </template>

  </modal>
</template>

<!-- notifications -->

<script>
// Reference from https://github.com/davidnotplay/vue-my-dropdown/blob/master/src/vue-my-dropdown.vue

import Modal from '@/views/components/Modal.vue'
import { reactive, ref } from 'vue'
import SelectContactTag from '@/views/components/SelectContactTag'
export default {
  name: 'SelectContactModal',
  components: {
    Modal,
    SelectContactTag
  },
  emits: ['close-form', 'selected-contacts'],
  setup (props, { emit }) {
    const state = reactive({
      working: false
    })
    const selectContactTag = ref()

    const closeModal = () => {
      emit('close-form')
    }

    const selectedContact = () => {
      const contacts = selectContactTag.value.state.value.map((item) => {
        return {
          id: item
        }
      })
      emit('selected-contacts', contacts)
      closeModal()
    }

    return {
      closeModal,
      props,
      state,
      selectContactTag,
      selectedContact
    }
  }
}
</script>
