<template>
  <ul class="nav nav-tabs"  role="tablist">
    <li class="nav-item" v-for="(label,index) in props.labels"  v-bind:key="index">
      <a
        v-on:click="state.selected_index = index"
        class="nav-link pointer"
        v-bind:class="{ active: index == state.selected_index }"
        >{{label}}</a
      >
    </li>

  </ul>
  <div class="tab-content" >
    <div class="tab-pane fade"
        v-bind:class="{ active: index == state.selected_index, show: index == state.selected_index }"
        v-for="(label,index) in props.labels"  v-bind:key="index">
        <slot :name="'tab_'+index"></slot>
    </div>

  </div>

</template>
<script>
import {
  reactive,
  defineComponent
} from 'vue'

export default defineComponent({
  name: 'Tab',
  props: {
    labels: Array
  },
  setup (props) {
    const state = reactive({
      selected_index: 0
    })
    return {
      props,
      state
    }
  }
})
</script>
