<template>
  <div id="sidebar" v-bind:class="{active:state.is_active}" >
    <div class="sidebar-wrapper active">

      <div class="sidebar-header">
        <div class="d-flex justify-content-between">
          <div class="logo">
            <router-link class="sidebar-link" :to="{ name: 'Home' }">
              <img src="/images/logo/logo.svg" class="white-logo" alt="Logo" srcset="" />
              <img src="/images/logo/logo-dark.svg" class="dark-logo" alt="Logo" srcset="" />
            </router-link>
          </div>
          <div class="toggler">
            <a
              href="#"
              class="sidebar-hide d-xxl-none d-block"
              @click="toggleSidebar"
              ><i class="bi bi-x bi-middle"></i
            ></a>
          </div>
        </div>

      </div>
      <div class="sidebar-menu">
        <ul class="menu">
          <li class="sidebar-item" v-bind:class="{active:currentRouteName=='Home'}" >
            <router-link class="sidebar-link" :to="{ name: 'Home' }">
              <i class="bi bi-alarm"></i>
              <span>Monitors</span>
            </router-link>
          </li>

          <li class="sidebar-item" v-bind:class="{active:currentRouteName=='History'}" >
            <router-link class="sidebar-link" :to="{ name: 'History' }">
              <i class="bi bi-clock-history"></i>
              <span>History</span>
            </router-link>
          </li>

          <li class="sidebar-item" v-bind:class="{active:currentRouteName=='Notifications'}" >
            <router-link class="sidebar-link" :to="{ name: 'Notifications' }">
              <i class="bi bi-bell"></i>
              <span>Notifications </span>
              <span v-if="state.unread_notification.number > 0">({{ state.unread_notification.number }})</span>
            </router-link>

          </li>

          <li class="sidebar-item" v-bind:class="{active:currentRouteName=='Contacts'}"
          v-if="state.user.user && state.user.user.profile.user_plan.enabled_contacts"
           >
            <router-link class="sidebar-link" :to="{ name: 'Contacts' }">
              <i class="bi bi-people"></i>
              <span>Contacts </span>
            </router-link>
          </li>

          <li class="sidebar-item" v-bind:class="{active:currentRouteName=='ImportExport'}" >
            <router-link class="sidebar-link" :to="{ name: 'ImportExport' }">
              <i class="bi bi-arrow-left-right"></i>
              <span>Import/Export</span>
            </router-link>
          </li>

          <li class="sidebar-item"  >
            <a class="sidebar-link pointer" v-on:click="toggleDarkMode" >
              <i v-if="state.user.darkMode == false" class="bi bi-toggle-off"></i>
              <i v-else class="bi bi-toggle-on icon-dark"></i>
              <span>Dark mode </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <router-link class="plan-text pointer vertical-center" :to="{ name: 'Pricing' }">
          <i class="bi bi-box-seam"></i> &nbsp; {{ state.user.user.profile.user_plan.name }}
        </router-link>

        <!-- <a class="plan-text pointer vertical-center" v-on:click="showPlan">
          <i class="bi bi-box-seam"></i> &nbsp; {{ state.user.user.profile.user_plan.name }}</a> -->
      </div>

      <button class="sidebar-toggler btn x"><i data-feather="x"></i></button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted } from 'vue'

import Utils from '@/helpers/Utils'
import { unreadNotificationStore } from '@/stores/UnreadNotificationStore'
import { userStore } from '@/stores/UserStore'
import UserHelper from '@/helpers/UserHelper'
import Alert from '@/helpers/Alert'
export default defineComponent({
  name: 'LeftSideBar',
  components: {

    // UserPlan
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  setup () {
    const applyDarkModel = (darkMode:boolean) => {
      const body = document.getElementsByTagName('body')[0]

      if (darkMode) {
        body.classList.add('dark')
      } else {
        body.classList.remove('dark')
      }
    }

    const refreshUser = () => {
      Utils.request()
        .get('/user/')
        .then((response) => {
          const user = response.data.results[0]
          userStore.setData(user)
          userStore.setPreFetch(true)
          UserHelper.setUser(user)
        })
        .catch((error) => {
          console.log('error', error)
          // invalid token
          if (error.response && error.response.status === 403) {
            UserHelper.logout()
          }
          if (error.response){
            Utils.showErrorMessage(error.response)
          }else{
            Alert.showErrorToast(error.messsage ?? "There is an error when fetching logged in user")
          }
          
        })
    }

    const user = userStore.getState()
    if (!user.preFetch) {
      userStore.fetchData()
      let darkMode = UserHelper.getDarkMode()
      darkMode = darkMode == null ? false : darkMode
      userStore.setDarkMode(darkMode)
      applyDarkModel(darkMode)

      // refresh user from server
      refreshUser()
    }

    const state = reactive({
      user: userStore.getState(),
      user_plan: null,
      is_active: window.innerWidth > 1400,

      unread_notification: unreadNotificationStore.getState()
    })

    const toggleSidebar = (event:any) => {
      event.preventDefault()

      const appEl = document.getElementById('sidebar')
      if (appEl) {
        appEl.classList.toggle('active')
      }
    }

    onMounted(() => {
      if (!unreadNotificationStore.getState().preFetch) {
        unreadNotificationStore.fetchData()
      }
    })

    const toggleDarkMode = () => {
      console.log('toggle darkmode')
      const darkMode = !state.user.darkMode
      UserHelper.setDarkMode(darkMode)
      userStore.setDarkMode(darkMode)
      applyDarkModel(darkMode)
    }

    return {
      toggleDarkMode,
      state,
      toggleSidebar
      // showPlan

    }
  }
})
</script>
