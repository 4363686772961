<template>
<div class="dropdown"  v-bind:class="state.fullClass">
  <slot name="slink"></slot>
  <ul class="dropdown-menu" v-bind:class="state.ulClass">
    <slot name="sbody"></slot>
  </ul>
</div>
</template>

<!-- notifications -->

<script>
// Reference from https://github.com/davidnotplay/vue-my-dropdown/blob/master/src/vue-my-dropdown.vue
import {
  computed,
  reactive,
  onUnmounted
} from 'vue'

export default {
  name: 'mydropdown',
  props: {
    tag: String,
    initClass: String,
    ulClass: String
  },
  setup (props) {
    const state = reactive({
      openClass: '',
      tag: props.tag ?? 'div',
      isShown: computed(() => (state.openClass === 'show')),
      fullClass: computed(() => (props.initClass ?? '') + ' ' + state.openClass),
      ulClass: computed(() => (props.ulClass ?? '') + ' ' + state.openClass)
    })
    const toogle = () => {
      if (state.openClass) {
        state.openClass = ''
        window.document.removeEventListener('click', toogle)
      } else {
        state.openClass = 'show'
        setTimeout(() => {
          window.document.addEventListener('click', toogle)
        }, 10)
      }
    }
    onUnmounted(() => {
      // Utils.log('un mounted')
      document.removeEventListener('click', toogle)
    })

    return {
      state,
      toogle
    }
  }
}
</script>
