<template>
  <div class="status-overview">

    <div class="row">

      <div class="col-6 col-lg-3 col-md-6 pointer" v-on:click="setFilter('latest_history_status_list','GOOD')">
            <div class="card">
                <div class="card-body px-3 py-3">
                    <div class="row">
                        <div class="col-4">
                            <div class="stats-icon green">
                                <i class="bi bi-check"></i>
                            </div>
                        </div>
                        <div class="col-8">
                            <h6 class="text-muted font-semibold">Good</h6>
                            <h6 class="font-extrabold mb-0">{{state.map_status.count.good}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <div class="col-6 col-lg-3 col-md-6 pointer" v-on:click="setFilter('latest_history_status_list','WARNING')" >
            <div class="card">
                <div class="card-body px-3 py-3">
                    <div class="row">
                        <div class="col-4">
                            <div class="stats-icon purple">
                                <i class="bi bi-exclamation-triangle"></i>
                            </div>
                        </div>
                        <div class="col-8">
                            <h6 class="text-muted font-semibold">Warning</h6>
                            <h6 class="font-extrabold mb-0">{{state.map_status.count.warning}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <div class="col-6 col-lg-3 col-md-6 pointer" v-on:click="setFilter('latest_history_status_list','ERROR')">
            <div class="card">
                <div class="card-body px-3 py-3">
                    <div class="row">
                        <div class="col-4">
                            <div class="stats-icon red">
                                <i class="bi bi-patch-exclamation"></i>
                            </div>
                        </div>
                        <div class="col-8">
                            <h6 class="text-muted font-semibold">Error</h6>
                            <h6 class="font-extrabold mb-0">{{state.map_status.count.error}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <div class="col-6 col-lg-3 col-md-6 pointer"  v-on:click="setFilter('enabled_list','false')" >
            <div class="card">
                <div class="card-body px-3 py-3">
                    <div class="row">
                        <div class="col-4">
                            <div class="stats-icon gray">
                                <i class="bi bi-pause-circle"></i>
                            </div>
                        </div>
                        <div class="col-8">
                            <h6 class="text-muted font-semibold">Disabled</h6>
                            <h6 class="font-extrabold mb-0">{{state.map_status.count.disabled}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- <div class="progress progress-sm">
      <div
        class="progress-bar bg-success"
        role="progressbar"
         :style="state.map_status.style.good"
        aria-valuenow="30"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>

      <div
        class="progress-bar bg-warning"
        role="progressbar"
        :style="state.map_status.style.warning"
        aria-valuenow="20"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
      <div
        class="progress-bar bg-danger"
        role="progressbar"
        :style="state.map_status.style.error"
        aria-valuenow="20"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
      <div
        class="progress-bar bg-disabled"
        role="progressbar"
         :style="state.map_status.style.disabled"
        aria-valuenow="15"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div> -->
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'
export default defineComponent({
  name: 'StatusOverview',
  props: {
    schedules: Array
  },
  setup (props, { emit }) {
    const state = reactive({
      map_status: computed(() => {
        const total = props.schedules.length

        const count = {
          good: 0,
          warning: 0,
          error: 0,
          disabled: 0
        }
        props.schedules.forEach(item => {
          if (!item.enabled) {
            count.disabled++
          } else if (!item.latest_history || item.latest_history.category === 'GOOD') {
            count.good++
          } else if (item.latest_history.category === 'ERROR') {
            count.error++
          } else {
            count.warning++
          }
        })

        const style = {
          good: `width: ${(count.good / total) * 100}%`,
          warning: `width: ${(count.warning / total) * 100}%`,
          error: `width: ${(count.error / total) * 100}%`,
          disabled: `width:  ${(count.disabled / total) * 100}%`
        }

        const result = {
          count: count,
          style: style
        }

        return result
      })
    })

    const setFilter = (key, value) => {
      const search = scheduleListViewStore.state.search
      search[key] = [value]

      scheduleListViewStore.setSearch(search)
      emit('search')
    }

    return {
      props,
      state,
      setFilter
    }
  }
})
</script>
