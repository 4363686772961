<template>
  <modal :dialogClass="'modal-xl modal-dialog-scrollable'" v-on:close-modal="closeModal">
    <template v-slot:modaltitle>
       History Result
    </template>
    <template v-slot:modalbody>
        <Loading v-if="!state.options" :text="'Loading log'"></Loading>
        <div v-else>
          <div v-if="state.options.message">
            <h6 class="font-extrabold font-semibold mt-3">Message</h6>
            <h6 class="text-muted mb-0">{{state.options.message}}</h6>
          </div>
          <div v-if="state.options.content_type">
            <h6 class="font-extrabold font-semibold">Content type</h6>
            <h6 class="text-muted mb-0">{{state.options.content_type}}</h6>
          </div>
          <div v-if="state.options.time">
            <h6 class="font-extrabold font-semibold mt-3">Time</h6>
            <h6 class="text-muted mb-0">{{state.options.time}} (second)</h6>
          </div>

          <div v-if="state.options.size">
            <h6 class="font-extrabold font-semibold mt-3">Size</h6>
            <h6 class="text-muted mb-0">{{state.options.size}} (byte) </h6>
          </div>

          <div v-if="state.options.status_code">
            <h6 class="font-extrabold font-semibold mt-3">Status code</h6>
            <h6 class="text-muted mb-0">{{state.options.status_code}}</h6>
          </div>

          <div v-if="state.options.error_type">
            <h6 class="font-extrabold font-semibold mt-3">Error type</h6>
            <h6 class="text-muted mb-0">{{Config.LIST_HTTTP_ERRORS[state.options.error_type] }}</h6>
          </div>

          <div v-if="state.options.error_message">
            <h6 class="font-extrabold font-semibold mt-3">Error message</h6>
            <h6 class="text-danger mb-0">{{state.options.error_message}}</h6>
          </div>
          <Spinner :show="state.loading_detail" ></Spinner>
          <div v-if="state.htmlbody">
            <div class="row">
                <div class="col-9">
                    <h6 class="font-extrabold mt-3">Body (First 1kb)</h6>
                </div>
                <div class="col-3">
                    <div class="float-end">
                    <a class="btn" v-on:click="copyToClipBoard(state.htmlbody.body)" title="Copy to clipboard"  >
                        <i class="bi bi-clipboard" ></i>
                    </a>
                    
                    </div>
                </div>
            </div>

            <pre class="code">{{state.htmlbody.body}}</pre>
          </div>

          <div v-if="state.options.html_error_part">
            <div class="row">
                <div class="col-9">
                    <h6 class="font-extrabold mt-3">Extract error html:</h6>
                </div>
                <div class="col-3">
                    <div class="float-end">
                    <a class="btn" v-on:click="copyToClipBoard(state.options.html_error_part)" title="Copy to clipboard"  >
                        <i class="bi bi-clipboard" ></i>
                    </a>
                    
                    </div>
                </div>
            </div>

            <pre class="code html-error-part">{{state.options.html_error_part}}</pre>
          </div>


        </div>
    </template>

  </modal>
</template>

<!-- notifications -->

<script>
// Reference from https://github.com/davidnotplay/vue-my-dropdown/blob/master/src/vue-my-dropdown.vue

import Modal from '@/views/components/Modal.vue'
import { onMounted, reactive } from 'vue'
import Utils from '@/helpers/Utils'
import Config from '@/helpers/Config'
import Loading from '@/views/components/Loading.vue'
import Alert from '@/helpers/Alert'
import Mark from 'mark.js'
export default {
  name: 'HistoryPResult',
  components: {
    Modal,
    Loading
  },
  props: {
    history: Object,
    history_id: String
  },
  emits: ['close-form'],
  setup (props, { emit }) {
    const state = reactive({
      loading_detail: true,
      options: props.history ? props.history.options : null,
      htmlbody: null
    })

    // if (props.history){
    //   state.options = props.history.options;
    // }

    const closeModal = (refresh = false) => {
      emit('close-form', refresh)
    }

    const copyToClipBoard = (text) => {
        Utils.copyToClipboard(text)
        Alert.showSuccessToast('The text has been coplied to the clipboard')
    }

    const fetchDetail = () => {
      const historyId = props.history_id ? props.history_id : props.history.id
      Utils.request()
        .get(`/schedule-history/${historyId}/`)
        .then((response) => {
          if (response.data.htmlbody) {
            state.htmlbody = response.data.htmlbody
          }
          state.options = response.data.options

          if (state.options.html_error_part){
            var keywords = [];
            let schedule = response.data.schedule
            var alert_items = schedule.options.alert_condition.when
            for (let alert_item of alert_items){
              if (alert_item.type == "KEYWORD_EXIST_HTTP_ERROR"){
                keywords.push(alert_item.value)
              }
            }
            
            if (keywords.length > 0){
              setTimeout(()=>{
                var context = document.querySelector("pre.html-error-part");
                var instance = new Mark(context);
                instance.mark(keywords);
              },10)
            }

          }

        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.loading_detail = false
        })
    }

    onMounted(() => {
      fetchDetail()
    })

    return {
      closeModal,
      props,
      state,
      copyToClipBoard,
      Config
    }
  }
}
</script>
