<template>
  <h4 class="mt-5">Upcoming monitors will be run <small>(grouped by type)</small></h4>
  <div class="row mt-5">
    <div class="col-12 col-lg-4 col-xl-3">
      <DatePicker
        :attributes="dots"
        v-model="state.selectedDate"
        v-on:update:modelValue="onSelectedDate"
      >
      </DatePicker>

      <div class="calendar-explanation mb-5">
             <p><strong>Color explaination:</strong></p><br/>
             <p><span class="green" ></span> Check SSL</p>
             <p><span class="red" ></span> HTTP Request</p>
             <p><span class="blue" ></span> Send email</p>
             <p><span class="yellow" ></span> Expiry domain</p>
             <p><span class="purple" ></span> Check port</p>
             <p><span class="pink" ></span> DNS</p>
             <p><span class="cyan" ></span> DISK STATUS</p>

      </div>
    </div>
    <div class="col-12 col-lg-8 col-xl-9">
      <p v-if="!state.selectedDate">Select a date to filter your monitors</p>
      <p v-else-if="state.filterSchedules.length== 0">There is no schedule will be run on this day</p>
      <ListSchedule v-else :schedules="state.filterSchedules" :tableClass="''"
        v-on:remove="removeItem"
        v-on:toggleEnable="toggleEnable"
       ></ListSchedule>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'
import { defineComponent, reactive, computed } from 'vue'
import Config from '@/helpers/Config'
import ListSchedule from '@/views/home/ListSchedule'
import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'
export default defineComponent({
  name: 'ScheduleCalendar',

  props: {
    schedules: Array
  },
  emits: ['remove', 'toggleEnable'],
  components: {
    DatePicker,
    ListSchedule
  },
  setup (props, { emit }) {
    const mapColor = {}

    mapColor[Config.CHECK_SSL] = {
      dot: 'green',
      dates: []
    }
    mapColor[Config.HTTP] = {
      dot: 'red',
      dates: []
    }

    mapColor[Config.SEND_EMAIL] = {
      dot: 'blue',
      dates: []
    }
    mapColor[Config.EXPIRE_DOMAIN] = {
      dot: 'yellow',
      dates: []
    }

    mapColor[Config.TELNET] = {
      dot: 'purple',
      dates: []
    }

    mapColor[Config.DNS] = {
      dot: 'pink',
      dates: []
    }
    mapColor[Config.DISK_STATUS] = {
      dot: 'cyan',
      dates: []
    }

    props.schedules.forEach((item) => {
      if (item.next_occur && item.enabled) {
        const nextOccur = new Date(item.next_occur)
        mapColor[item.category].dates.push(nextOccur)
      }
    })
    const dots = []
    for (const key in mapColor) {
      dots.push(mapColor[key])
    }

    const onSelectedDate = () => {
      if (state.selectedDate) {
        scheduleListViewStore.setSelectedDate(state.selectedDate)
      }
    }

    const selectedDate = scheduleListViewStore.getState().selectedDate

    const state = reactive({
      selectedDate: selectedDate,
      schedulesOnSelectedDate: [],

      filterSchedules: computed(() => {
        const result = []
        if (!state.selectedDate){
          return result
        }
        props.schedules.forEach(schedule => {
          if (schedule.next_occur && schedule.enabled) {
            const nextOccur = new Date(schedule.next_occur)
            if (nextOccur.getDate() === state.selectedDate.getDate() && nextOccur.getMonth() === state.selectedDate.getMonth() && nextOccur.getYear() === state.selectedDate.getYear()) {
              result.push(schedule)
            }
          }
        })
        return result
      })
    })

    const removeItem = (item) => {
      emit('remove', item)
    }
    const toggleEnable = (item) => {
      emit('toggleEnable', item)
    }

    return {
      dots: dots,
      onSelectedDate,
      state,
      removeItem,
      toggleEnable
    }
  }
})
</script>
