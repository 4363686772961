<template>
  <MainLayout>
    <Loading v-if="state.fetching" :text="'Loading contact'"></Loading>
    <div class="row" v-else >
       <div class="col-12 col-lg-8  offset-lg-2 offset-md-0">
            <div class="page-title mb-3">
            <div class="row ">
                <div class="col-12 col-md-12">
                  <nav aria-label="breadcrumb" class="breadcrumb-header">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link  :to="{ name: 'Contacts' }">Contacts</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Edit</li>
                      </ol>
                  </nav>
                </div>
                <div class="col-12 col-md-6">
                  <h3>Edit Contact</h3>
                </div>

            </div>
          </div>
          <ContactForm :contact="state.form_data" ></ContactForm>
        </div>
    </div>

  </MainLayout>
</template>

<script>
import { defineComponent, reactive, onMounted, computed } from 'vue'
import MainLayout from '@/views/layouts/Main.vue'
import ContactForm from '@/views/components/ContactForm.vue'
import { useRoute } from 'vue-router'
import Utils from '@/helpers/Utils'
// import Config from "@/helpers/Config";
import Loading from '@/views/components/Loading.vue'
import router from '@/router'
export default defineComponent({
  page_title: () => {
    return 'Edit Contact'
  },
  name: 'EditContact',
  components: {
    MainLayout,
    ContactForm,
    Loading
  },

  setup () {
    const route = useRoute()
    const currentLocation = computed(() => {
      const { ...rest } = route
      return rest
    })
    const state = reactive({
      fetching: true,
      contact_id: currentLocation.value.params.id

    })
    const fetchContact = () => {
      state.fetching = true

      Utils.request()
        .get(`/contact/${state.contact_id}/`)
        .then((response) => {
          state.form_data = response.data
        })
        .catch((error) => {
          if (error.response.status === 404) {
            router.push({
              name: 'Contacts'
            })
          }
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.fetching = false
        })
    }

    onMounted(() => {
      fetchContact()
    })

    return {
      state
    }
  }
})
</script>
