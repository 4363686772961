<template>
  <modal :dialogClass="'modal-dialog-scrollable'" v-on:close-modal="closeModal">
    <template v-slot:modaltitle>
       Import checks from PingDom
    </template>
    <template v-slot:modalbody>

            <form  v-on:submit.prevent="importData">
                <label>Token</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="state.token"
                  placeholder="Input Pingdom API Token"
                />

                <button
                  type="submit"
                  class="btn btn-primary mt-3 me-1 mb-1"
                  :disabled="state.working"
                >
                  <Spinner :show="state.working"></Spinner>
                  Import
                </button>
            </form>

    </template>

  </modal>
</template>

<!-- notifications -->

<script>
// Reference from https://github.com/davidnotplay/vue-my-dropdown/blob/master/src/vue-my-dropdown.vue

import Modal from '@/views/components/Modal.vue'
import { reactive } from 'vue'
import Utils from '@/helpers/Utils'
import Alert from '@/helpers/Alert'

export default {
  name: 'UserPlan',
  components: {
    Modal
  },
  emits: ['close-form'],
  setup (props, { emit }) {
    const state = reactive({
      working: false,
      token: ''
    })

    const closeModal = (refresh = false) => {
      emit('close-form', refresh)
    }

    const importData = () => {
      state.working = true
      const formData = {
        key: state.token
      }
      Utils.request().post('/import-pingdom/', formData).then(response => {
        console.log('response', response)

        Alert.showSuccessToast(
            `${response.data.success} imported, ${response.data.failed} failed, ${response.data.not_supported} not supported`
        )
        closeModal(true)
      }).catch((error) => {
        Utils.showErrorMessage(error.response)
        // closeModal(true);
      }).finally(() => {
        state.working = false
      })
    }

    return {
      closeModal,
      props,
      state,
      importData
    }
  }
}
</script>
