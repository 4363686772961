<template>
  <div class="row">
    <div class="col-12 col-sm-8 col-lg-6 mb-4">
      <p class="mb-1">
        Host:
      </p><input
        v-model="state.form_data.host"
        type="text"
        required
        class="form-control"
      >
    </div>
    <div class="col-12 col-sm-4 col-lg-2 mb-4">
      <p class="mb-1">
        Port:
      </p><input
        v-model="state.form_data.port"
        type="number"
        class="form-control"
      >
    </div>
    <div class="col-12 col-sm-4 col-lg-2 mb-4">
      <p class="mb-1">
        Protocol:
      </p>
      <select class="form-select" v-model="state.form_data.protocol">
        <option
          v-for="type in Config.PROTOCOL_TYPES"
          :key="type"
          v-bind:value="type"
        >
          {{ type }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
// This is for Daily, Hourly and Minutely
import { defineComponent, reactive } from 'vue'
import Config from '@/helpers/Config'
export default defineComponent({
  name: 'CheckPort',
  props: {
    options: {
      type: Object,
      default: null
    }

  },
  setup (props) {
    const defaultData = {
      host: '',
      port: 80,
      protocol:'TCP'
    }

    const getValue = () => {
      const result = {}
      for (const key in defaultData) {
        if (key === 'port') {
          result[key] = parseInt(state.form_data[key])
        } else {
          result[key] = state.form_data[key]
        }
      }

      return result
    }

    const formData = {
      ...defaultData,
      ...props.options
    }

    const state = reactive({
      form_data: formData
    })
    return { props, state, getValue, Config }
  }
})
</script>
