<template>
<div class="px-2">
    <div>
      <label class="mb-1"><strong>Username:</strong></label>
      <input type="text" class="form-control" v-model="state.authData[type].username"
        required placeholder="Input username">
    </div>
    <div class="mt-3">
      <label class="mb-1"><strong>Password:</strong></label>
      <input type="text" class="form-control" v-model="state.authData[type].password"
      required placeholder="Input password">
    </div>
</div>
</template>
<script>

import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'AuthHTTPBasic',
  props: {
    auth_data: Object,
    type: String
  },
  setup (props) {
    const authData = {
      ...props.auth_data
    }
    if (typeof (authData[props.type]) === 'undefined') {
      authData[props.type] = {}
    }

    const listKeys = ['username', 'password']
    listKeys.forEach(key => {
      if (typeof (authData[props.type][key]) === 'undefined') {
        authData[props.type][key] = ''
      }
    })

    const getValue = () => {
      return state.authData[props.type]
    }

    const state = reactive({
      authData: authData,
      type: props.type
    })
    return { state, getValue }
  }
})
</script>
