// convert object to query params

const SERVER = location.hostname == 'localhost' ? 'http://localhost:8000/api' : 'https://api.apibot.io/api'
// const SERVER = 'http://localhost:8000/api'

// Default limit
const LIMIT = 20

const SHORT_LIMIT = 10

const TITLE = 'APIBOT'

const RRULE_MAP_FREQ = {
  YEARLY: 'Yearly',
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly',
  DAILY: 'Daily',
  HOURLY: 'Hourly',
  MINUTELY: 'Minutely'
}

const RRULE_DEFAULT_FREQ = 'YEARLY'

const RRULE_MAP_POSITION = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  '-1': 'Last'
}

const RRULE_DEFAULT_POSITION = '1'

const RRULE_MAP_DAY = {
  SU: 'Sunday',
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thurday',
  FR: 'Friday',
  SA: 'Saturday',
  'SU,MO,TU,WE,TH,FR,SA': 'Day',
  'MO,TU,WE,TH,FR': 'Weekday',
  'SU,SA': 'Weekend day'
}

const RRULE_MAP_DAY_IN_WEEK = {
  SU: 'Sunday',
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thurday',
  FR: 'Friday',
  SA: 'Saturday'
}

const RRULE_DEFAULT_DAY = 'SU'

const RRULE_MAP_MONTH = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

const RRULE_DEFAULT_MONTH = '1'
const RRULE_DEFAULT_MONTHDAY = '1'

const DEFAULT_INTERVAL = 1

const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

const HTTP_METHODS_SUPPORT_BODY = ['POST', 'PUT']
const HTTP_BODY_TYPE = ['RAW', 'JSON']

const CHECK_SSL = 'CHECK_SSL'
const HTTP = 'HTTP'
const SEND_EMAIL = 'SEND_EMAIL'
const EXPIRE_DOMAIN = 'EXPIRE_DOMAIN'
const TELNET = 'TELNET'
const DNS = 'DNS'
const DISK_STATUS = 'DISK_STATUS'
const MAP_CATEGORY = {
  HTTP: 'HTTP request',
  CHECK_SSL: 'Check SSL',
  SEND_EMAIL: 'Send email',
  EXPIRE_DOMAIN: 'Expiry domain',
  TELNET: 'Check port',
  DNS: 'DNS',
  DISK_STATUS: "Disk Status"
}

const PASSIVE_SCHEDULES = [  // Receive data via webhook
  DISK_STATUS
]

const LIST_HTTTP_ERRORS = {
  RESPONSE_CODE_HTTP_ERROR: 'Response error status code',
  KEYWORD_EXIST_HTTP_ERROR: 'Keyword exist',
  KEYWORD_NOT_EXIST_HTTP_ERROR: 'Keyword not exist',
  OTHER_HTTP_ERROR: 'Other errors'
}

const DNS_TYPES = [
  'A',
  'CNAME',
  'TXT',
  'MX'
]


const PROTOCOL_TYPES = [
  'TCP',
  'UDP'
]

// Should define max is once
const SINGLE_HTTTP_ERRORS = ['RESPONSE_CODE_HTTP_ERROR', 'OTHER_HTTP_ERROR']

const DEFAULT_RRULE = {
  HTTP: 'FREQ=MINUTELY;INTERVAL=5',
  CHECK_SSL: 'FREQ=DAILY;INTERVAL=1',
  SEND_EMAIL: 'FREQ=DAILY;INTERVAL=1',
  TELNET: 'FREQ=MINUTELY;INTERVAL=5',
  DNS: 'FREQ=MINUTELY;INTERVAL=5',
  DISK_STATUS: 'FREQ=DAILY;INTERVAL=2',
}

const SHOW_RESPONSE_CHARTS = [
  HTTP,
  TELNET,
]

const SHOW_UPTIME_LOG_CATEGORY = [
  HTTP,
  TELNET,
  DISK_STATUS
]

const TELEGRAM_USER_NAME = 'official_apibot'

const DOWN_CATEGORY = 'DOWN'
const UP_CATEGORY = 'UP'

export default {
  LOCAL_TOKEN_KEY: 'token_info',
  LOCAL_DARKMODE_KEY: 'darkmode',
  LOCAL_USER_KEY: 'user_info',
  SERVER,
  LIMIT,
  SHORT_LIMIT,
  TITLE,
  RRULE_MAP_FREQ,
  RRULE_DEFAULT_FREQ,
  RRULE_MAP_POSITION,
  RRULE_DEFAULT_POSITION,
  RRULE_MAP_DAY,
  RRULE_DEFAULT_DAY,
  RRULE_MAP_MONTH,
  RRULE_DEFAULT_MONTH,
  RRULE_DEFAULT_MONTHDAY,
  DEFAULT_INTERVAL,
  RRULE_MAP_DAY_IN_WEEK,
  HTTP_METHODS,
  HTTP_METHODS_SUPPORT_BODY,
  HTTP_BODY_TYPE,
  MAP_CATEGORY,
  LIST_HTTTP_ERRORS,
  SINGLE_HTTTP_ERRORS,
  DEFAULT_RRULE,
  HTTP,
  CHECK_SSL,
  EXPIRE_DOMAIN,
  SEND_EMAIL,
  TELNET,
  DNS,
  DISK_STATUS,
  SHOW_RESPONSE_CHARTS,
  SHOW_UPTIME_LOG_CATEGORY,
  TELEGRAM_USER_NAME,
  DOWN_CATEGORY,
  UP_CATEGORY,
  DNS_TYPES,
  PROTOCOL_TYPES,
  PASSIVE_SCHEDULES
}
