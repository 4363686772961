<template>
  <div
    class="wrap-multiple-select"
    v-if="state.selectSchedulesStoreState.ids.length > 0 && state.selectSchedulesStoreState.active"
    v-bind:class="{
      grow: state.selectSchedulesStoreState.active,
    }"
  >
    <!-- <a
      title="Select multiple monitors"
      class="toggle"
      v-if="!state.selectSchedulesStoreState.active"
      v-on:click="selectSchedulesStore.toggleActive()"
    >
      <i
        v-if="state.selectSchedulesStoreState.active"
        class="bi bi-check-square"
      ></i>
      <i v-else class="bi bi-square"></i>
    </a> -->

      <p class="statistic" >
        Selected: {{state.selectSchedulesStoreState.ids.length}}
      </p>
      <select
        class="form-select form-control-sm"
        v-model="state.action"
        v-if="state.selectSchedulesStoreState.active"
      >
          <option
            v-for="action in state.actions"
            :key="action.id"
            v-bind:value="action.id"
          >
            {{ action.label }}
          </option>
      </select>

      <button
        type="button"
        v-if="state.selectSchedulesStoreState.active"
        v-on:click="applyAction()"
        class="btn btn-success"
      >
        Apply
      </button>
      <a class="close-button"
      v-if="state.selectSchedulesStoreState.active"
        v-on:click="selectSchedulesStore.reset()"
      >
        <i class="bi bi-x-lg"></i>
      </a>
      <SelectContactModal v-if="state.showContactForm" v-on:close-form="state.showContactForm= false"
      v-on:selected-contacts="selectedContacts"
      ></SelectContactModal>
      <SelectTagModal v-if="state.showTagForm" v-on:close-form="state.showTagForm= false"
      v-on:selected-tags="selectedTags"
      ></SelectTagModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { selectSchedulesStore } from '@/stores/SelectSchedules'
import Alert from '@/helpers/Alert'
import Utils from '@/helpers/Utils'
import SelectContactModal from '../components/SelectContactModal.vue'
import SelectTagModal from '../components/SelectTagModal.vue'

export default defineComponent({
  components: { SelectContactModal, SelectTagModal },
  name: 'SelectMultipleSchedules',
  setup (props, { emit }) {
    interface State {
      action: string;
      showContactForm: boolean,
      showTagForm: boolean,
      actions: Array<any>,
      selectSchedulesStoreState: any;
    }
    const state = reactive<State>({
      action: '',
      showContactForm: false,
      showTagForm: false,
      actions: [
        { id: '', label: '--Select action--' },
        { id: 'enable', label: 'Enable' },
        { id: 'disable', label: 'Disable' },
        { id: 'delete', label: 'Delete' },
        { id: 'set_contacts', label: 'Set contacts' },
        { id: 'set_tags', label: 'Set tags' }
      ],
      selectSchedulesStoreState: selectSchedulesStore.getState()

    })

    const generalApply = (append) => {
      let formData = {
        ids: selectSchedulesStore.getState().ids,
        action: state.action
      }
      if (append) {
        formData = { ...formData, ...append }
      }
      Alert.loading('Working')
      Utils.request().post('/bulk-action/', formData)
        .then((response) => {
          console.log('response', response)
          state.action = ''
          selectSchedulesStore.reset()
          Alert.showSuccessToast('Your request have been applied')
          emit('bulkActionDone')
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          Alert.close()
        })
    }

    const applyAction = () => {
      if (state.action === '') {
        return
      }
      if (selectSchedulesStore.getState().ids.length === 0) {
        Alert.alert('Confirm', 'You must select at least one schedule before apply the action')
        return
      }
      console.log(state.action)
      if (state.action === 'set_contacts') {
        state.showContactForm = true
      } else if (state.action === 'set_tags') {
        state.showTagForm = true
      } else if (state.action === 'delete') {
        Alert.confirm(`Are you sure to delete ${selectSchedulesStore.getState().ids.length} selected schedules ?`, 'Confirm').then((result) => {
          if (result) {
            generalApply({})
          }
        })
      } else {
        generalApply({})
      }
    }

    const selectedContacts = (contacts) => {
      console.log('contacts', contacts)
      generalApply({ contacts: contacts })
    }

    const selectedTags = (tags) => {
      console.log('tags', tags)
      generalApply({ tags: tags })
    }

    return {
      state,
      selectSchedulesStore,
      applyAction,
      selectedContacts,
      selectedTags
    }
  }
})
</script>
