<template>
    {{Config.MAP_CATEGORY[props.value] ?? ''}}
</template>

<script>
import {

  defineComponent
} from 'vue'
import Config from '@/helpers/Config'
export default defineComponent({
  name: 'PurposeText',
  props: {
    value: String
  },
  setup (props) {
    return {
      props,
      Config
    }
  }
})
</script>
