<template>
  <span  v-if="props.show">
      <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
  </span>
</template>

<script>
import {

  defineComponent
} from 'vue'

export default defineComponent({
  name: 'Spinner',
  props: {
    show: Boolean
  },
  setup (props) {
    return {
      props
    }
  }
})
</script>
