<template>
  <AnonymousLayout>
    <h1 class="auth-title">
      Verify Account
    </h1>

    <Loading
      v-if="state.fetching"
      :text="'Requesting'"
    />
    <div v-else>
      <div
        v-if="state.success"
        class="alert alert-light-primary color-primary"
      >
        Great, you can receive alert from APIBOT now.
      </div>
      <div
        v-else
        class="alert alert-light-warning color-warning"
      >
        <p> Invalid request, please try again</p>
      </div>
      <p>
        <router-link
          :to="{ name: 'SignIn' }"
          class="btn  btn-outline-primary"
        >
          Sign In
        </router-link>
      </p>
    </div>
  </AnonymousLayout>
</template>

<script>
import {
  defineComponent,
  reactive,
  computed,
  onMounted
} from 'vue'
import {
  useRoute
} from 'vue-router'
import Utils from '@/helpers/Utils'
import AnonymousLayout from '@/views/layouts/Anonymous.vue'
import Loading from '@/views/components/Loading.vue'
export default defineComponent({
  name: 'VerifyAccount',
  components: {
    AnonymousLayout,
    Loading
  },
  async setup () {
    // const delay = (t) => new Promise(resolve => setTimeout(resolve, t))
    const route = useRoute()
    const currentLocation = computed(() => {
      const {
        ...rest
      } = route
      return rest
    })

    const fetch = () => {
      const formData = {
        email: currentLocation.value.params.email,
        secret: currentLocation.value.params.secret
      }

      Utils.request(false).post('/verify-alert-contact/', formData).then(() => {
        state.success = true
      }).catch(error => {
        Utils.showErrorMessage(error.response)
        state.success = false
      }).finally(() => {
        state.fetching = false
      })
    }

    const state = reactive({
      fetching: true,
      success: false
    })

    onMounted(() => {
      fetch()
    })
    // await delay(4000)
    return {
      currentLocation,
      state
    }
  }
})
</script>
