import { Store } from './main'
import {
  RouteLocationNormalized
} from 'vue-router'

/**
 * General store to store commmon things for web app.
 */
interface GeneralWrapper {
    preLoginRoute: RouteLocationNormalized | null // store url before redirect to login page, after logged in, will redirect to this path
}

class GeneralStore extends Store<GeneralWrapper> {
  protected data (): GeneralWrapper {
    return {
      preLoginRoute: null
    }
  }

  setPreLoginRoute (route: RouteLocationNormalized) {
    this.state.preLoginRoute = route
  }
}

export const generalStore: GeneralStore = new GeneralStore()
