<template>
  <div class="col-8 col-md-4">
    <p class="mb-1" >Every</p>
    <div class="form-group row align-items-center">
      <div class="col-lg-6 col-6">
        <input
          type="number"
          placeholder="months"
          required
          class="form-control"
          v-model="state.formData.INTERVAL"
        />
      </div>
      <div class="col-lg-2 col-2">
        <label class="col-form-label">{{getText()}}(s)</label>
      </div>
    </div>
  </div>

  <!-- end row -->
</template>

<script>
// This is for Daily, Hourly and Minutely
import { defineComponent, reactive } from 'vue'
import Config from '@/helpers/Config'
export default defineComponent({
  name: 'DAILY',
  props: {
    freq: String,
    data: Object
  },
  setup (props) {
    let formData = {
      INTERVAL: Config.DEFAULT_INTERVAL
    }

    formData = {
      ...formData,
      ...props.data
    }

    const getText = () => {
      const map = {
        DAILY: 'day',
        HOURLY: 'hour',
        MINUTELY: 'minute'
      }
      return map[props.freq] ? map[props.freq] : ''
    }

    const getValue = () => {
      const result = {
        FREQ: props.freq,
        INTERVAL: state.formData.INTERVAL
      }

      return result
    }

    const state = reactive({
      working: false,
      formData
    })
    return { props, state, Config, getValue, getText }
  }
})
</script>
