<template>
  <div class="row">
    <div class="col-12 col-sm-8 col-lg-2 mb-4">
      <p class="mb-1">
        Type:
      </p>
      <select class="form-select" v-model="state.form_data.type">
        <option
          v-for="type in Config.DNS_TYPES"
          :key="type"
          v-bind:value="type"
        >
          {{ type }}
        </option>
      </select>
    </div>
    <div class="col-12 col-sm-8 col-lg-4 mb-4">
      <p class="mb-1">
        Record:
      </p><input
        v-model="state.form_data.record"
        type="text"
        :placeHolder="state.recordPlaceHolder"
        required
        class="form-control"
      >
    </div>
    <div class="col-12 col-sm-4 col-lg-6 mb-4">
      <p class="mb-1">
        Value:
      </p><input
        v-model="state.form_data.value"
        :placeHolder="state.valuePlaceHolder"
        class="form-control"
      >
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, computed } from 'vue'
import Config from '@/helpers/Config'
export default defineComponent({
  name: 'CheckDNS',
  props: {
    options: {
      type: Object,
      default: null
    }

  },
  setup (props) {
    const defaultData = {
      type: 'A',
      record: '',
      value: ''
    }

    const getValue = () => {
      return state.form_data
    }

    const formData = {
      ...defaultData,
      ...props.options
    }

    const state = reactive({
      recordPlaceHolder: computed(() => { return `Input ${state.form_data.type} record` }),
      valuePlaceHolder: computed(() => { return `Input ${state.form_data.type} value` }),
      form_data: formData
    })
    return { props, state, getValue, Config }
  }
})
</script>
