<template>
<tr>
  <th width="30%" v-if="!props.schedule_id" >Monitor</th>
  <th width="45%">Message</th>
  <th width="25%">Status</th>

</tr>
</template>
<script>
import {

  defineComponent
} from 'vue'

export default defineComponent({
  name: 'ColumnHistory',
  props: {
    schedule_id: String
  },
  setup (props) {
    return {
      props
    }
  }
})
</script>
