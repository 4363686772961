<template>
    <div class="col-12 col-sm-8 col-md-4">
    <p class="mb-1" >Every</p>
    <div class="form-group row align-items-center">

      <div class="col-lg-6 col-6">
        <input
          type="number"
          placeholder="months"
          required
          class="form-control"
          v-model="state.formData.INTERVAL"
        />
      </div>
      <div class="col-lg-2 col-2">
        <label class="col-form-label">week(s)</label>
      </div>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-12">
      <div class="btn-group not-flex">
        <button type="button" class="btn" v-for="(day_text, day) in Config.RRULE_MAP_DAY_IN_WEEK"
          :key="day"
          v-bind:class="{'btn-primary': state.formData.BYDAY.indexOf(day) != -1 }"
          v-on:click="toogleDay(day)"
          >{{day_text}}</button>

      </div>
    </div>
  </div>
  <!-- end row -->
</template>

<script>
import { defineComponent, reactive } from 'vue'
import Config from '@/helpers/Config'
import Utils from '@/helpers/Utils'
export default defineComponent({
  name: 'WEEKLY',
  props: {
    data: Object
  },
  setup (props) {
    let formData = {
      INTERVAL: Config.DEFAULT_INTERVAL,
      BYDAY: []
    }

    const clone = Utils.clone(props.data)
    if (clone.BYDAY) {
      clone.BYDAY = clone.BYDAY.split(',')
    }

    formData = {
      ...formData,
      ...clone
    }

    const toogleDay = (value) => {
      const position = state.formData.BYDAY.indexOf(value)
      if (position === -1) {
        state.formData.BYDAY.push(value)
      } else {
        state.formData.BYDAY.splice(position, 1)
      }
    }

    const getValue = () => {
      if (state.formData.BYDAY.length === 0) {
        throw new Error('You must select at least 1 day before process')
      }

      const result = {
        FREQ: 'WEEKLY',
        INTERVAL: state.formData.INTERVAL,
        BYDAY: state.formData.BYDAY.join(',')
      }

      return result
    }

    const state = reactive({
      working: false,
      formData
    })
    return { state, toogleDay, Config, getValue }
  }
})
</script>
