<template>
  <AnonymousLayout>
    <h1 class="auth-title">
      Verify Account
    </h1>
    <div
      v-if="state.success"
      class="alert alert-light-primary color-primary"
    >
      Your account is verified
    </div>
    <div
      v-else
      class="alert alert-light-warning color-warning"
    >
      <p> Invalid request, please try again</p>
    </div>
    <p>
      <router-link
        :to="{ name: 'SignIn' }"
        class="btn  btn-outline-primary"
      >
        Sign In
      </router-link>
    </p>
  </AnonymousLayout>
</template>

<script>
import {
  defineComponent,
  reactive,
  computed
} from 'vue'
import {
  useRoute
} from 'vue-router'
import Utils from '@/helpers/Utils'
import AnonymousLayout from '@/views/layouts/Anonymous.vue'
export default defineComponent({
  name: 'VerifyAccount',
  components: {
    AnonymousLayout
  },
  async setup () {
    // const delay = (t) => new Promise(resolve => setTimeout(resolve, t))
    const route = useRoute()
    const currentLocation = computed(() => {
      const {
        ...rest
      } = route
      return rest
    })

    const formData = {
      email: currentLocation.value.params.email,
      secret: currentLocation.value.params.secret
    }

    const result = await Utils.request(false).post('/user-email-verification/', formData)

    const state = reactive({
      success: result.data.email_verified
    })
    // await delay(4000)
    return {
      currentLocation,
      state
    }
  }
})
</script>
