<template>
<p class="mB-5"  v-if="data.total_entries">
  Showing {{state.start_entry}} to {{state.end_entry}} of {{data.total_entries}} entries
</p>

<nav class="clear-both" v-if="data.total_entries">
  <ul class="pagination">
    <li class="page-item"><a class="page-link pointer"  @click="setPage(1)" >First</a></li>
    <li class="page-item" v-bind:class="{ active: page==data.current_page }" v-for="page in state.predict_pages"  :key="page">
      <a class="page-link pointer" @click="setPage(page)">
        <span aria-hidden="true">{{page}}</span>
      </a>
    </li>
    <li class="page-item"><a class="page-link pointer"  @click="setPage(state.total_pages)" >Last</a></li>
  </ul>
</nav>

</template>

<script>
import {

  reactive,
  computed
  // computed
  // computed
  // onUnmounted
} from 'vue'
import Utils from '@/helpers/Utils'

export default {
  name: 'pagination',
  props: {
    data: Object
  },
  setup (props, {
    emit
  }) {
    let endEntry = props.data.current_page * props.data.limit
    endEntry = endEntry > props.data.total_entries ? props.data.total_entries : endEntry
    const state = reactive({
      total_pages: computed(() => {
        return Utils.getTotalPages(props.data.total_entries, props.data.limit)
      }),
      predict_pages: computed(() => {
        const result = []
        // Get around 2 unit
        const from = props.data.current_page - 2
        const to = props.data.current_page + 2
        for (let i = from; i <= to; i++) {
          if (i > 0 && i <= state.total_pages) {
            result.push(i)
          }
        }
        return result
      }),
      start_entry: props.data.total_entries ? (props.data.current_page - 1) * props.data.limit + 1 : 0,
      end_entry: endEntry
    })

    const setPage = (page) => {
      emit('update:page', page)
    }

    return {
      state,
      setPage
    }
  }
}
</script>
