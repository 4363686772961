
<template>
  <AnonymousLayout>
    <h1 class="auth-title">
      Sign In
    </h1>
    <p class="auth-subtitle mb-4">
      Log in with your data that you entered during registration.
    </p>

    <form
      autocomplete="off"
      @submit.prevent="login"
    >
      <div class="form-group position-relative has-icon-left mb-4">
        <input
          ref="emailRef"
          v-model="state.email"
          type="email"
          class="form-control form-control-xl"
          name="email"
          required
          placeholder="Your email"
        >
        <div class="form-control-icon">
          <i class="bi bi-person" />
        </div>
      </div>
      <div class="form-group position-relative has-icon-left mb-4">
        <input
          v-model="state.password"
          type="password"
          class="form-control form-control-xl"
          name="password"
          required
          placeholder="Your password"
        >
        <div class="form-control-icon">
          <i class="bi bi-shield-lock" />
        </div>
      </div>

      <button
        class="btn btn-primary btn-block btn-lg shadow-lg mt-5 vertical-center"
        :disabled="state.working"
      >
        <Spinner :show="state.working" />Log in
      </button>
    </form>
    <div class="text-center mt-5 text-lg fs-5">
      <p class="text-gray-600">
        Don't have an account? &nbsp;&nbsp;
        <router-link
          class="font-bold sign-up"
          :to="{ name: 'SignUp' }"
        >
          Sign up
        </router-link>
        .
      </p>
      <p>
        &nbsp;&nbsp;
        <router-link
          class="font-bold"
          :to="{ name: 'ForgotPassword' }"
        >
          Forgot password ?
        </router-link>

        .
      </p>
    </div>
  </AnonymousLayout>
</template>

<script>
import {
  defineComponent,
  reactive,
  onMounted,
  ref
} from 'vue'

import Utils from '@/helpers/Utils.ts'
import UserHelper from '@/helpers/UserHelper'
import Alert from '@/helpers/Alert'
import router from '@/router'
import AnonymousLayout from '@/views/layouts/Anonymous.vue'
import { generalStore } from '@/stores/GeneralStore'
export default defineComponent({
  name: 'SignIn',
  components: {
    AnonymousLayout
  },
  setup () {
    const state = reactive({
      email: '',
      password: '',
      working: false,
      errors: []
    })
    const checkForm = () => {
      state.errors = []

      if (!state.email) {
        state.errors.push('Email is required.')
      }
      if (!state.password) state.errors.push('password is required.')

      return state.errors.length === 0
    }
    const login = () => {
      // e.preventDefault();

      if (!checkForm() || state.working) {
        return
      }
      state.working = true
      const formData = {
        username: state.email,
        password: state.password
      }

      Utils.request(false).post('/auth-token/', formData).then(response => {
        // this.redirectToHome(response.data)
        if (!response.data.user.email_verified) {
          return Alert.error('Error', 'You need to verify your account')
        }

        UserHelper.setUser(response.data.user)
        UserHelper.setToken(response.data.token)
        const preRoute = generalStore.getState().preLoginRoute
        if (preRoute != null) {
          generalStore.setPreLoginRoute(null) // clear it
          router.push({ // Push to previous path that required loggedin
            name: preRoute.name,
            params: preRoute.params
          })
        } else {
          router.push({
            name: 'Home'
          })
        }
      }).catch(error => {
        Utils.log(error)
        Utils.showErrorMessage(error.response)
      })
        .finally(() => {
          state.working = false
        })
    }
    const emailRef = ref(null)

    onMounted(() => {
      emailRef.value.focus()
    })

    return {
      state,
      login,
      emailRef
    }
  }
})
</script>
