import { Store } from './main'
import Utils from '@/helpers/Utils'
import { TagWrapper } from '@/models/interfaces'

class TagStore extends Store<TagWrapper> {
  protected data (): TagWrapper {
    return {
      preFetch: false,
      list: []
    }
  }

    fetchList = (force = true) => {
      if (!force && this.state.preFetch) {
        return new Promise((resolve) => {
          resolve(this.state.list)
        })
      }
      return new Promise((resolve, reject) => {
        Utils.request()
          .get('/tag/')
          .then((response) => {
            this.state.preFetch = true

            tagStore.setList(response.data)
            resolve(response.data)
          })
          .catch((error) => {
            if (error.response){
              Utils.showErrorMessage(error.response)
            }
            if (reject){
              reject()
            }
            
          })
      })
    }

    setList (projects: Array<any>) {
      this.state.list = projects
    }

    addItem (tag: any) {
      this.state.list.push(tag)
    }

    clear () {
      this.state.list = []
      this.state.preFetch = false
    }
}

export const tagStore: TagStore = new TagStore()
