<template>
  <MainLayout>

    <div class="page-title mb-3">
      <div class="row">
        <div class="col-12 col-md-6">
          <h3>History</h3>

        </div>

    </div>
    </div>
    <section class="section">
      <div class="card">
          <div class="card-body">
              <HistoryEmbbed ></HistoryEmbbed>
          </div>
      </div>

    </section>

  </MainLayout>
</template>

<script>
import {
  defineComponent

  //   ref,
} from 'vue'

import HistoryEmbbed from '@/views/components/HistoryEmbbed/HistoryEmbbed'
import MainLayout from '@/views/layouts/Main.vue'

export default defineComponent({
  page_title: 'History',
  name: 'History',
  props: [],
  components: {
    MainLayout,
    HistoryEmbbed
  }
})
</script>
