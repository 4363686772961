<template>
          <Multiselect
            v-model="state.value"
            :createTag="false"
            :placeholder="props.placeholder || 'Select or input new tag'"
            :searchable="true"
            mode="tags"
            :options="state.options"
          />
</template>

<script>

import { reactive, onMounted } from 'vue'
// import Utils from '@/helpers/Utils';
import Config from '@/helpers/Config'
import Multiselect from '@vueform/multiselect'
import Utils from '@/helpers/Utils'
import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'
export default {
  name: 'SelectTag',
  components: {
    Multiselect
  },
  props: {
    init_tags: Array,
    placeholder: String,
    options: Array
  },
  setup (props) {
    const state = reactive({
      fetching: false,
      value: Utils.clone(props.init_tags),
      options: {}

    })

    onMounted(() => {
      scheduleListViewStore.fetchList(true).then(response => {
        const temp = {}
        response.forEach(element => {
          temp[element.id] = element.name
        })
        state.options = temp
      })
    })

    return {
      state,
      Config,
      props
    }
  }
}
</script>
