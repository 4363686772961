<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-12 col-lg-5 col-xl-4">
        <div class="clearfix">
          <label><strong>Type:</strong></label>
          <select
            class="form-select width-auto"
            v-model="state.authData.type"
          >
            <option
              v-for="type in state.listTypes"
              :key="type.code"
              v-bind:value="type.code"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="clearfix mt-2" v-if="state.authData.type != 'no_auth'">
          <p>
            The authorization header will be automatically generated when you
            send the request.
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-7 col-xl-8">
        <AuthHTTPBasic
          v-if="state.authData.type == 'basic_auth'"
          :auth_data="state.authData"
          :type="'basic_auth'"
          ref="refBasicAuth"

        ></AuthHTTPBasic>
        <AuthHTTPBearer
          v-if="state.authData.type == 'bearer_token'"
          :auth_data="state.authData"
          :type="'bearer_token'"
          ref="refBearerToken"
        >
        </AuthHTTPBearer>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import AuthHTTPBasic from '@/views/components/category/AuthHTTP/Basic.vue'
import AuthHTTPBearer from '@/views/components/category/AuthHTTP/Bearer.vue'
export default defineComponent({
  name: 'AuthHTTP',
  components: {
    AuthHTTPBasic,
    AuthHTTPBearer
  },
  props: {
    data: Object
  },
  setup (props) {
    const refBasicAuth = ref(null)
    const refBearerToken = ref(null)
    const getValue = () => {
      const result = state.authData
      let ref = null
      if (state.authData.type === 'basic_auth') {
        ref = refBasicAuth
      } else if (state.authData.type === 'bearer_token') {
        ref = refBearerToken
      }

      if (state.authData.type !== 'no_auth' && ref) {
        result[state.authData.type] = ref.value.getValue()
      }
      return result
    }

    const state = reactive({
      listTypes: [
        {
          code: 'no_auth',
          name: 'No Auth'
        },
        {
          code: 'basic_auth',
          name: 'Basic Authentication'
        },
        {
          code: 'bearer_token',
          name: 'Bearer Token'
        }
      ]
    })
    const authData = {
      ...props.data
    }
    if (!authData.type) {
      authData.type = 'no_auth'
    }
    state.authData = authData
    return { state, refBasicAuth, refBearerToken, getValue }
  }
})
</script>
