<template>
  <MainLayout>

    <div class="row">
       <div class="col-12 col-lg-8  offset-lg-2 offset-md-0">
            <div class="page-title mb-3">
            <div class="row ">
                <div class="col-12 col-md-12">
                  <nav aria-label="breadcrumb" class="breadcrumb-header">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link  :to="{ name: 'Contacts' }">Contacts</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">New</li>
                      </ol>
                  </nav>
                </div>
                <div class="col-12 col-md-6">
                  <h3>New Contact</h3>
                </div>

            </div>
          </div>
          <ContactForm :contact="state.formData" ></ContactForm>
        </div>
    </div>

  </MainLayout>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import MainLayout from '@/views/layouts/Main.vue'
import ContactForm from '@/views/components/ContactForm.vue'
// import { useRoute } from "vue-router";
// import Config from "@/helpers/Config";

export default defineComponent({
  page_title: () => {
    return 'New Contact'
  },
  name: 'NewContact',
  components: {
    MainLayout,
    ContactForm
  },

  setup () {
    const formData = {
      name: '',
      enabled: true,
      rrule: '',
      started: '',
      notify_via_email: true,
      notify_via_telegram: false,
      telegram_group_id: null
    }

    const state = reactive({
      working: false,
      contact: null,
      formData: formData
    })

    return {
      state
    }
  }
})
</script>
