import UserHelper from './UserHelper'
import Alert from './Alert'
import { generalStore } from '@/stores/GeneralStore'
import {
  RouteLocationNormalized
} from 'vue-router'

function checkLogin (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) {
  if (!UserHelper.getToken()) {
    if (to.fullPath != '/') { // ignore index page
      Alert.showErrorToast('You need login to access this page')
    }

    next('/sign-in')
    generalStore.setPreLoginRoute(to)
  } else {
    next()
  }
}

export default {
  checkLogin: checkLogin
}
