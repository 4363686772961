<template>
  <Multiselect
    v-model="state.value"
    placeholder="Search"
    label="name"
    ref="multiselect"
    mode="tags"
    :createTag="state.createTag"
    :searchable="true"
    v-on:change="rebuildOptions"
    :options="state.options"
  >
    <template v-slot:tag="{ option, handleTagRemove, disabled }">
      <div :class="'multiselect-tag  ' + option.category">
        {{ option.name }}
        <i
          v-if="!disabled"
          @click.prevent
          @mousedown.prevent.stop="handleTagRemove(option, $event)"
        />
      </div>
    </template>

    <template v-slot:option="{ option }">
      <span :class="option.category">{{ option.name }}</span>
    </template>
  </Multiselect>
</template>

<script>
import { reactive, onMounted, ref } from 'vue'
import Config from '@/helpers/Config'
import Multiselect from '@vueform/multiselect'
import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'
export default {
  name: 'SearchControl',
  components: {
    Multiselect
  },
  props: {
    init_tags: Array,
    placeholder: String,
    options: Array
  },
  setup (props, { emit }) {
    const map = {
      0: { value: 'CHECK_SSL', name: 'Check SSL', category: 'category' },
      1: { value: 'HTTP', name: 'HTTP request', category: 'category' },
      3: { value: 'SEND_EMAIL', name: 'Send email', category: 'category' },
      4: { value: 'EXPIRE_DOMAIN', name: 'Expiry domain', category: 'category' },
      5: { value: 'TELNET', name: 'Check port', category: 'category' },
      6: { value: 'DNS', name: 'Check DNS', category: 'category' },
      7: { value: 'false', name: 'Disabled', category: 'status' },
      8: { value: 'true', name: 'Enabled', category: 'status' },
      9: { value: 'ERROR', name: 'Error', category: 'latest-history' },
      10: { value: 'WARNING', name: 'Warning', category: 'latest-history' },
      11: { value: 'GOOD', name: 'Good', category: 'latest-history' }
    }
    const temp = []
    for (const key in map) {
      temp.push({
        value: parseInt(key),
        name: map[key].name,
        category: map[key].category,
        label: map[key].name
      })
    }
    const defaultOptions = temp

    const state = reactive({
      fetching: false,
      value: [],
      createTag: true,
      options: defaultOptions
    })

    const rebuildOptions = () => {
      setTimeout(() => {
        multiselect.value.close()
        scheduleListViewStore.resetSearch()
        const search = scheduleListViewStore.state.search
        // let added_category = [];
        // let added_number = [];
        let canAddTag = true
        for (const key in state.value) {
          const value = state.value[key]
          if (typeof value !== 'number') {
            search.search = value

            canAddTag = false // user input string to search
          } else {
            const fixed = map[value]
            if (fixed.category === 'category') {
              search.category_list.push(fixed.value)
            } else if (fixed.category === 'status') {
              search.enabled_list.push(fixed.value)
            } if (fixed.category === 'latest-history') {
              search.latest_history_status_list.push(fixed.value)
            }
          }
        }
        state.createTag = canAddTag

        // let temp = [];

        // for (let key in map) {
        //   let key_number = parseInt(key);

        //   if (
        //     added_category.indexOf(map[key_number].category) == -1 ||
        //     added_number.indexOf(key_number) != -1
        //   ) {
        //     temp.push({
        //       value: parseInt(key_number),
        //       name: map[key_number].name,
        //       category: map[key_number].category,
        //     });
        //   }
        // }

        // if (search.search) {

        //   temp.push(search.search);
        // }

        // state.options = temp;

        scheduleListViewStore.setSearch(search)

        emit('search')
      })
    }

    const pushToSearch = (category, selectedList) => {
      const result = []
      selectedList.forEach(element => {
        for (var key in map) {
          const item = map[key]
          if (item.category === category && item.value === element) {
            result.push(key)
          }
        }
      })
      return result
    }

    onMounted(() => {
      const searchObj = scheduleListViewStore.state.search
      let value = []
      if (searchObj.search) {
        state.options.push(searchObj.search)
        value.push(searchObj.search)
      }

      if (searchObj.category_list.length > 0) {
        const valueList = pushToSearch('category', searchObj.category_list)
        if (valueList.length > 0) {
          value = value.concat(valueList)
        }
      }

      if (searchObj.enabled_list.length > 0) {
        const valueList = pushToSearch('status', searchObj.enabled_list)
        if (valueList.length > 0) {
          value = value.concat(valueList)
        }
      }

      if (searchObj.latest_history_status_list.length > 0) {
        const valueList = pushToSearch('latest-history', searchObj.latest_history_status_list)
        if (valueList.length > 0) {
          value = value.concat(valueList)
        }
      }

      state.value = value
    })

    const multiselect = ref(null)

    return {
      state,
      Config,
      props,
      multiselect,
      rebuildOptions
    }
  }
}
</script>
