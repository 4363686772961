<template>

  <HistoryResult v-if="state.history_result" :history="state.history_result" v-on:close-form="closeForm"></HistoryResult>

            <div  class="empty-result" v-if="state.list.length == 0 && !state.working" >
                <img src="/images/empty-box.svg" />
                <p>No results yet</p>
              </div>

  <div v-else >
      <table class="mobile-table table" v-bind:class="{'list-history':!props.schedule_id, 'list-history-for-schedule':props.schedule_id}">
          <thead>
            <Column :schedule_id="props.schedule_id" ></Column>
          </thead>

          <tbody>

            <Skeleton :count_column="state.count_column"  v-if="state.working" ></Skeleton>

            <tr v-else v-for="item in state.list" :key="item.id">
              <td v-if="!props.schedule_id">
                <router-link  :to="{ name: 'ViewSchedule', params:{id:item.schedule.id} }">
                  {{ item.schedule.name }} (<CategoryText :value="item.schedule.category"></CategoryText>)
                </router-link>

              </td>

              <td>
                <p v-if="item.options.message" >{{item.options.message}}</p>
                <p v-if="item.options.error_message" >
                  <a class="pointer text-danger"  v-on:click="viewHTTPResult(item)" v-if="item.schedule.category =='HTTP' ">{{item.options.error_message}}</a>
                  <span v-else>{{item.options.error_message}}</span>

                </p>
                <a class="btn btn-outline-primary btn-sm" v-on:click="viewHTTPResult(item)" v-if="item.schedule.category =='HTTP' && item.category =='GOOD' ">
                  View detail
                </a>
              </td>
              <td>
                  <status-indicator :status="item.category"></status-indicator>
                  &nbsp;
                  <small>{{Utils.displayTime(item.created)}}</small>
              </td>

            </tr>
          </tbody>
      </table>
  </div>
      <Pagination
        v-if="!state.working"
        :data="state.pagination"
        v-on:update:page="setPage"
      ></Pagination>

</template>

<script>
import {
  defineComponent,
  reactive,
  onMounted
  //   ref,
} from 'vue'

import Column from '@/views/components/HistoryEmbbed/Column'

import Pagination from '@/views/components/Pagination.vue'

import HistoryResult from '@/views/components/HistoryResult.vue'

import Skeleton from '@/views/components/Skeleton.vue'
import StatusIndicator from '@/views/components/StatusIndicator.vue'
import CategoryText from '@/views/components/CategoryText.vue'
import Config from '@/helpers/Config'
import Utils from '@/helpers/Utils'

export default defineComponent({
  name: 'NotificationEmbbed',

  components: {
    Column,
    Pagination,
    Skeleton,
    StatusIndicator,
    HistoryResult,
    CategoryText
  },
  props: {
    schedule_id: String
  },
  setup (props) {
    const state = reactive({
      working: false,
      history_result: null,
      count_column: props.schedule_id ? 3 : 4,
      pagination: reactive({
        limit: props.schedule_id ? Config.SHORT_LIMIT : Config.LIMIT,
        current_page: 1,
        total_entries: 0
      }),
      list: []
    })

    const fetchList = () => {
      if (state.working) {
        return
      }
      state.working = true

      const formData = {
        limit: state.pagination.limit,
        offset: (state.pagination.current_page - 1) * state.pagination.limit
      }

      if (props.schedule_id) {
        formData.schedule = props.schedule_id
      }

      Utils.request()
        .get('/schedule-history/?' + Utils.serialize(formData))
        .then((response) => {
          state.pagination.total_entries = response.data.count
          state.list = response.data.results
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.working = false
        })
    }
    const setPage = (page) => {
      state.pagination.current_page = page
      fetchList()
    }

    onMounted(() => {
      fetchList()
    })

    const closeForm = () => {
      state.history_result = null
    }

    const viewHTTPResult = (item) => {
      state.history_result = item
    }

    return {
      state,
      setPage,
      Utils,
      fetchList,
      props,
      viewHTTPResult,
      closeForm
    }
  }
})
</script>
