<template>
  <MainLayout>

    <div class="page-title mb-3">

          <h3 class="inline-block" >Notifications</h3>
          <div class="float-end "  v-if="state.unread_notification.number > 0"  >
            <button class="pd-2 btn btn-outline-primary align-vertical-center " v-bind:class={disabled:state.working_read_all} v-on:click="markReadAllNotification" >
            <i class="bi bi-check-all"></i> &nbsp; Mark read all
            </button>
          </div>
    </div>
    <section class="section">
      <div class="card">
          <div class="card-body">
              <NotificationEmbbed ></NotificationEmbbed>
          </div>
      </div>
    </section>
  </MainLayout>
</template>

<script>
import {
  defineComponent,
  reactive
  //   ref,
} from 'vue'

import NotificationEmbbed from '@/views/components/NotificationEmbbed/NotificationEmbbed'
import MainLayout from '@/views/layouts/Main.vue'
import { unreadNotificationStore } from '@/stores/UnreadNotificationStore'
import Utils from '@/helpers/Utils'
import Alert from '@/helpers/Alert'
export default defineComponent({
  page_title: 'Notification',
  name: 'Notification',
  props: [],
  components: {
    MainLayout,
    NotificationEmbbed
  },
  setup () {
    const state = reactive({
      unread_notification: unreadNotificationStore.getState(),
      working_read_all: false
    })

    const markReadAllNotification = () => {
      // read-all-notifications/
      state.working_read_all = false
      Utils.request()
        .put('/read-all-notifications/1/') // Rest Django require id to put, so just pass 1 to PUT works
        .then(() => {
          unreadNotificationStore.setData(0)
          Alert.showSuccessToast('All your notifications have been marked as read')
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        }).finally(() => {
          state.working_read_all = false
        })
    }

    return {
      state,
      markReadAllNotification
    }
  }

})
</script>
