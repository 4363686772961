<template>
<div class="mt-4">
  <div class="table-responsive" v-if="state.list.length > 0">

        <table class="table table-lg table-borderless narrow">
            <thead >
                <tr>
                    <th><small>Key</small></th>
                    <th><small>Value</small></th>

                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in state.list"  :key="item" >
                    <td>
                        <input type="input" class="form-control" placeholder="Input key" v-model="item.key" required />
                    </td>
                    <td>
                      <input type="input" class="form-control" placeholder="Input value"  v-model="item.value" required />
                    </td>

                    <td>
                      <a  class="pointer pd-2 float-end" title="Remove" v-on:click="removeItem(index)" >
                        <i class="bi bi-x"></i> </a>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <a  class="btn btn-sm btn-outline-primary" v-on:click="newItem">Add</a>
</div>

</template>

<script>
// This is for Daily, Hourly and Minutely
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'KeyValue',
  props: {
    data: Object
  },
  setup (props) {
    const getValue = () => {
      const result = {}
      state.list.forEach(item => {
        result[item.key] = item.value
      })
      return result
    }

    const newItem = () => {
      state.list.push({
        key: '',
        value: '',
        description: ''
      })
    }

    const removeItem = (index) => {
      state.list.splice(index, 1)
    }

    const list = []

    if (props.data) {
      for (const key in props.data) {
        list.push({
          key: key,
          value: props.data[key]
        })
      }
    }

    const state = reactive({
      list: list
    })
    return { props, state, getValue, newItem, removeItem }
  }
})
</script>
