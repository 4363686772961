<template>
  <h6>Data</h6>
  <CheckSSL
    v-if="props.schedule.category == Config.CHECK_SSL || props.schedule.category == Config.EXPIRE_DOMAIN "
    ref="checkSSL"
    :options="props.schedule.options"
  />
  <HttpRequest
    v-else-if="props.schedule.category == Config.HTTP"
    ref="httpRequest"
    :options="props.schedule.options"
  />
  <SendEmail
    v-else-if="props.schedule.category == Config.SEND_EMAIL"
    ref="sendEmail"
    :options="props.schedule.options"
  />
  <CheckPort
    v-else-if="props.schedule.category == Config.TELNET"
    ref="checkPort"
    :options="props.schedule.options"
  />
  <CheckDNS
    v-else-if="props.schedule.category == Config.DNS"
    ref="checkDNS"
    :options="props.schedule.options"
  />
  <DiskStatus
    v-else-if="props.schedule.category == Config.DISK_STATUS"
    ref="diskStatus"
    :options="props.schedule.options"
  />
  
</template>

<script>
// This is for Daily, Hourly and Minutely
import { defineComponent, reactive, ref } from 'vue'
import Config from '@/helpers/Config'
import CheckSSL from '@/views/components/category/CheckSSL'
import SendEmail from '@/views/components/category/SendEmail'
import CheckPort from '@/views/components/category/CheckPort'
import CheckDNS from '@/views/components/category/CheckDNS'
import HttpRequest from '@/views/components/category/HttpRequest'
import DiskStatus from '@/views/components/category/DiskStatus'

export default defineComponent({
  name: 'Category',
  components: {
    CheckSSL,
    HttpRequest,
    SendEmail,
    CheckPort,
    CheckDNS,
    DiskStatus
  },
  props: {
    schedule: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const checkSSL = ref(null)
    const httpRequest = ref(null)
    const sendEmail = ref(null)
    const checkPort = ref(null)
    const checkDNS = ref(null)
    const diskStatus = ref(null)

    const getValue = () => {
      let result = null
      switch (props.schedule.category) {
        case Config.CHECK_SSL:
        case Config.EXPIRE_DOMAIN:
          result = checkSSL.value.getValue()
          break
        case Config.HTTP:
          result = httpRequest.value.getValue()
          break
        case Config.SEND_EMAIL:
          result = sendEmail.value.getValue()
          break
        case Config.TELNET:
          result = checkPort.value.getValue()
          break
        case Config.DNS:
          result = checkDNS.value.getValue()
          break
        case Config.DISK_STATUS:
          result = diskStatus.value.getValue()
      }
      return result
    }

    const state = reactive({

    })
    return { props, state, Config, checkSSL, httpRequest, sendEmail, checkPort, checkDNS, diskStatus, getValue }
  }
})
</script>
