<template>
  <MainLayout>

    <div class="page-title mb-3">
      <div class="row">
        <div class="col-6">
          <h3>Monitors</h3>
        </div>
        <div class="col-6">
          <div class="float-end d-flex vertical-align-middle">

            <router-link
              class="pointer btn btn-outline-primary"
              :to="{ name: 'NewSchedule' }"
              >New</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="row">
        <div class="col-12">
         <StatusOverview
                :schedules="state.viewData.list"
                v-on:search="preFetchList('status_overview')"
              ></StatusOverview>
          <div class="card">
            <div class="card-body">
              <div
                class="empty-result"
                v-if="
                  state.viewData.list.length == 0 &&
                  !state.working &&
                  state.viewData.home_load_list_times <= 1
                "
              >
                <img src="/images/empty-box.svg" />
                <p>
                  No results available, Please set up tests first from
                  <router-link
                    class="pointer orange"
                    :to="{ name: 'NewSchedule' }"
                  >
                    this link
                  </router-link>
                </p>
              </div>

              <div v-else class="clear-both">
                <div
                  class="clear-both d-flow-root mB-10 mT-10 dataTables_filter"
                >
                  <div class="d-flex">

                      <SelectMultipleSchedules  v-on:bulkActionDone="bulkActionDone"></SelectMultipleSchedules>

                    <div class="wrap-search" v-if="state.viewSearch"><SearchControl v-on:search="preFetchList"></SearchControl>
                    </div>

                    <div class="btn-group  set-view-schedules" role="group">

                      <button
                        type="button"
                        class="btn icon icon-left"
                        title="Normal view"
                        v-bind:class="{
                          'btn-primary': state.viewData.view == 'default',
                        }"
                        v-on:click="setView('default')"
                      >
                        <i class="bi bi-list"></i>
                      </button>
                      <button
                        type="button"
                        class="btn icon icon-left"
                        title="Group by tags"
                        v-bind:class="{
                          'btn-primary': state.viewData.view == 'tags',
                        }"
                        v-on:click="setView('tags')"
                      >
                        <i class="bi bi-tags"></i>
                      </button>
                      <button
                        type="button"
                        class="btn icon icon-left"
                        title="Group by category"
                        v-bind:class="{
                          'btn-primary': state.viewData.view == 'category',
                        }"
                        v-on:click="setView('category')"
                      >
                        <i class="bi bi-collection"></i>
                      </button>
                      <button
                        type="button"
                        class="btn icon icon-left"
                        title="Group by calendar"
                        v-bind:class="{
                          'btn-primary': state.viewData.view == 'calendar',
                        }"
                        v-on:click="setView('calendar')"
                      >
                        <i class="bi bi-calendar-week"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="alert alert-light-primary color-primary mt-3"
                  v-if="!state.working && state.viewData.list.length == 0"
                >
                  No result yet
                </div>

                <table
                  v-else-if="state.working"
                  class="mobile-table table list-schedule mt-3"
                  cellspacing="0"
                  width="100%"
                >
                  <tbody>
                    <Skeleton v-if="state.working" :count_column="5"></Skeleton>
                  </tbody>
                </table>
                <div v-else>
                  <div v-if="state.viewData.view == 'default'">
                    <ListSchedule
                      :schedules="state.viewData.list"
                      v-on:remove="removeItem"
                      v-on:toggleEnable="toggleEnable"
                    ></ListSchedule>
                    <p>Total: {{ state.viewData.list.length }} records</p>
                  </div>

                  <div v-else-if="state.viewData.view == 'tags'">
                    <div v-for="tag in state.activeTags" v-bind:key="tag.id">
                      <h4 class="mt-5">
                        {{ tag.name }}
                        <small
                          >({{
                            state.mapTagsSchedules[tag.id].length
                          }})</small
                        >
                      </h4>
                      <ListSchedule
                        :schedules="state.mapTagsSchedules[tag.id]"
                        v-on:remove="removeItem"
                        v-on:toggleEnable="toggleEnable"
                      ></ListSchedule>
                    </div>
                  </div>
                  <div v-else-if="state.viewData.view == 'category'">
                    <div
                      v-for="(text, category) in Config.MAP_CATEGORY"
                      v-bind:key="category"
                    >
                      <div
                        v-if="
                          state.mapCategorySchedules[category] &&
                          state.mapCategorySchedules[category].length > 0
                        "
                      >
                        <h4 class="mt-5">
                          {{ text }}
                          <small
                            >({{
                              state.mapCategorySchedules[category].length
                            }})</small
                          >
                        </h4>
                        <ListSchedule
                          :schedules="state.mapCategorySchedules[category]"
                          v-on:remove="removeItem"
                          v-on:toggleEnable="toggleEnable"
                        ></ListSchedule>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="state.viewData.view == 'calendar'">
                    <ScheduleCalendar
                      :schedules="state.viewData.list"
                      v-on:remove="removeItem"
                      v-on:toggleEnable="toggleEnable"
                    ></ScheduleCalendar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </MainLayout>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted } from 'vue'
import MainLayout from '@/views/layouts/Main.vue'

import Utils from '@/helpers/Utils'
import Services from '@/helpers/Services'
import Skeleton from '@/views/components/Skeleton.vue'

import Config from '@/helpers/Config'
// import Dropdown from "@/views/components/Dropdown.vue";
import SearchControl from '@/views/components/SearchControl.vue'
import ListSchedule from '@/views/home/ListSchedule.vue'
import ScheduleCalendar from '@/views/home/Calendar.vue'
import StatusOverview from '@/views/home/StatusOverview.vue'
import SelectMultipleSchedules from '@/views/home/SelectMultipleSchedules.vue'

import { tagStore } from '@/stores/TagStore'
import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'
import { Schedule, Tag } from '@/models/interfaces'
import { selectSchedulesStore } from '@/stores/SelectSchedules'
// import router from '@/router'
// import { useRoute } from "vue-router";

export default defineComponent({
  page_title: 'Monitors',
  name: 'Home',
  components: {
    MainLayout,
    ListSchedule,
    ScheduleCalendar,
    Skeleton,
    StatusOverview,
    // Dropdown,
    SearchControl,
    SelectMultipleSchedules

  },

  setup () {
    interface State {
      working: boolean,
      viewSearch: boolean,
      viewData: any,
      isSelectMultiple: boolean,
      activeTags: Tag[],
      mapTagsSchedules: any,
      mapCategorySchedules: any,
      selectSchedulesStoreState: any
    }
    const state = reactive<State>({
      working: false,
      viewSearch: true,
      viewData: scheduleListViewStore.getState(),
      selectSchedulesStoreState: selectSchedulesStore.getState(),
      isSelectMultiple: false,
      activeTags: [],
      mapTagsSchedules: {},
      mapCategorySchedules: {}
    })

    const preFetchList = (source:string) => {
      if (source === 'status_overview') {
        // Rerender search to fill filter items
        state.viewSearch = false
        setTimeout(() => {
          state.viewSearch = true
        })
      }
      // scheduleListViewStore.setSearch(state.search_obj)
      fetchList()
    }

    const fetchList = (keepScroll = false) => {
      return new Promise((resolve, reject) => {
        if (state.working) {
          return resolve(null)
        }
        const scrollPosition = window.scrollY

        state.working = true
        scheduleListViewStore
          .fetchList()
          .then((response) => {
            // state.pagination.total_entries = response.data.count;
            // state.list = response.data;
            scheduleListViewStore.setList(response)
            scheduleListViewStore.increaseHomeLoadListTimes()

            checkTagView()

            processView(state.viewData.view)

            if (keepScroll) {
              setTimeout(() => {
                window.scrollTo(0, scrollPosition)
              })
            }
            resolve(null)
          })
          .catch(() => {
            reject(new Error('Get list fail'))
          })
          .finally(() => {
            state.working = false
          })
      })
    }

    const checkTagView = () => {
      /*
       https://github.com/kajalagroup/apibot-frontend/issues/61
       Tag view should be default is there is schedule belong to any tag.
      */
      if (scheduleListViewStore.getState().view !== '') {
        return
      }
      if (tagStore.getState().list.length === 0) {
        return scheduleListViewStore.setView('default')
      }

      let foundTag = false

      for (let i = 0; i < state.viewData.list.length; i++) {
        if (state.viewData.list[i].tags.length > 0) {
          foundTag = true
          break
        }
      }

      scheduleListViewStore.setView(foundTag ? 'tags' : 'default')
    }

    onMounted(() => {
      if (state.selectSchedulesStoreState.active) {
        selectSchedulesStore.reset()
      }
      tagStore.fetchList(false).then(() => {
        if (scheduleListViewStore.getState().list.length > 0) {
          // state.list = scheduleListViewStore.getState().list;
          checkTagView()
          processView(state.viewData.view)
          setTimeout(() => {
            window.scrollTo(0, scheduleListViewStore.getState().scroll_position)
          })
        } else {
          fetchList()
        }
      })
    })

    const removeItem = (item:Schedule) => {
      Services.removeSchedule(item).then(() => {
        scheduleListViewStore.removeItem(item)
        processView(state.viewData.view)
      })
    }

    const toggleEnable = (item:Schedule) => {
      const temp = Utils.clone(item)
      temp.enabled = !temp.enabled
      Services.updateSchedule(temp).then((schedule) => {
        scheduleListViewStore.updateItem(schedule)

        processView(state.viewData.view)
      })
    }

    const setView = (name:string) => {
      if (state.viewData.view === name) {
        return
      }

      processView(name)

      scheduleListViewStore.setView(name)
    }

    const processView = (name:string) => {
      if (name === 'tags') {
        setViewTags()
      } else if (name === 'category') {
        setSchedulesForCategory()
      }
    }

    const setViewTags = () => {
      state.working = true
      tagStore
        .fetchList(false)
        .then(() => {
          setSchedulesForTags()
        })
        .finally(() => {
          state.working = false
        })
    }

    const setSchedulesForTags = () => {
      const activeTags:Tag[] = []
      const noTagId = '0'
      const activeTagIds:string[] = []
      const temp:any = {}

      temp[noTagId] = []

      tagStore.getState().list.forEach((tag) => {
        temp[tag.id] = []
      })

      state.viewData.list.forEach((schedule:Schedule) => {
        if (schedule.tags.length === 0) {
          temp[noTagId].push(schedule)
        }
        schedule.tags.forEach((tag:Tag) => {
          if (temp[tag.id]) {
            temp[tag.id].push(schedule)
            if (activeTagIds.indexOf(tag.id) === -1) {
              activeTags.push(tag)
              activeTagIds.push(tag.id)
            }
          }
        })
      })

      if (temp[noTagId].length > 0) {
        activeTags.push({
          id: noTagId,
          name: 'No Tag'
        })
      }

      state.mapTagsSchedules = temp
      state.activeTags = activeTags
    }

    const setSchedulesForCategory = () => {
      const temp:any = {}

      for (const key in Config.MAP_CATEGORY) {
        temp[key] = []
      }

      state.viewData.list.forEach((schedule:Schedule) => {
        temp[schedule.category].push(schedule)
      })

      state.mapCategorySchedules = temp
    }

    const bulkActionDone = () => {
      fetchList(true)
    }

    return {
      preFetchList,
      state,
      Utils,
      removeItem,
      toggleEnable,
      Config,
      setView,
      selectSchedulesStore,
      bulkActionDone

    }
  }
})
</script>
