<template>
  <div class="modal-backdrop fade show"></div>
  <div
    class="modal fade modal-borderless"
    id="modalWrapper"
    style="display: block; padding-right: 15px"
  >
    <div class="modal-dialog" v-bind:class="state.extra_class" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="modaltitle"></slot>
          </h5>
          <button type="button" class="close" v-on:click="closeModal" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        </div>
        <div class="modal-body">
          <slot name="modalbody"></slot>
        </div>
        <div class="modal-footer">
          <slot name="modalfooter"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- notifications -->

<script>
// Reference from https://github.com/davidnotplay/vue-my-dropdown/blob/master/src/vue-my-dropdown.vue
import { onMounted, onUnmounted, reactive } from 'vue'
// import Utils from "@/helpers/Utils"

export default {
  name: 'mymodal',
  props: {
    dialogClass: String
  },
  emits: ['close-modal'],
  setup (props, { emit }) {
    const state = reactive({
      extra_class: props.dialogClass ?? ''
    })

    const closeModal = () => {
      emit('close-modal')
    }

    const listenBodyClick = (evt) => {
      if (evt.target.id === 'modalWrapper') {
        closeModal()
      }
    }
    onMounted(() => {
      const body = document.getElementsByTagName('body')[0]
      body.classList.add('modal-open')
      setTimeout(() => {
        const temp = document.getElementById('modalWrapper')
        temp.classList.add('show')
      }, 100)

      window.document.addEventListener('click', listenBodyClick)
    })
    onUnmounted(() => {
      const body = document.getElementsByTagName('body')[0]
      body.classList.remove('modal-open')
      window.document.removeEventListener('click', listenBodyClick)
    })
    return {
      state,
      closeModal
    }
  }
}
</script>
