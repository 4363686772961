function set (key:string, value:string) {
  localStorage.setItem(key, value)
}

function get (key:string):string {
  return localStorage.getItem(key) ?? ''
}

function setObject (key:string, value?: object | null|boolean) {
  const temp = JSON.stringify(value ?? '')
  localStorage.setItem(key, temp)
}

function getObject (key:string):any {
  let result = localStorage.getItem(key)
  if (result) {
    result = JSON.parse(result)
  }
  return result
}

export default {
  set: set,
  get: get,
  setObject: setObject,
  getObject: getObject
}
