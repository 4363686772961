<template>
<tr>
  <th width="100%">Message</th>

</tr>
</template>

<script>
import {

  defineComponent
} from 'vue'

export default defineComponent({
  name: 'ColumnHistory',
  props: {
    schedule_id: String
  },
  setup (props) {
    return {
      props
    }
  }
})
</script>
