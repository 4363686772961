<template>
  <Suspense>
    <template #default>
      <router-view />
    </template>
    <template #fallback>
      <div id="loader">
        <div class="spinner" />
      </div>
    </template>
  </Suspense>
</template>
<script>
export default {
  name: 'App',
  // mixsdins: [titleMixin],
  components: {

  }
}
</script>
