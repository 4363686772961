<template>
  <div class="row">
    <div class="col-12 col-sm-9 mb-4">
      <p class="mb-1" >Url:</p>
      <input
        type="url"
        placeholder="Input url"
        required
        v-model="state.form_data.url"
        class="form-control"
      />
    </div>
    <div class="col-12 col-sm-3 mb-4">
      <p class="mb-1" >Method:</p>
      <select class="form-select" v-model="state.form_data.method">
        <option
          v-for="(method_text, method) in Config.HTTP_METHODS"
          :key="method"
          v-bind:value="method"
        >
          {{ method_text }}
        </option>
      </select>
    </div>
  </div>

  <div class="mt-2 mb-5">
    <Tab :labels="state.tabs"  >
      <template v-slot:tab_0>
        <KeyValue :data="state.form_data.headers" ref="refHeader"></KeyValue>
      </template>
      <template v-slot:tab_1>
        <AuthHTTP :data="state.form_data.auth" ref="refAuth"></AuthHTTP>
      </template>
    </Tab>
  </div>

  <div
    class="mt-5"
    v-if="
      Config.HTTP_METHODS_SUPPORT_BODY.indexOf(state.form_data.method) != -1
    "
  >
    <div class="row">
      <div class="col-8">
        <h6 class="text-muted mt-2">Body</h6>
      </div>
      <div class="col-4">
        <div class="float-end">
          <div
            class="d-inline-block"
            v-for="body_type in Config.HTTP_BODY_TYPE"
            :key="body_type"
          >
            &nbsp;&nbsp;
            <div class="form-check d-inline-block">
              <input
                class="form-check-input"
                type="radio"
                :value="body_type"
                v-model="state.form_data.body_type"
                :id="body_type"
              />
              <label class="form-check-label" :for="body_type">
                {{ body_type }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <textarea
      rows="5"
      v-model="state.form_data.body"
      class="form-control"
    ></textarea>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import Config from '@/helpers/Config'
import KeyValue from '@/views/components/category/KeyValue'
import AuthHTTP from '@/views/components/category/AuthHTTP'
import Tab from '@/views/components/Tab'
import Utils from '@/helpers/Utils'
export default defineComponent({
  name: 'HttpRequest',
  components: {
    KeyValue,
    AuthHTTP,
    Tab
  },
  props: {
    options: Object
  },
  setup (props) {
    const refHeader = ref(null)
    const refAuth = ref(null)

    const defaultData = {
      url: 'https://',
      method: 'GET',
      body_type: 'JSON',
      body: '',
      headers: {},
      auth: {}
    }

    const getValue = () => {
      const result = {}
      for (const key in defaultData) {
        result[key] = state.form_data[key]
      }

      if (Config.HTTP_METHODS_SUPPORT_BODY.indexOf(result.method) !== -1) {
        if (result.body_type === 'JSON') {
          // validate body should be json
          const jsonBody = Utils.toJSON(result.body)
          if (!jsonBody) {
            throw new Error('Body should format as json')
          } else {
            result.body = jsonBody
          }
        }
      } else {
        delete result.body_type
        delete result.body
      }

      result.headers = refHeader.value.getValue()
      result.auth = refAuth.value.getValue()

      // throw new Error('Body should format as json')
      return result
    }

    const formData = {
      ...defaultData,
      ...props.options
    }

    if (formData.body_type === 'JSON' && typeof formData.body === 'object') {
      formData.body = JSON.stringify(formData.body, null, 4)
    }
    const state = reactive({
      form_data: formData,
      tabs: ['Headers', 'Authorization']
    })

    return { props, state, getValue, Config, refHeader, refAuth }
  }
})
</script>
