<template>
  <table
    class="mobile-table table list-contact mt-3 "
    cellspacing="0"
    width="100%"
    >
    <thead>
        <ColumnContact></ColumnContact>
    </thead>

    <tbody>

        <tr  v-for="item in props.contacts" :key="item.id" v-bind:class="{disabled:!item.enabled}">
        <td>
            <router-link
            :to="{
                name: 'EditContact',
                params: { id: item.id },
            }"
            >
            {{ item.name }}

            </router-link>

        </td>
        <td>
          {{item.count_alertable_schedules}}
        </td>
        <td>
          {{ item.email }}
        </td>

        <td>
  <i
            class="bi bi-check-circle success"
            v-if="item.is_verified"
            ></i>
            <i class="bi bi-x-circle danger" v-else></i>
        </td>

        <td>
           <p class="align-vertical-center">
            <router-link
            class="pointer d-flex  text-primary "
            title="Edit"
              :to="{
                  name: 'EditContact',
                  params: { id: item.id },
              }"
              >
              <i class="bi bi-pencil-square x2"></i>
            </router-link>
            &nbsp;&nbsp;&nbsp;
            <a
            class="pointer text-warning d-flex"
            v-on:click="removeItem(item)"
            ><i class="bi bi-trash x2"></i
            ></a>
            &nbsp;&nbsp;&nbsp;
         <a class="d-flex pointer" v-on:click="toggleEnable(item)" >
            <i class="bi bi-toggle-on enable-on x2" v-if="item.enabled" ></i>
            <i class="bi bi-toggle-off enable-off x2" v-else></i>
            </a>
           </p>
        </td>
        </tr>
    </tbody>
    </table>
</template>

<script>
import ColumnContact from '@/views/contact/ColumnContact'

import Utils from '@/helpers/Utils'
import {

  defineComponent
} from 'vue'

export default defineComponent({
  name: 'ListContact',
  components: {
    ColumnContact
  },
  props: {
    contacts: Array
  },
  setup (props, { emit }) {
    const removeItem = (item) => {
      emit('remove', item)
    }
    const toggleEnable = (item) => {
      emit('toggleEnable', item)
    }

    return {
      props,
      Utils,
      removeItem,
      toggleEnable
    }
  }
})
</script>
