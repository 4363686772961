<template>

      <h6>Repeat</h6>
      <p v-if="Config.PASSIVE_SCHEDULES.indexOf(state.category) != -1" class="text-muted" >
        This is a passive monitor, define desire schedule for receieve activity data, the system will check if it doesn't get any activity in that time.
      </p>
      <div class="row">
        <div class="col-12 col-md-5 mb-4">
            <p  class="mb-1">Start date time:</p>

            <input type="datetime-local" class="form-control" v-model="state.formData.started_local" >
        </div>

        <div class="col-12 col-md-3 mb-4">
          <p class="mb-1" >Type of repeat</p>
          <fieldset class="form-group">
            <select
              class="form-select"
              v-model="state.formData.FREQ"
            >
              <option
                v-for="(repeat_text, repeat) in state.map_req"
                :key="repeat"
                v-bind:value="repeat"
              >
                {{ repeat_text }}
              </option>
            </select>
          </fieldset>
        </div>
        <RruleYearly ref="rruleYearly" :data="state.formData.rrule" v-if="state.formData.FREQ == 'YEARLY'" ></RruleYearly>
        <RruleMonthly ref="rruleMonthly" :data="state.formData.rrule"  v-else-if="state.formData.FREQ == 'MONTHLY'" ></RruleMonthly>
        <RruleWeekly ref="rruleWeekly" :data="state.formData.rrule"  v-else-if="state.formData.FREQ == 'WEEKLY'" ></RruleWeekly>
        <RruleOther ref="rruleOther" :data="state.formData.rrule"  :freq="state.formData.FREQ" v-else ></RruleOther>
      </div>

      <!-- end row -->

</template>

<script>
import { defineComponent, reactive, ref, computed } from 'vue'
import RruleYearly from '@/views/components/rrule/Yearly.vue'
import RruleMonthly from '@/views/components/rrule/Monthly.vue'
import RruleWeekly from '@/views/components/rrule/Weekly.vue'
import RruleOther from '@/views/components/rrule/Other.vue'
import Config from '@/helpers/Config'
import Utils from '@/helpers/Utils'
export default defineComponent({
  name: 'Rrule',
  components: {
    RruleYearly,
    RruleMonthly,
    RruleWeekly,
    RruleOther
  },
  props: {
    data: String,
    option: Object,
    started_local: String
  },
  setup (props) {
    const rruleYearly = ref(null)
    const rruleMonthly = ref(null)
    const rruleWeekly = ref(null)
    const rruleOther = ref(null)

    const initDate = props.started_local ? props.started_local : ''

    const formData = {
      FREQ: Config.RRULE_DEFAULT_FREQ,
      rrule: {},
      started_local: Utils.datetimeLocal(initDate)
    }

    const parseData = (data) => {
      const temp = data.split(';')
      const rrule = {}
      temp.forEach(item => {
        const _temp = item.split('=')
        // if (_temp[0] == 'BYDAY'){
        //   _temp[1] = _temp[1].split(',');
        // }
        rrule[_temp[0]] = _temp[1]
      })

      formData.rrule = rrule
      formData.FREQ = rrule.FREQ
    }

    if (props.data) {
      parseData(props.data)
    }

    const getValue = () => {
      let result = null

      switch (state.formData.FREQ) {
        case 'YEARLY':
          result = rruleYearly.value.getValue()
          break
        case 'MONTHLY':
          result = rruleMonthly.value.getValue()
          break

        case 'WEEKLY':
          result = rruleWeekly.value.getValue()
          break

        default:
          result = rruleOther.value.getValue()
          break
      }
      /**
       * combine object to string
       * EG: {FREQ: "YEARLY", BYMONTH: "9", BYMONTHDAY: 17}
       *  => FREQ=YEARLY;BYMONTH=9;BYMONTHDAY=17
       * */

      if (result) {
        let temp = ''
        for (const key in result) {
          temp += `${key}=${result[key]};`
        }
        // Remove last character ";"
        result = temp.slice(0, -1)
      }
      return result
    }

    const state = reactive({
      working: false,
      formData,
      category: props.option.category,
      map_req: computed(() => {
        var fullCheckTypes = [Config.HTTP, Config.TELNET, Config.DNS]
        if (fullCheckTypes.indexOf(props.option.category) !== -1) {
          return Config.RRULE_MAP_FREQ
        }

        const result = Utils.clone(Config.RRULE_MAP_FREQ)
        delete result.HOURLY
        delete result.MINUTELY

        return result
        // return Utils.getTotalPages(props.data.total_entries, props.data.limit)
      })
    })
    return { state, Config, rruleYearly, rruleMonthly, rruleWeekly, rruleOther, getValue, parseData }
  }
})
</script>
