<template>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-6 mb-4">
      <p class="mb-1">
        Secret:
      </p>
      <input
        v-model="state.form_data.secret"
        type="text"
        required
        placeholder="Secret"
        class="form-control"
      >
    </div>
  </div>
</template>

<script>
// This is for Daily, Hourly and Minutely
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  name: 'DiskStatus',
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const defaultData = {
      secret: '',
    }

    const getValue = () => {
      return state.form_data
    }

    const formData = {
      ...defaultData,
      ...props.options
    }

    const state = reactive({
      form_data: formData
    })
    return { props, state, getValue }
  }
})
</script>
