<template>

  <table
    class="mobile-table table list-schedule table-hover" v-bind:class="finalTableClass"
    cellspacing="0"
    width="100%"
    >
    <thead>
        <ColumnSchedule></ColumnSchedule>
    </thead>

    <tbody>

        <tr  v-for="item in props.schedules" :key="item.id" v-bind:class="{disabled:!item.enabled}">

        <td>

          <p class="mb-0">
<a
                          class="toggle-checkbox-item"
                          v-if="state.selectSchedulesStoreState.active"
                          v-on:click="selectSchedulesStore.toogleID(item.id)"
                        >
                        <i v-if="state.selectSchedulesStoreState.ids.indexOf(item.id) != -1" class="bi bi-check-square"></i>
                        <i v-else class="bi bi-square"></i>
                        </a>
            <router-link
              class="monitor-detail"
              :to="{
                  name: 'ViewSchedule',
                  params: { id: item.id },
              }"
              >
              {{ item.name }}
            </router-link>
          </p>

        </td>
        <td>
            <p v-if="item.latest_history" class="no-margin" >
            <status-indicator
                :status="item.latest_history.category"
            ></status-indicator>
            &nbsp;
            <small>{{
                Utils.displayTime(item.latest_history.created)
            }}</small>
            </p>
            <p class="no-margin" v-else>Not run yet</p>
        </td>
        <td>
            <CategoryText :value="item.category"></CategoryText>
        </td>

        <td>
          <p class="align-vertical-center">
            <router-link
            class="pointer d-flex  text-primary "
            title="Edit"
              :to="{
                  name: 'ViewSchedule',
                  params: { id: item.id, action:'edit' },
              }"
              >
              <i class="bi bi-pencil-square x2"></i>
            </router-link>

            &nbsp;&nbsp;&nbsp;
            <a
            class="pointer d-flex  text-danger "
            title="Remove"
            v-on:click="removeItem(item)"
            ><i class="bi bi-trash x2"></i
            ></a>
            &nbsp;&nbsp;&nbsp;
         <a class="d-flex pointer" v-on:click="toggleEnable(item)" title="Toggle" >
            <i class="bi bi-toggle-on enable-on x2" v-if="item.enabled" ></i>
            <i class="bi bi-toggle-off enable-off x2" v-else></i>
            </a>
          </p>
        </td>
        </tr>
    </tbody>
    </table>
</template>

<script>

import ColumnSchedule from '@/views/home/ColumnSchedule'
import StatusIndicator from '@/views/components/StatusIndicator.vue'
import CategoryText from '@/views/components/CategoryText.vue'
import Utils from '@/helpers/Utils'
import { selectSchedulesStore } from '@/stores/SelectSchedules'
import {
  defineComponent,
  reactive
} from 'vue'

export default defineComponent({
  name: 'ListSchedule',
  components: {
    ColumnSchedule,
    StatusIndicator,
    CategoryText
  },
  props: {
    schedules: Array,
    tableClass: String
  },
  setup (props, { emit }) {
    const removeItem = (item) => {
      emit('remove', item)
    }
    const toggleEnable = (item) => {
      emit('toggleEnable', item)
    }
    const finalTableClass = props.tableClass != null ? props.tableClass : 'mt-3'

    const state = reactive({
      selectSchedulesStoreState: selectSchedulesStore.getState()
    })

    return {
      props,
      Utils,
      finalTableClass,
      removeItem,
      toggleEnable,
      state,
      selectSchedulesStore
    }
  }
})
</script>
