<template>
  <AnonymousLayout>
    <h1 class="auth-title">
      Sign Up
    </h1>
    <p class="auth-subtitle mb-4">
      Input your data to register to our website.
    </p>

    <form
      autocomplete="off"
      @submit.prevent="signup"
    >
      <div class="form-group position-relative has-icon-left mb-4">
        <input
          ref="emailRef"
          v-model="state.email"
          type="email"
          class="form-control form-control-xl"
          name="email"
          required
          placeholder="Your email address"
        >
        <div class="form-control-icon">
          <i class="bi bi-envelope" />
        </div>
      </div>
      <div class="form-group position-relative has-icon-left mb-4">
        <input
          v-model="state.first_name"
          type="text"
          class="form-control form-control-xl"
          name="first-name"
          required
          Placeholder="Your first name"
        >
        <div class="form-control-icon">
          <i class="bi bi-person" />
        </div>
      </div>
      <div class="form-group position-relative has-icon-left mb-4">
        <input
          v-model="state.last_name"
          type="text"
          class="form-control form-control-xl"
          name="last-name"
          required
          Placeholder="Your last name"
        >
        <div class="form-control-icon">
          <i class="bi bi-person" />
        </div>
      </div>
      <div class="form-group position-relative has-icon-left mb-4">
        <input
          v-model="state.password"
          type="password"
          class="form-control form-control-xl"
          name="password"
          required
          placeholder="Password"
        >
        <div class="form-control-icon">
          <i class="bi bi-shield-lock" />
        </div>
      </div>
      <div class="form-group position-relative has-icon-left mb-4">
        <input
          v-model="state.confirm_password"
          type="password"
          class="form-control form-control-xl"
          name="confirm-passsword"
          required
          placeholder="Confirm Password"
        >
        <div class="form-control-icon">
          <i class="bi bi-shield-lock" />
        </div>
      </div>

      <div
        v-if="state.errors.length"
        class="list-errors"
      >
        <p>Please correct the following error(s):</p>
        <ul>
          <li
            v-for="error in state.errors"
            :key="error"
          >
            {{ error }}
          </li>
        </ul>
      </div>
      <button
        class="btn btn-primary btn-block btn-lg shadow-lg mt-5"
        type="submit"
        :disabled="state.working"
      >
        <Spinner :show="state.working" /> &nbsp; Signup
      </button>
    </form>
    <div class="text-center mt-5 text-lg fs-5">
      <p class="text-gray-600">
        Already have an account? <router-link
          class="font-bold"
          :to="{ name: 'SignIn' }"
        >
          <i class="fa fa-sign-in-alt fa-lg" />&nbsp;&nbsp;Sign In
        </router-link><br>.
      </p>
    </div>
  </AnonymousLayout>
</template>

<script>
import {
  defineComponent,
  reactive,
  onMounted,
  ref
} from 'vue'
import Utils from '@/helpers/Utils'
import Alert from '@/helpers/Alert'
import AnonymousLayout from '@/views/layouts/Anonymous.vue'
export default defineComponent({
  name: 'SignUp',
  components: {
    AnonymousLayout
  },
  setup () {
    const initData = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
      working: false,
      errors: []
    }
    const state = reactive(initData)
    const checkForm = () => {
      state.errors = []
      if (!state.email) state.errors.push('email is required.')
      if (!state.first_name) state.errors.push('First name is required.')
      if (!state.last_name) state.errors.push('Last name is required.')
      if (!state.password) state.errors.push('password is required.')
      if (state.password !== state.confirm_password) state.errors.push('Confirm password is not match with password.')

      return state.errors.length === 0
    }
    const signup = () => {
      // e.preventDefault();

      if (!checkForm() || state.working) {
        return
      }
      state.working = true
      const formData = {
        first_name: state.first_name,
        last_name: state.last_name,
        email: state.email,
        password: state.password
      }

      Utils.request(false).post('/user/', formData).then(() => {
        state.first_name = ''
        state.email = ''
        state.password = ''
        state.confirm_password = ''
        // state = initData
        Alert.alert('Success', 'Please check your email to verify your account')
      }).catch(error => {
        Utils.showErrorMessage(error.response)
      }).finally(() => {
        state.working = false
      })
    }

    const emailRef = ref(null)
    onMounted(() => {
      emailRef.value.focus()
    })

    return {
      emailRef,
      state,
      signup
    }
  }
})
</script>
