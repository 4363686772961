<template>
  <modal :dialogClass="'modal-xl modal-dialog-scrollable'" v-on:close-modal="closeModal">
    <template v-slot:modaltitle>
       Edit monitor
    </template>
    <template v-slot:modalbody>
      <ScheduleForm :schedule="props.schedule" v-on:refresh="refresh" ></ScheduleForm>
    </template>

  </modal>
</template>

<!-- notifications -->

<script>
// Reference from https://github.com/davidnotplay/vue-my-dropdown/blob/master/src/vue-my-dropdown.vue

import Modal from '@/views/components/Modal.vue'
import { reactive } from 'vue'

import ScheduleForm from '@/views/components/ScheduleForm.vue'
export default {
  name: 'HistoryResult',
  components: {
    Modal,
    ScheduleForm
  },
  props: {
    schedule: Object
  },
  emits: ['close-form', 'refresh'],
  setup (props, { emit }) {
    const state = reactive({

    })

    const closeModal = () => {
      emit('close-form')
    }

    const refresh = (schedule) => {
      emit('refresh', schedule)
      closeModal()
    }

    return {
      closeModal,
      props,
      state,
      refresh
    }
  }
}
</script>
