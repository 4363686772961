<template>
  <MainLayout>
    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 offset-md-0">
        <div class="page-title mb-3">
          <h3>Settings</h3>
        </div>

        <div class="card">
          <div class="card-body">
            <form
              autocomplete="off"
              @submit.prevent="submitForm"
            >
              <div class="row">
                <div class="col-12 col-md-6 mt-3">
                  <label>Email</label>
                  <input
                    v-model="state.form_data.email"
                    type="text"
                    class="form-control"
                    disabled
                  >
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Timezone</label>
                  <Multiselect
                    v-model="state.form_data.profile.timezone"
                    :required="true"
                    :create-tag="false"
                    :placeholder="'Select timezone'"
                    :searchable="true"
                    :options="state.options"
                  />
                </div>
                <div class="col-12  mt-3" />
                <div class="col-12 col-md-6 mt-3">
                  <label>First name</label>
                  <input
                    v-model="state.form_data.first_name"
                    type="text"
                    name="first-name"
                    class="form-control"
                    required
                    placeholder="First name"
                  >
                </div>
                <div class="col-12 col-md-6 mt-3">
                  <label>Last name</label>
                  <input
                    v-model="state.form_data.last_name"
                    type="text"
                    class="form-control"
                    name="last-name"
                    required
                    placeholder="Last name"
                  >
                </div>
                <div class="col-12  mt-3">
                  <div class="form-check">
                    <div class="checkbox">
                      <input
                        id="change-password"
                        v-model="state.show_change_password"
                        type="checkbox"
                        class="form-check-input"
                      >
                      <label for="change-password">Change password</label>
                    </div>
                  </div>
                </div>
              </div>
              <transition name="fade">
                <div
                  v-if="state.show_change_password"
                  class="row"
                >
                  <div class="col-12 col-md-6 mt-3">
                    <label>Password</label>
                    <input
                      v-model="state.form_data.new_password"
                      type="password"
                      required
                      name="new-password"
                      class="form-control"
                      placeholder="New password"
                    >
                  </div>
                  <div class="col-12 col-md-6 mt-3">
                    <label>Confirm new password</label>
                    <input
                      v-model="state.form_data.confirm_new_password"
                      type="password"
                      name="confirm-new-password"
                      class="form-control"
                      required
                      placeholder="Confirm new password"
                    >
                  </div>
                </div>
              </transition>
              <div class="row">
                <div class="col-12 mt-4">
                  <h6>Notifications</h6>
                  <div
                      class="d-flex align-vertical-center"
                      @click="state.form_data.profile.receive_email_notification = !state.form_data.profile.receive_email_notification"
                    >
                      <a class="d-flex pointer">
                        <i
                          v-if="state.form_data.profile.receive_email_notification"
                          class="bi bi-toggle-on large enable-on"
                        />
                        <i
                          v-else
                          class="bi bi-toggle-off large enable-off"
                        />
                      </a>
                        &nbsp;
                      <label>
                        Email notification
                      </label>
                    </div>

                    <div
                      class="d-flex align-vertical-center mt-3"
                      @click="state.form_data.profile.receive_report = !state.form_data.profile.receive_report"
                    >
                      <a class="d-flex pointer">
                        <i
                          v-if="state.form_data.profile.receive_report"
                          class="bi bi-toggle-on large enable-on"
                        />
                        <i
                          v-else
                          class="bi bi-toggle-off large enable-off"
                        />
                      </a>
                        &nbsp;
                      <label >
                        Weekly report
                      </label>
                    </div>
                  
                  <div class="d-flex mt-3" >

                    <div
                      class="d-flex align-vertical-center"
                      @click="state.form_data.profile.daily_report = !state.form_data.profile.daily_report"
                    >
                      <a class="d-flex pointer">
                        <i
                          v-if="state.form_data.profile.daily_report"
                          class="bi bi-toggle-on large enable-on"
                        />
                        <i
                          v-else
                          class="bi bi-toggle-off large enable-off"
                        />
                      </a>
                        &nbsp;
                      <label >
                        Daily report
                      </label>
                    </div>

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div
                      class="d-flex align-vertical-center"
                      v-bind:class="{'disabled':!state.form_data.profile.daily_report}"
                      @click="state.form_data.profile.daily_report_only_on_error = !state.form_data.profile.daily_report_only_on_error"
                    >
                      <a class="d-flex pointer">
                        <i
                          v-if="state.form_data.profile.daily_report_only_on_error"
                          class="bi bi-toggle-on large enable-on"
                        />
                        <i
                          v-else
                          class="bi bi-toggle-off large enable-off"
                        />
                      </a>
                        &nbsp;
                      <label >
                        Only send if there is any error
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-4">
                  <div class="d-flex">
                    <div v-if="!state.form_data.profile.telegram_username">
                      <a
                        class="d-flex pointer align-vertical-center"
                        :href="state.form_data.profile.telegram_start_link"
                        target="_blank"
                        @click="checkRefreshTelegram"
                      >
                        <i class="bi bi-telegram" />
                        &nbsp;
                        Connect with Telegram
                      </a>
                      <div class="alert alert-light-primary color-primary mt-2">
                        <p>
                          If you don't have native Telegram app and use webapp, copy this message:<br>
                          <span class="code">{{ state.form_data.profile.telegram_start_message }}</span>
                          <a
                            class="btn"
                            title="Copy to clipboard"
                            @click="copyToClipBoard(state.form_data.profile.telegram_start_message)"
                          ><i class="bi bi-clipboard" /></a>
                          <br>and send to <a :href="'https://t.me/'+Config.TELEGRAM_USER_NAME"><strong>{{ Config.TELEGRAM_USER_NAME }}</strong></a>
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <label for="enableSchedule">
                          Integrated Telegram ({{ state.form_data.profile.telegram_username }}).
                          <br/>Now you can select Telegram channel in your alert contacts.
                        </label>
                      <div class="alert alert-light-primary color-primary mt-2">
                        <a
                          class="d-flex pointer align-vertical-center"
                          :href="state.form_data.profile.telegram_start_link"
                          target="_blank"
                          @click="checkRefreshTelegram"
                        >
                          <i class="bi bi-telegram" />
                          &nbsp;
                          Click here if you want to change Telegram account
                        </a>
                        <hr>
                        <p>
                          If you don't have native Telegram app and use webapp, copy this message:<br>
                          <span class="code">{{ state.form_data.profile.telegram_start_message }}</span>
                          <a
                            class="btn"
                            title="Copy to clipboard"
                            @click="copyToClipBoard(state.form_data.profile.telegram_start_message)"
                          ><i class="bi bi-clipboard" /></a>
                          <br>and send to <a :href="'https://t.me/'+Config.TELEGRAM_USER_NAME"><strong>{{ Config.TELEGRAM_USER_NAME }}</strong></a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-12 mt-4" v-if="state.form_data.profile.user_plan">
                    <h6>Your Plan</h6>
                    <p>Name: <span class="badge bg-light text-dark" >{{state.form_data.profile.user_plan.name}}</span> </p>
                    <p>Max schedules: <span class="badge bg-light text-dark">{{state.form_data.profile.user_plan.max_schedules}}</span> </p>
                </div> -->

                <div class="col-12 mt-3">
                  <button
                    type="submit"
                    class="btn btn-primary me-1 mb-1 mt-3"
                    :disabled="state.working"
                  >
                    <Spinner :show="state.working" />Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  onUnmounted

  //   ref,
} from 'vue'

import MainLayout from '@/views/layouts/Main.vue'
import Utils from '@/helpers/Utils'
import UserHelper from '@/helpers/UserHelper'
import Alert from '@/helpers/Alert'
import { userStore } from '@/stores/UserStore'
import Multiselect from '@vueform/multiselect'
import Config from '@/helpers/Config'
export default defineComponent({
  page_title: 'Update profile',
  name: 'UpdateProfile',
  components: {
    MainLayout,
    Multiselect
  },
  props: [],

  setup () {
    const state = reactive({
      working: false,
      show_change_password: false,
      timezones: [],
      interval_telegram: null,
      form_data: {
        name: '',
        new_password: '',
        confirm_new_password: '',
        profile: reactive({
          phone: '',
          timezone: 'UTC'
        })
      },
      options: []
    })

    const fetchTimezones = () => {
      Utils.request(true)
        .get('/timezone/')
        .then((response) => {
          state.options = response.data
          // console.log('response',response)
        })
    }

    const refreshUser = () => {
      Utils.request()
        .get('/user/')
        .then((response) => {
          const user = response.data.results[0]
          userStore.setData(user)
          UserHelper.setUser(user)
          state.form_data = { ...state.form_data, ...Utils.clone(user) }
        })
        .catch((error) => {
          console.log('error', error)
          Utils.showErrorMessage(error)
        })
    }

    onMounted(() => {
      refreshUser()
      fetchTimezones()
    })

    onUnmounted(() => {
      if (state.interval_telegram) {
        clearInterval(state.interval_telegram)
      }
    })

    const submitForm = () => {
      // e.preventDefault();

      if (state.working) {
        return
      }

      const formData = {
        first_name: state.form_data.first_name,
        last_name: state.form_data.last_name,
        profile: state.form_data.profile
        // email: state.form_data.email,
      }

      if (state.show_change_password && (state.form_data.new_password !== '' || state.form_data.confirm_new_password !== '')) {
        if (
          state.form_data.confirm_new_password !== state.form_data.new_password
        ) {
          return Alert.error(
            'Error',
            'Confirm new password must match new password'
          )
        }
        formData.password = state.form_data.new_password
      }
      state.working = true
      Utils.request(true)
        .put('/me/', formData)
        .then((response) => {
          Alert.showSuccessToast('Your profile have been updated')
          UserHelper.setUser(response.data)
          userStore.fetchData()
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.working = false
        })
    }

    const refreshTelegramResult = () => {
      console.log('Checking new telegram result')
      const oldTelegramUsername = state.form_data.profile.telegram_username
      Utils.request()
        .get('/user/')
        .then((response) => {
          const user = response.data.results[0]
          const newTelegramUsername = user.profile.telegram_username
          if (newTelegramUsername !== oldTelegramUsername) {
            //  state.form_data.profile.receive_telegram_notification = user.profile.receive_telegram_notification
            state.form_data.profile.telegram_username = newTelegramUsername
            Alert.showSuccessToast('You integrated with your Telegram account successfully')
            clearInterval(state.interval_telegram)
            state.interval_telegram = null
          }
        })
        .catch((error) => {
          Utils.showErrorMessage(error)
        })
    }

    const checkRefreshTelegram = () => {
      console.log('need to check')
      if (!state.interval_telegram) {
        state.interval_telegram = setInterval(() => {
          refreshTelegramResult()
        }, 5000)
      }
    }

    const copyToClipBoard = (str) => {
      state.copied_to_clipboard = true
      Utils.copyToClipboard(str)
      Alert.showSuccessToast('Copied to clipboard')
      checkRefreshTelegram()
    }
    return {
      state,
      submitForm,
      checkRefreshTelegram,
      Config,
      copyToClipBoard
    }
  }
})
</script>
