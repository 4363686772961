<template>
  <div class="loading">
    <span>
      <div class="spinner-border text-info spinner-border-sm" role="status">
        <span class="visually-hidden vertical-center">Loading...</span>
      </div>
      {{ props.text }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Loading',
  props: {
    text: String
  },
  setup (props) {
    return {
      props
    }
  }
})
</script>
