<template>
  <HistoryResult
    v-if="state.historyResult"
    :data="state.historyResult"
    v-on:close-form="closeForm"
  ></HistoryResult>
  <div class="empty-result" v-if="state.list.length == 0 && !state.working">
    <img src="/images/empty-box.svg" />
    <p>No results yet</p>
  </div>

  <div v-else class="table-responsive">
    <table class="table">
      <thead>
        <Column :schedule_id="props.schedule_id"></Column>
      </thead>

      <tbody>
        <Skeleton
          :count_column="state.countColumn"
          v-if="state.working"
        ></Skeleton>

        <tr v-else v-for="item in state.list" :key="item.id">
          <td>
            <div
              class="notification-item"
              v-bind:class="{ read: item.read == true }"
            >
              <div class="unread-icon" v-if="item.read == false"></div>
              <router-link
                v-if="
                  item.relation_name && item.relation_name == 'core.schedule'
                "
                v-bind:class="{ 'font-extrabold': item.read == false }"
                class="font-semibold mt-3"
                :to="{ name: 'ViewSchedule', params: { id: item.relation_id } }"
              >
                {{ item.title }}
              </router-link>
              <h6
                class="font-semibold mt-3"
                v-bind:class="{ 'font-extrabold': item.read == false }"
                v-else
              >
                {{ item.title }}
              </h6>
              <p>{{ item.message }}</p>
              <status-indicator :status="item.category"></status-indicator>
              &nbsp; <small>{{ Utils.displayTime(item.created) }}</small>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Pagination
    v-if="!state.working"
    :data="state.pagination"
    v-on:update:page="setPage"
  ></Pagination>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  onMounted
  //   ref,
} from 'vue'

import Column from '@/views/components/NotificationEmbbed/Column.vue'
import Pagination from '@/views/components/Pagination.vue'

import HistoryResult from '@/views/components/HistoryResult.vue'

import Skeleton from '@/views/components/Skeleton.vue'
import StatusIndicator from '@/views/components/StatusIndicator.vue'

import Config from '@/helpers/Config'
import Utils from '@/helpers/Utils'
import { unreadNotificationStore } from '@/stores/UnreadNotificationStore'
import { Paginate, Notification, History } from '@/models/interfaces'

export default defineComponent({
  name: 'NotificationEmbbed',

  components: {
    Column,
    Pagination,
    Skeleton,
    StatusIndicator,
    HistoryResult
  },
  props: {
    schedule_id: String
  },
  setup (props) {
    interface State {
      working: boolean;
      historyResult: any;
      countColumn: number;
      pagination: Paginate;
      list: History[];
    }

    interface FormData {
      limit: number;
      offset: number;
      schedule?: string;
    }

    const state = reactive<State>({
      working: false,
      historyResult: null,
      countColumn: props.schedule_id ? 3 : 4,
      pagination: reactive<Paginate>({
        limit: Config.LIMIT,
        current_page: 1,
        total_entries: 0
      }),
      list: []
    })

    const fetchList = () => {
      if (state.working) {
        return
      }
      state.working = true

      const formData: FormData = {
        limit: state.pagination.limit,
        offset: (state.pagination.current_page - 1) * state.pagination.limit
      }

      if (props.schedule_id) {
        formData.schedule = props.schedule_id
      }

      Utils.request()
        .get('/notification/?' + Utils.serialize(formData))
        .then((response) => {
          state.pagination.total_entries = response.data.count
          state.list = response.data.results
          // Refresh count unread notifications
          unreadNotificationStore.fetchData()
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.working = false
        })
    }
    const setPage = (page: number) => {
      state.pagination.current_page = page
      fetchList()
    }

    onMounted(() => {
      fetchList()
    })

    const closeForm = () => {
      state.historyResult = null
    }

    const viewhistoryResult = (item: Notification) => {
      state.historyResult = item.options
    }

    return {
      state,
      setPage,
      Utils,
      fetchList,
      props,
      viewhistoryResult,
      closeForm
    }
  }
})
</script>
