import { Store } from './main'


interface SelectSchedulesWrapper {
  active: boolean,
  ids: Array<number>,
}

class SelectSchedulesStore extends Store<SelectSchedulesWrapper> {
  protected data(): SelectSchedulesWrapper {

    return this.defaultData()
  }

  defaultData(){
    return {
      active: true,
      ids: []
    }
  }

  toggleActive() {
    this.state.active = !this.state.active
  }

  addID(id: number) {
    this.state.ids.push(id)
  }

  toogleID(id: number) {
    let index = this.state.ids.indexOf(id);
    if ( index == -1){
      this.state.ids.push(id)
    }else{
      this.state.ids.splice(index, 1)
    }
    
  }
  reset() {
    this.state.ids = []
    this.state.active = true
    //  = this.defaultData()
  }

}

export const selectSchedulesStore: SelectSchedulesStore = new SelectSchedulesStore()
// export const scheduleListViewStore: ScheduleListViewStore = new ScheduleListViewStore()