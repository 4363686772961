<template>
    <p><strong>Note:</strong> error that match any defined rules will alert to you</p>
    <div class="buttons mb-3">
        <p class="mb-2">Select below errors if you wish to get alert:</p>
        <button v-for="(text, key) in state.available_errors" v-on:click="addAlert(key)" v-bind:key="key" type="button" class="btn btn-outline-danger">
            {{text}}
        </button>
    </div>

    <p class="mb-1" >Defined errors to alert:</p>
    <div class="alert alert-secondary" v-if="state.when.length ==0" >There is no rule yet</div>
    <div v-else>

            <div class="tags">
                <div class="tag-item tag-error"  v-for="(item,index) in state.when" v-bind:key="item"   >
                    <p class="text" >{{Config.LIST_HTTTP_ERRORS[item.type]}}
                    <span v-if="item.value">: <strong>{{item.value}}</strong></span></p>
                    <p class="icon"  v-on:click="removeAlert(index)">
                        <i class="bi bi-x"></i>
                    </p>

                </div>

            </div>

            <div class="mt-3">
                <p>Repeated failure number before alert to you:</p>
                <input type="number" min="0" max="10" class="form-control" v-model="state.continuous_failure_times" />
            </div>
    </div>

</template>

<script>
// This is for Daily, Hourly and Minutely
import { defineComponent, reactive, computed } from 'vue'
import Alert from '@/helpers/Alert'
import Config from '@/helpers/Config'
export default defineComponent({
  name: 'HttpAlertConfig',
  props: {
    data: Object
  },
  setup (props) {
    const addAlert = (alertKey) => {
      if (Config.SINGLE_HTTTP_ERRORS.indexOf(alertKey) !== -1) {
        state.when.push({ type: alertKey })
      } else {
        Alert.inputText('Input keyword').then((text) => {
          if (!text) {
            return
          }
          state.when.push({ type: alertKey, value: text })
        })
      }
    }

    const removeAlert = (index) => {
      state.when.splice(index, 1)
    }

    // let init_when =
    const state = reactive({
      when: props.data.when ?? [],
      continuous_failure_times: props.data.continuous_failure_times ?? 0,
      available_errors: computed(() => {
        const result = {}
        for (const key in Config.LIST_HTTTP_ERRORS) {
          if (Config.SINGLE_HTTTP_ERRORS.indexOf(key) === -1) {
            result[key] = Config.LIST_HTTTP_ERRORS[key]
          } else {
            const found = state.when.find(item => item.type === key)
            if (!found) {
              result[key] = Config.LIST_HTTTP_ERRORS[key]
            }
          }
        }
        return result
      })
    })

    const getValue = () => {
      return {
        when: state.when,
        continuous_failure_times: parseInt(state.continuous_failure_times)
      }
    }

    return { props, state, Config, addAlert, removeAlert, getValue }
  }
})
</script>
