<template>
 <form v-on:submit.prevent="submitForm">
  <section class="section">
      <div class="card">

        <div class="card-content">
            <div class="card-body">
              <h6 >General</h6>
              <div class="row">
                  <div class="col-12 col-md-3 mb-3">
                    <label class="mb-1" >Name</label>
                    <input
                        type="text"
                        class="form-control"
                        required
                        v-model="state.form_data.name"
                        placeholder="Name"
                        />
                  </div>
                  <div class="col-12 col-md-3 mb-3">
                    <label class="mT-15 mb-1">Category</label>
                    <fieldset class="form-group">
                        <select
                          class="form-select"
                          id="select-category"
                          v-model="state.form_data.category"
                          :disabled="state.form_data.id"
                          v-on:change="changeCategory"
                          >
                          <option
                              v-for="(category_text, category) in state.map_categories"
                              :key="category"
                              v-bind:value="category"
                              >
                              {{ category_text }}
                          </option>
                        </select>
                    </fieldset>
                  </div>
                  <div class="col-12 col-md-4 mb-3">
                    <label class="mT-15">&nbsp;</label>
                    <div class="d-flex align-vertical-center">

                            <a class="d-flex pointer" v-on:click="state.form_data.enabled = !state.form_data.enabled" >
                            <i class="bi bi-toggle-on large enable-on" v-if="state.form_data.enabled" ></i>
                            <i class="bi bi-toggle-off large enable-off" v-else></i>
                            </a>
                            &nbsp;&nbsp;
                          <label for="enableSchedule">
                            Enabled
                          </label>

                    </div>
                  </div>
              </div>
            <div class="mt-3"></div>
            <div v-if="state.show_config_rrule">
              <Rrule :data="state.form_data.rrule" :option="{category:state.form_data.category}" :started_local="state.form_data.started_local" ref="rrule" ></Rrule>
            </div>
            <div class="mt-3"></div>
            <Category ref="category"  :schedule="state.form_data"  ></Category>

            <div class="mt-4" v-if="state.form_data.category == Config.HTTP">
                <h6>Config Alert</h6>
                <HttpAlertConfig  ref="httpAlertConfig" :data="state.form_data.options.alert_condition ? state.form_data.options.alert_condition :state.default_http_alert" ></HttpAlertConfig>
            </div>
            <div class="mt-4" v-if="state.form_data.category == Config.DISK_STATUS">
                <h6>Config Alert</h6>
                <DiskStatusAlertConfig  ref="diskStatusAlertConfig" :data="state.form_data.options.alert_condition ? state.form_data.options.alert_condition :state.default_disk_status_alert" ></DiskStatusAlertConfig>
            </div>


            <div class="mt-4" >
              <h6>Tags</h6>
              <SelectTag ref="selectTag" :init_tags="state.init_tags" />
          </div>

          <div class="mt-5" v-if="state.form_data.category != Config.SEND_EMAIL" >
              <h6>Alert Contacts</h6>
              <SelectContactTag ref="selectContactTag" :placeholder="'Select alert contact'" :init_tags="state.init_contacts" :init_contact_email="state.init_contact_email" />
          </div>

            <div class="col-12 mt-5">
                <button type="submit" class="btn btn-primary w-100 me-1  mb-1" :disabled="state.working">
                  <Spinner :show="state.working" ></Spinner>Save</button>
            </div>
            </div>
        </div>
      </div>
  </section>

</form>
</template>

<script>

import Rrule from '@/views/components/rrule/Rrule.vue'
import Category from '@/views/components/category/Category.vue'
import HttpAlertConfig from '@/views/components/HttpAlertConfig/HttpAlertConfig'
import DiskStatusAlertConfig from '@/views/components/DiskStatusAlertConfig/DiskStatusAlertConfig'
import SelectTag from '@/views/components/SelectTag'
import SelectContactTag from '@/views/components/SelectContactTag'
import Config from '@/helpers/Config'
import Utils from '@/helpers/Utils'
import UserHelper from '@/helpers/UserHelper'

import Alert from '@/helpers/Alert'
import router from '@/router'
// import Services from "@/helpers/Services";
import { tagStore } from '@/stores/TagStore'
import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'
import {
  reactive,
  defineComponent,
  ref
} from 'vue'

export default defineComponent({
  name: 'ScheduleForm',
  components: {
    Rrule,
    Category,
    HttpAlertConfig,
    DiskStatusAlertConfig,
    SelectTag,
    SelectContactTag
  },
  emits: ['refresh'],
  props: {
    schedule: Object
  },
  setup (props, { emit }) {
    const state = reactive({
      fetching: false,
      working: false,
      show_config_rrule: true,
      default_http_alert: {
        when: [{
          type: 'RESPONSE_CODE_HTTP_ERROR'
        }, {
          type: 'OTHER_HTTP_ERROR'
        }
        ],
        continuous_failure_times: 2
      },
      default_disk_status_alert: {
        "over_usage_percentage": 90
      },
      init_tags: [],
      init_contacts: [],
      init_contact_email: '',
      schedule_id: props.id,
      map_categories: Config.MAP_CATEGORY,
      form_data: Utils.clone(props.schedule)
    })

    if (props.schedule) {
      if (props.schedule.tags) {
        state.init_tags = props.schedule.tags.map(item => { return item.name })
      }

      if (props.schedule.init_contact_email) {
        state.init_contact_email = JSON.parse(JSON.stringify(props.schedule.init_contact_email))
      }
      if (props.schedule.contacts) {
        state.init_contacts = props.schedule.contacts.map(item => { return item.id })
      }
    }

    if (!state.form_data.rrule) {
      state.form_data.rrule = Config.DEFAULT_RRULE[state.form_data.category]
    }

    const submitForm = async () => {
      if (state.working) {
        return
      }

      const tags = selectTag.value.state.value.map((item) => {
        return {
          name: item
        }
      })
      let contacts = []

      if (state.form_data.category !== Config.SEND_EMAIL) {
        contacts = selectContactTag.value.state.value.map((item) => {
          return {
            id: item
          }
        })
      }

      let rruleString
      try {
        rruleString = rrule.value.getValue()
      } catch (error) {
        Alert.error('Error', error)
        return
      }

      let options
      try {
        options = category.value.getValue()

        if (state.form_data.category === 'HTTP') {
          const alertConfig = httpAlertConfig.value.getValue()
          if (alertConfig.when.length > 0) {
            options.alert_condition = alertConfig
          }
        }
        else if (state.form_data.category === Config.DISK_STATUS) {
          let alert_config = diskStatusAlertConfig.value.getValue()
          console.log("alert_config", alert_config)
          options.alert_condition = alert_config
        }
      } catch (error) {
        Alert.error('Error', error)
        return
      }

      state.working = true
      const sendData = {
        name: state.form_data.name,
        rrule: rruleString,
        started: Utils.toUTCDateTime(rrule.value.state.formData.started_local, UserHelper.getUserTimezoneOffset()),
        enabled: state.form_data.enabled,
        category: state.form_data.category,
        options: options,
        tags: tags,
        contacts: contacts
      }

      // return;
      if (props.schedule.id) {
        sendData.id = props.schedule.id
      }

      const url = sendData.id
        ? `/schedule/${sendData.id}/`
        : '/schedule/'
      const method = sendData.id ? 'put' : 'post'
      Utils.request()[method](url, sendData).then(response => {
        if (method === 'post') {
          Alert.showSuccessToast('Your monitor have been added')
          router.push({
            name: 'ViewSchedule',
            params: {
              id: response.data.id
            }
          })
        } else {
          Alert.showSuccessToast('Your monitor have been updated')
        }
        state.working = false
        // Refresh list tags and list scheduled tests
        refreshStore(response)
      }).catch((error) => {
        Utils.showErrorMessage(error.response)
      }).finally(() => {
        state.working = false
      })
    }

    const refreshStore = (response = null) => {
      Promise.all([tagStore.fetchList(true), scheduleListViewStore.fetchList()]).then((values) => {
        const listScheduleResponse = values[1]
        scheduleListViewStore.setList(listScheduleResponse)
        if (response) {
          emit('refresh', response.data)
        }
      })
    }

    const rrule = ref(null)
    const category = ref(null)
    const httpAlertConfig = ref(null)
    const diskStatusAlertConfig = ref(null)
    const selectTag = ref()
    const selectContactTag = ref()

    const changeCategory = (e) => {
      state.form_data.rrule = Config.DEFAULT_RRULE[e.target.value]
      state.show_config_rrule = false
      setTimeout(() => {
        state.show_config_rrule = true
      })
    }

    return {
      state,
      props,
      Config,
      submitForm,
      rrule,
      category,
      httpAlertConfig,
      diskStatusAlertConfig,
      changeCategory,
      selectTag,
      selectContactTag
    }
  }
})
</script>
