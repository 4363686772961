<template>
<tr>
  <th width="10%" >Status</th>
  <th width="25%" >From</th>
  <th width="25%">To</th>
  <th width="20%">Duration</th>
  <th width="20%">Log</th>

</tr>
</template>

<script>
import {

  defineComponent
} from 'vue'

export default defineComponent({
  name: 'ColumnHistory',
  props: {
    schedule_id: String
  },
  setup (props) {
    return {
      props
    }
  }
})
</script>
