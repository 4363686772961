<template>
    <div id="auth">

        <div class="row h-100">
            <div class="col-lg-6 col-xl-7 col-xxl-5 col-12">
                <div id="auth-left">
                    <div class="auth-logo">
                        <a href="/" class="text-center d-block"><img src="/images/logo/logo.png" alt="Logo"></a>
                    </div>

                    <slot></slot>

                </div>
            </div>
            <div class="col-lg-6 col-xl-5 col-xxl-7 d-none d-lg-block">
                <div id="auth-right">

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
  defineComponent
} from 'vue'

export default defineComponent({
  name: 'AnonymousLayout'
})
</script>
