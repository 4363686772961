<template>
  <MainLayout>
    <div class="page-title mb-3">
      <div class="row">
        <div class="col-6">
          <h3>Contacts</h3>
        </div>
        <div class="col-6">
          <div class="float-end d-flex vertical-align-middle">
            <router-link
              class="pointer btn btn-outline-primary"
              :to="{ name: 'NewContact' }"
              >New</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="card">
        <div class="card-body">
          <div
            class="empty-result"
            v-if="
              state.list.length == 0 &&
              !state.working &&
              state.loadedTimes <= 1
            "
          >
            <img src="/images/empty-box.svg" />
            <p>
              No results available, Please set up first contact from
              <router-link class="pointer orange" :to="{ name: 'NewContact' }">
                this link
              </router-link>
            </p>
          </div>

          <div
            class="alert alert-light-primary color-primary mt-3"
            v-else-if="!state.working && state.list.length == 0"
          >
            No result yet
          </div>

          <table
            v-else-if="state.working"
            class="mobile-table table list-contacts mt-3"
            cellspacing="0"
            width="100%"
          >
            <tbody>
              <Skeleton v-if="state.working" :count_column="5"></Skeleton>
            </tbody>
          </table>
          <div v-else>
            <ListContact
              :contacts="state.list"
              v-on:remove="removeItem"
              v-on:toggleEnable="toggleEnable"
            ></ListContact>
            <p>Total: {{ state.list.length }} records</p>
          </div>
        </div>
      </div>
    </section>
  </MainLayout>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted } from 'vue'
// import Utils from "@/helpers/Utils";
import MainLayout from '@/views/layouts/Main.vue'
import ListContact from '@/views/contact/ListContact.vue'
import Skeleton from '@/views/components/Skeleton.vue'
import Services from '@/helpers/Services'
import Utils from '@/helpers/Utils'
import { Contact } from '@/models/interfaces'
export default defineComponent({
  page_title: 'Contacts',
  name: 'Contact',
  props: [],
  components: {
    MainLayout,
    ListContact,
    Skeleton
  },

  setup () {
    interface State {
      working: boolean,
      list: Contact[],
      loadedTimes: number
    }

    const state = reactive<State>({
      working: false,
      list: [],
      loadedTimes: 0
    })

    const fetchList = () => {
      state.working = true
      Services.listContact().then((contacts) => {
        state.list = contacts
        state.loadedTimes++
      })
        .finally(() => {
          state.working = false
        })
    }

    onMounted(() => {
      fetchList()
    })

    const removeItem = (item:Contact) => {
      Services.removeContact(item).then(() => {
        fetchList()
      })
    }

    const toggleEnable = (item:Contact) => {
      const temp = Utils.clone(item)
      temp.enabled = !temp.enabled
      Services.updateContact(temp).then((contact) => {
        for (let i = 0; i < state.list.length; i++) {
          if (state.list[i].id === contact.id) {
            state.list[i] = contact
            break
          }
        }
      })
    }

    return {
      state,
      removeItem,
      toggleEnable
    }
  }
})
</script>
