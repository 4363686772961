import { Store } from './main'
import Utils from '@/helpers/Utils'
import { Schedule, ScheduleListView, ScheduleSearch } from '@/models/interfaces'
// import { Schedule } from "@/models/interfaces";
// import {Schedule} from "@/models/interfaces"

// interface Schedule{
//     id: string,
//     name: string
// }

// interface ScheduleListViewWrapper {
//     preFetch: Boolean,
//     data: ScheduleListView
// }

class ScheduleListViewStore extends Store<ScheduleListView> {
  defaultSearch (): ScheduleSearch {
    return {
      search: '',
      category_list: [],
      enabled_list: [],
      latest_history_status_list: []
    }
  }

  defaultValue () {
    return {
      view: '',
      scroll_position: 0,
      selectedDate: new Date(),
      search: this.defaultSearch(),
      list: [],
      home_load_list_times: 0
    }
  }

  protected data (): ScheduleListView {
    return this.defaultValue()
  }

  reset () {
    this.state = this.defaultValue()
  }

  setView (view:string) {
    this.state.view = view
  }

  setSelectedDate (date:Date) {
    this.state.selectedDate = date
  }

  resetSearch () {
    this.state.search = this.defaultSearch()
  }

  setSearch (search:ScheduleSearch) {
    this.state.search = search
  }

  setScrollPosition (value:number) {
    this.state.scroll_position = value
  }

  setList (list:Array<Schedule>) {
    this.state.list = list
  }

  updateItem (schedule:any) {
    for (let i = 0; i < this.state.list.length; i++) {
      if (this.state.list[i].id == schedule.id) {
        this.state.list[i] = schedule
        break
      }
    }
  }

  increaseHomeLoadListTimes () {
    this.state.home_load_list_times++
  }

  resetHomeLoadListTimes () {
    this.state.home_load_list_times = 0
  }

  fetchList (is_get_all = false): Promise<Schedule[]> {
    const formData:{ [key: string]: any; } = {}
    formData.ordering = 'name'

    if (!is_get_all) {
      if (this.state.search.search) {
        formData.search = this.state.search.search
      }

      if (this.state.search.category_list.length > 0) {
        formData.category_list = this.state.search.category_list.join(',')
      }

      if (this.state.search.enabled_list) {
        formData.enabled_list = this.state.search.enabled_list.join(',')
      }
      if (this.state.search.latest_history_status_list) {
        formData.latest_history_status_list = this.state.search.latest_history_status_list.join(',')
      }
    }

    return new Promise((resolve, reject) => {
      Utils.request().get('/schedule/?' + Utils.serialize(formData))
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
          reject()
        })
    })
  }

  removeItem (schedule:any) {
    for (let i = 0; i < this.state.list.length; i++) {
      if (this.state.list[i].id == schedule.id) {
        this.state.list[i] = schedule
        this.state.list.splice(i, 1)
        break
      }
    }
  }
}

export const scheduleListViewStore: ScheduleListViewStore = new ScheduleListViewStore()
