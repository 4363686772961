/* eslint-disable */
import Config from '@/helpers/Config'
import Alert from './Alert'
import UserHelper from './UserHelper'
import axios from 'axios'
import moment from 'moment'


// function getCache(key: string, isObject = false): any {
//   return isObject ? LocalStorage.getObject(key) : LocalStorage.get(key)

// }


function uploadFile(path: String, formData: any) {
  let obj_header: any = {
    'Content-Type': 'multipart/form-data'
  };
  let token = UserHelper.getToken()
  if (token) {
    obj_header["Authorization"] = 'Token ' + token
  }

  return axios.post(Config.SERVER + path,
    formData,
    {
      headers: obj_header
    }
  );
}

function request(required_auth: boolean = true) {
  let init_obj = {
    baseURL: Config.SERVER,
    timeout: 20000,
    headers: {}
  }


  if (required_auth) {
    let token = UserHelper.getToken()
    if (token) {
      init_obj.headers = { 'Authorization': 'Token ' + token }
    }
  }

  let i = axios.create(init_obj)
  return i
}


function getRequestByPath(path: string): any {
  return new Promise((resolve, reject) => {
    request().get(Config.SERVER + path).then(response => {
      //console.log(response)
      resolve(response.data)
    }).catch(error => {
      reject(error)
    })
  })
}


function showErrorMessage(err_response: any) {
  let message = "";
  if (err_response == undefined){
    message = 'There is an error when process'
  }
  else if (typeof (err_response.data) == "string") {
    message = err_response.data;
  } else {
    if (err_response && err_response.data) {
      for (let key in err_response.data) {
        if (key === 'non_field_errors' || key === 'detail') {
          message += err_response.data[key] + '\n'
        } else {

          if (isNaN(Number(key))) {
            message += key + ': ' + err_response.data[key] + '\n'
          } else {
            message += err_response.data[key] + '\n'
          }

        }
      }
    } else {
      message = 'There is an error when process'
    }
  }

  Alert.error("Error", message)
}




// Get base url https://www.google.com.vn/search => https://www.google.com.vn
function getBaseUrl() {
  let url = window.location.href
  let arr = url.split('/')
  return arr[0] + '//' + arr[2]
}

function toCommas(value: string) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// Turn text to readable url if it is embbed
function urlify(text: string) {
  var urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="blank" >' + url + '</a>'
  })

}

// convert object to query params
function serialize(obj: any): string {
  var str : string[] = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

function log(...args: any) {
  if (typeof (console) !== 'undefined') {
    console.log(...args);
  }
}



function getTotalPages(count: number, limit: number): number {
  return Math.ceil(count / limit)
}

type TypeValueObject = {
  type: string,
  value: string,
};


function processElement(element: any): Array<TypeValueObject> {
  let result: any[] = [];
  if (element.tag) {
    switch (element.tag.toUpperCase()) {
      case 'INPUT':
        if (element.type == "submit") {
          result.push({ type: 'Type', value: 'Submit Button' })
        } else if (element.type == "button") {
          result.push({ type: 'Type', value: 'Button' })
        }
        else if (element.type == "email") {
          result.push({ type: 'Type', value: 'Email box' })
        }
        else if (element.type == "password") {
          result.push({ type: 'Type', value: 'Password box' })
        } else {
          result.push({ type: 'Type', value: 'Text box' })
        }
        break
      case 'BUTTON':
        if (element.type == "submit") {
          result.push({ type: 'Type', value: 'Submit button' })
        } else {
          result.push({ type: 'Type', value: 'Button' })
        }
        break

      case 'A':
        result.push({ type: 'Type', value: 'Link' })
        break
      case 'IMG':
      case 'SVG':
        result.push({ type: 'Type', value: 'Image' })
        break
    }
  }
  if (element.placeholder) {
    result.push({ type: 'Hint', value: element.placeholder })
  }
  if (element.text) {
    result.push({ type: 'Text', value: element.text })
  }
  if (result.length == 0) {
    result.push({ type: 'Type', value: 'Unable to detect' })
  }
  return result
}

function displaySessionName(item: any): string {
  return item.name ? item.name : 'Unknown name'
}

function displayTime(item: string): string {
  return moment(item).fromNow()
}


function datetimeLocal(date: string, timezone_offset: string = ''): string {
  if (timezone_offset == '') {
    // "2021-06-25T02:00:00+09:00" => 2021-06-25T02:00:00
    date = date.substr(0, 19);
  }
  let result = moment(date == '' ? new Date() : date);
  if (timezone_offset != '') {
    result = result.utcOffset(timezone_offset);
  }
  return result.format('YYYY-MM-DDTHH:mm')
}


function localToUTC(date: string, timezone_offset: string): string {
  let result = moment(date).utcOffset(timezone_offset).format('YYYY-MM-DDTHH:mm')
  return result;
}

function toUTCDateTime(date: string, timezone_offset: string): string {
  date = date + timezone_offset;
  let result = moment(date).utc().format('YYYY-MM-DDTHH:mm')
  return result;
}

function UTCLocalReadableTime(date: Date): string {
  let result = moment(date).utc().format('YYYY-MM-DD HH:mm')
  return result;
}

function localReadableTime(date: Date): string {
  let result = moment(date).format('YYYY-MM-DD HH:mm')
  return result;
}

function validURL(str: string): boolean {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

function clone(object: Object) {
  return JSON.parse(JSON.stringify(object));
}

function toJSON(str: string): any {
  let result = null;
  try {
    result = JSON.parse(str);
  } catch (e) {
    return result;
  }
  return result;
}


function copyToClipboard(str: string) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

}
function download(text: string, filename: string) {
  var blob = new Blob([text], { type: "text/plain" });
  var url = window.URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
}

function pluralText(number: number, label: string) {

  if (number < 2) {
    return `${number} ${label}`
  } else {
    return `${number} ${label}s`
  }
}


function dateDiffInMinutes(date1: string, date2: string) {
  let minutes = Math.round(moment(date1).diff(moment(date2)) / 1000 / 60);
  minutes = minutes == 0 ? 1 : minutes;
  return minutes;
}

function dateDiffReadable(date1: string, date2: string) {
  let minutes = dateDiffInMinutes(date1, date2)

  if (minutes < 60) {
    return pluralText(minutes, 'minute')
  }
  if (minutes < 60 * 24) {
    let hours = Math.round(minutes / 60)
    hours = hours == 0 ? 1 : hours;
    return pluralText(hours, 'hour')
  } else {
    let days = Math.round(minutes / 60 / 24);
    days = days == 0 ? 1 : days;
    return pluralText(days, 'day')
  }

}


export default {
  // getCache: getCache,
  request: request,
  showErrorMessage: showErrorMessage,
  getRequestByPath: getRequestByPath,
  getBaseUrl: getBaseUrl,
  toCommas: toCommas,
  urlify: urlify,
  serialize: serialize,
  log: log,
  getTotalPages: getTotalPages,
  processElement: processElement,
  displaySessionName: displaySessionName,
  displayTime: displayTime,
  validURL: validURL,
  clone: clone,
  datetimeLocal,
  toJSON,
  toUTCDateTime,
  UTCLocalReadableTime,
  copyToClipboard,
  uploadFile,
  localToUTC,
  localReadableTime,
  dateDiffReadable,
  dateDiffInMinutes,
  download,

  pluralText
}
