<template>
  <MainLayout>

    <div class="page-title mb-3">
          <h3 class="inline-block" >Select Pricing</h3>
    </div>
    <section class="section status-overview force-icon-left">

        <div class="col-12 col-md-8 offset-md-2">

            <div class="pricing">
                <div class="row align-items-center">
                    <div class="col-md-6 px-0">
                        <div class="card">
                            <div class="card-header text-center">
                                <h4 class="card-title">FREE</h4>
                            </div>
                            <h1 class="price">Basic</h1>
                            <p class="text-center">A standart features you can get</p>
                            <ul>
                                <li class="align-vertical-center" ><i class="bi bi-check-circle"></i>Max monitors: 20</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>5 minutes check</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>7 days history logging</li>
                                <li>
                                    <hr/>
                                </li>
                                <li><p><strong>Monitorings:</strong></p></li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>HTTP(S) (keyword,status code,..)</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>HTTPS expiry monitoring</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>TCP Port monitoring (SMTP, IMAP, Mysql, Redis,...)</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>Expiry domain</li>
                                <li>
                                    <hr/>
                                </li>
                                <li><p><strong>Tools & Analysis:</strong></p></li>
                                    <li class="align-vertical-center"><i class="bi bi-check-circle"></i>Downtime logging (HTTP monitoring + Port monitoring)</li>
                                    <li class="align-vertical-center"><i class="bi bi-check-circle"></i>History logging</li>
                                    <li class="align-vertical-center"><i class="bi bi-check-circle"></i>Schedule send email</li>
                                <li>
                                    <hr/>
                                </li>
                                <li><p><strong>Integrations:</strong></p></li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>Notify alert via email</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>Notify alert via Telegram</li>
                            </ul>
                            <div class="card-footer"  v-if="state.user && state.user.user && state.user.user.profile.user_plan.name == 'Basic' ">
                                <div class="alert alert-success color-primary align-vertical-center" >
                                    You are using this plan
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6 px-0">
                        <div class="card card-highlighted">
                            <div class="card-header text-center">
                                <h4 class="card-title">$10/month</h4>

                                <p></p>
                            </div>
                            <h1 class="price text-white">Premium</h1>
                            <p class="text-center text-white">Everything included in Basic and:</p>
                            <ul>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>Priority queue</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>Max monitors: 100 (more x5)</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>1 minute check (faster x5)</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>1 month history logging (longer x4)</li>
                                <li class="align-vertical-center"><i class="bi bi-check-circle"></i>Alert contacts (send to more relevant people)</li>
                            </ul>
                            <div class="card-footer" >
                                <div class="alert alert-success color-primary align-vertical-center"  v-if="state.user && state.user.user && state.user.user.profile.user_plan.name == 'Premium'" >
                                    You are using this plan
                                </div>
                                <div v-else >
                                  <button class="btn btn-outline-white btn-block" v-on:click="upgradePlan" >Upgrade Now</button>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
  </MainLayout>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive
} from 'vue'

import MainLayout from '@/views/layouts/Main.vue'
import Alert from '@/helpers/Alert'
import { userStore } from '@/stores/UserStore'
export default defineComponent({
  page_title: 'Pricing',
  name: 'Pricing',
  props: [],
  components: {
    MainLayout
  },
  setup () {
    const state = reactive({
      user: userStore.getState()
    })

    const upgradePlan = () => {
      Alert.alert('Confirm', 'This feature is under development')
    }

    return {
      state,
      upgradePlan

    }
  }

})
</script>
