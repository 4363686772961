<template>

        <left-side-bar></left-side-bar>
        <div id="main" >
            <header-nav-bar></header-nav-bar>
            <div class="main-body">
                <slot></slot>
            </div>

        </div>

</template>

<script>
import LeftSideBar from './../partials/LeftSidebar.vue'
import HeaderNavBar from './../partials/HeaderNavBar.vue'
// import FooterBar from './../partials/Footer.vue'

import {
  defineComponent,
  computed
} from 'vue'
import {
  useRoute
} from 'vue-router'
export default defineComponent({
  name: 'MainLayout',
  components: {
    LeftSideBar,
    HeaderNavBar
    // FooterBar
  },
  props: {},
  setup () {
    const route = useRoute()

    const currentLocation = computed(() => {
      const {
        ...rest
      } = route
      return rest
    })

    return {
      currentLocation
    }
  }
})
</script>
