<template>
<div class="card">
  <div class="card-body mt-2 p-2">
<div class="row" >
    <div class="col-12 mb-3 pd-3">
        <div class="list-group list-group-horizontal-sm mb-1 text-center" role="tablist">
          <a class="list-group-item sm list-group-item-action pointer"
          v-on:click="changeDateFilter(item.key)"
            v-for="item in state.date_ranges" v-bind:key="item.key"
            :class="{active:item.key == state.select_date_range}"
             >{{item.label}}</a>

        </div>
    </div>
    <div class="col-12 col-lg-8">
        <p  class="ms-3"><strong>Disk usage change</strong></p>

        <apexchart
          height="150"
          type="area"
          :options="state.options"
          :series="state.series"
          v-if="state.series.length > 0"
        ></apexchart>
        <p  class="ms-3 mb-5" v-else >No data</p>
        <p class="ms-3" ><strong>Up time</strong></p>
        <div class="changelog-chart" v-if="state.uptime.list.length > 0">
          <Popper v-for="item in state.uptime.list" v-bind:key="item.id" :class="{ up: item.category =='UP', down: item.category =='DOWN' }" :hover="true" :placement="'top'"

            :style="{ width: item.percentage + '%' }"
            :content="item.content">
            <div>&nbsp;</div>
          </Popper>

        </div>
        <p  class="ms-3 mb-0" v-else >No data</p>

    </div>
    <div class="col-12 col-lg-4">
        <div class="row">
            <div class="col-6 col-lg-12">
              <div class="card mb-1">
                  <div class="card-body status-overview px-3 py-3">
                      <div class="row">
                          <div class="col-md-4">
                              <div class="stats-icon red">
                                  <i class="bi bi-arrow-down-short"></i>
                              </div>
                          </div>
                          <div class="col-md-8">
                              <h6 class="text-muted font-semibold">Down time</h6>
                              <h6 class="font-extrabold mb-0">{{ Utils.pluralText(state.uptime.downTimeInMinutes,'minute') }}</h6>
                              <p>in {{ Utils.pluralText(state.uptime.countDownTimes,'time') }}</p>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          <div class="col-6 col-lg-12">
          <div class="card mb-0">
              <div class="card-body status-overview px-3 py-3">
                  <div class="row">
                      <div class="col-md-4">
                          <div class="stats-icon green">
                              <i class="bi bi-arrow-up-short"></i>
                          </div>
                      </div>
                      <div class="col-md-8">
                          <h6 class="text-muted font-semibold">Uptime</h6>
                          <h6 class="font-extrabold mb-0">{{state.uptime.uptime_percentage}}%</h6>
                      </div>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
</div>
  </div>

        </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, computed, reactive } from 'vue'
// import ApexCharts from "apexcharts";
import Utils from '@/helpers/Utils'
// import moment from 'moment'
import VueApexCharts from 'vue3-apexcharts'
import Popper from 'vue3-popper'
import Config from '@/helpers/Config'
import moment from 'moment'
export default defineComponent({
  name: 'DiskStatusChart',
  props: {
    id: String
  },
  components: {
    apexchart: VueApexCharts,
    Popper
  },
  // https://apexcharts.com/react-chart-demos/area-charts/datetime-x-axis/
  setup (props) {
    const state = reactive({
      fetching: true,
      isShowChart: computed(() => (state.categories.length >= 2)),
      series: [

      ],
      uptime: {
        list: [],
        countDownTimes: 0,
        downTimeInMinutes: 0,
        uptime_percentage: 0
      },
      select_date_range: '1_week',
      date_ranges: [
        {
          label: '3 months',
          key: '3_months',
          value: 3 * 30 * 24
        },
        {
          label: '1 month',
          key: '1_month',
          value: 30 * 24
        },
        {
          label: '2 weeks',
          key: '2_weeks',
          value: 14 * 24
        },
        {
          label: '1 week',
          key: '1_week',
          value: 7 * 24
        },
        {
          label: '24h',
          key: '24h',
          value: 24
        }
      ],
      options: {
        chart: {
          id: 'area-datetime',
          type: 'area',
          height: 350,
          zoom: {
            autoScaleYaxis: true
          },
          toolbar: {
            show: false
          }
        },

        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow'
        },
        xaxis: {
          type: 'datetime'
          // min: new Date('01 August 2021').getTime(),
          // tickAmount: 6,
        },

        tooltip: {
          y: {
            show: false
          },
          x: {
            show: false
          }
        },

        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        }
      }
    })

    const getFromDate = () => {
      let minusHour = 0
      for (let i = 0; i < state.date_ranges.length; i++) {
        if (state.select_date_range === state.date_ranges[i].key) {
          minusHour = state.date_ranges[i].value
          break
        }
      }

      var newTime = moment().subtract({ hours: minusHour })
      return newTime.toISOString()
    }

    const fetchPercentageChangeList = () => {
      const fromDate = getFromDate()

      const formData = {
        schedule_id: props.id,
        from_date: fromDate
      }
      Utils.request()
        .get('/disk-percentage-change/?' + Utils.serialize(formData))
        .then((response) => {

          var map_series = {}
          response.data.forEach((item) => {
            let partions = item.partitions
            partions.forEach((partion) =>{
              let name = partion["name"]
              if (!map_series[name]){
                map_series[name] = {
                  "name": name,
                  "data": []
                }
              }
              map_series[name].data.push([item.timestamp, partion.used_percentage])
            })
            
          })
          let series = []
          for (let name in map_series){
            series.push(map_series[name])
          }
          state.series = series
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.fetching = false
        })
    }
    const fetchUptimeList = () => {
      const fromDate = getFromDate()
      const formData = {
        schedule: props.id,
        ordering: 'created',
        from_date: fromDate,
        limit: 10000
      }

      Utils.request()
        .get('/changelog/?' + Utils.serialize(formData))
        .then((response) => {
          var processedList = response.data.results
          let totalMinutes = 0
          let countDownTimes = 0
          let downTimeInMinutes = 0
          if (processedList.length > 0 && processedList[0].created < fromDate) {
            processedList[0].created = fromDate
          }
          processedList.map(item => {
            item.diff_in_minutes = Utils.dateDiffInMinutes(item.updated, item.created)
            totalMinutes += item.diff_in_minutes
            if (item.category === Config.DOWN_CATEGORY) {
              countDownTimes++
              downTimeInMinutes += item.diff_in_minutes
            }
            return item
          })

          state.uptime.countDownTimes = countDownTimes
          state.uptime.downTimeInMinutes = downTimeInMinutes
          if (totalMinutes === 0) {
            state.uptime.uptime_percentage = 100
          } else {
            const downtimePercentage = downTimeInMinutes / totalMinutes * 100

            state.uptime.uptime_percentage = Math.round((100 - downtimePercentage) * 100) / 100 // round with 2 decimal
          }

          processedList.map(item => {
            item.percentage = (item.diff_in_minutes / totalMinutes) * 100
            const diff = Utils.dateDiffReadable(item.updated, item.created)
            const status = item.category === Config.DOWN_CATEGORY ? 'down' : 'up'
            item.content = `${Utils.localReadableTime(item.created)} ${status} in ${diff}`
            return item
          })

          state.uptime.list = processedList
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.working = false
        })
    }

    const changeDateFilter = (key) => {
      state.select_date_range = key
      fetchPercentageChangeList()
      fetchUptimeList()
    }

    onMounted(() => {
      fetchPercentageChangeList()
      fetchUptimeList()
    })

    onUnmounted(() => {
      if (state.chart != null) {
        state.chart.destroy()
      }
    })

    return {
      props,
      state,
      Utils,
      changeDateFilter
    }
  }
})
</script>
