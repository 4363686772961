<template>
    <div class="px-2">
        <label class="mb-1"><strong>Token:</strong></label>
        <textarea class="form-control" required rows="10"
        v-model="state.authData[type].token"
         placeholder="Input token"></textarea>

    </div>
</template>

<script>

import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'AuthHTTPBearer',
  props: {
    auth_data: Object,
    type: String
  },
  setup (props) {
    const authData = {
      ...props.auth_data
    }
    if (typeof (authData[props.type]) === 'undefined') {
      authData[props.type] = {}
    }

    const listKeys = ['token']
    listKeys.forEach(key => {
      if (typeof (authData[props.type][key]) === 'undefined') {
        authData[props.type][key] = ''
      }
    })

    const getValue = () => {
      return state.authData[props.type]
    }

    const state = reactive({
      authData: authData,
      type: props.type
    })
    return { state, getValue }
  }
})
</script>
