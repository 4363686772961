<template>
      <tr v-for="item in 3" :key="item">
        <td v-for="index in props.count_column" :key="index">
          <span
            class="skeleton-box"
            v-if="index== 0"
            style="width: 100px; height: 40px"
          ></span>
            <span
            class="skeleton-box"
            v-else-if="index== 1"
            style="width: 100%; height: 40px"
          ></span>
          <span
            v-else
            class="skeleton-box"
            style="width: 50%; height: 20px"
          ></span>
        </td>

      </tr>
</template>

<script>
import {

  defineComponent
} from 'vue'

export default defineComponent({
  name: 'Skeleton',
  props: {
    count_column: Number
  },
  setup (props) {
    return {
      props
    }
  }
})
</script>
