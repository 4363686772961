<template>
  <div id="error">
    <div class="error-page container">
      <div class="col-md-8 col-12 offset-md-2">
        <img
          class="img-error"
          src="/images/samples/error-404.png"
          alt="Not Found"
        >
        <div class="text-center">
          <h1 class="error-title">
            NOT FOUND
          </h1>
          <p class="fs-5 text-gray-600">
            The page you are looking not found.
          </p>

          <router-link
            class="btn btn-lg btn-outline-primary mt-3"
            :to="{ name: 'Home' }"
          >
            Go to Home
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent
} from 'vue'
import {
  useRoute
} from 'vue-router'

export default defineComponent({
  name: 'NotFound',
  setup () {
    const route = useRoute()
    return {
      route
    }
  }
})
</script>
