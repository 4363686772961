<template>
  <MainLayout>

    <div class="row">
       <div class="col-12 col-lg-8  offset-lg-2 offset-md-0">
            <div class="page-title mb-3">
            <div class="row ">
                <div class="col-12 col-md-12">
                  <nav aria-label="breadcrumb" class="breadcrumb-header">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link  :to="{ name: 'Home' }">List monitors</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">New</li>
                      </ol>
                  </nav>
                </div>
                <div class="col-12 col-md-6">
                  <h3>New monitor</h3>
                </div>

            </div>
          </div>
          <ScheduleForm :schedule="state.formData" ></ScheduleForm>
        </div>
    </div>

  </MainLayout>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import MainLayout from '@/views/layouts/Main.vue'
import ScheduleForm from '@/views/components/ScheduleForm.vue'
// import { useRoute } from "vue-router";
import UserHelper from '@/helpers/UserHelper'
import Config from '@/helpers/Config'

export default defineComponent({
  page_title: () => {
    return 'New schedule'
  },
  name: 'NewSchedule',
  components: {
    MainLayout,
    ScheduleForm
  },

  setup () {
    const formData = {
      name: '',
      enabled: true,
      rrule: '',
      started: '',
      category: Config.HTTP,
      options: {},
      init_contact_email: UserHelper.getUser().email
    }

    const state = reactive({
      fetching: false,
      working: false,
      schedule: null,
      formData: formData
    })

    return {
      state
    }
  }
})
</script>
