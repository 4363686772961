<template>
  
  <MainLayout>

    <Loading v-if="state.fetching" :text="'Loading monitor'"></Loading>

    <div v-else>
            <div class="page-title mb-3">
              <div class="row ">
                  <div class="col-12">
                    <nav aria-label="breadcrumb" class="breadcrumb-header">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                              <router-link  :to="{ name: 'Home' }">List monitors</router-link>
                          </li>
                          <li class="breadcrumb-item active" aria-current="page">{{pageTitle}}</li>
                        </ol>
                    </nav>
                  </div>
                  <div class="col-12 col-md-6" v-if="state.formData">
                    <h3 >{{state.formData.name}}</h3>

                  </div>

              </div>
            </div>
            <HTTPLoadTimeChart  v-if="Config.SHOW_RESPONSE_CHARTS.indexOf(state.formData.category) != -1" :id="state.formData.id" ></HTTPLoadTimeChart>
            
            <DiskStatusChart  v-if="state.formData.category == Config.DISK_STATUS" :id="state.formData.id" ></DiskStatusChart>
            
            <div class="row">
                <div class="col-12 col-lg-8">
                  <div class="card">

                        <div class="card-body mt-2">

                            <ul class="nav nav-pills mb-3" v-if="state.show_tab" >
                              <li class="nav-item">
                                <a class="nav-link pointer" v-bind:class="{ active: state.active_tab =='uptime' }"
                                  v-on:click="state.active_tab = 'uptime'"
                                >Uptime log</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link pointer" v-bind:class="{ active: state.active_tab =='history' }"
                                v-on:click="state.active_tab = 'history'"
                                >History log</a>
                              </li>

                            </ul>

                            <div v-if="state.active_tab =='uptime' && state.show_tab ">
                              <div class="row">

                                  <div class="col-12">

                                      <a class="pointer align-vertical-center" v-on:click="refreshUptimeLog()" ><i class="bi bi-arrow-repeat"></i> &nbsp;Refresh</a>

                                  </div>
                              </div>
                              <UptimeLogList ref="uptimeLogList" :schedule_id="state.formData.id" ></UptimeLogList>
                            </div>
                            <div v-if="state.active_tab =='history' || !state.show_tab ">
                              <div class="row">

                                  <div class="col-12">
                                      <a class="pointer align-vertical-center" v-on:click="refreshHistory()" ><i class="bi bi-arrow-repeat"></i> &nbsp;Refresh</a>
                                  </div>
                              </div>
                              <HistoryEmbbed ref="historyEmbbed" :schedule_id="state.formData.id" ></HistoryEmbbed>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">

                  <div class="card">
                      <div class="card-header pt-3">
                          <h4 class="inline-block mt-2" >Options</h4>
                          <p class="align-vertical-center float-end">
                            <label class="mt-2" >Enabled:</label> &nbsp;
                            <a class="d-flex pointer" v-on:click="toggleEnable()" >
                            <i class="bi bi-toggle-on enable-on large" v-if="state.formData.enabled" ></i>
                            <i class="bi bi-toggle-off enable-off large" v-else></i>
                            </a>
                            </p>
                      </div>
                      <div class="card-body mt-2">
                         <div v-if="Config.PASSIVE_SCHEDULES.indexOf(state.formData.category) == -1">
                         <p v-if="!state.formData.enabled">
                           This "{{Config.MAP_CATEGORY[state.formData.category]}}" monitor  is disabled, You can trigger to run by click "Run now" button</p>
                         <p v-else>This  "{{Config.MAP_CATEGORY[state.formData.category]}}" monitor will run on {{nextOccur}}
                           <strong class="text-muted" v-if="state.remain_time"> within {{state.remain_time}} </strong>.
                          You can trigger to run by click "Run now" button</p>
                         <button class="btn btn-block  btn btn-primary font-bold mt-3" :disabled="state.checking" v-on:click="runSchedule" >
                           <Spinner :show="state.checking" ></Spinner> Run now</button>
                        </div>
                        <div v-else>
                          <p v-if="!state.formData.enabled">This sheduled is disabled</p>
                          <p v-else>This schedule is passive and is supposed to receive data on {{nextOccur}}, remain time to expect get the data: <strong class="text-muted" v-if="state.remain_time"> {{state.remain_time}} </strong></p>
                          <div>

                            
                            <a class="btn btn-primary w-100" v-on:click="state.show_guide_disk_status = !state.show_guide_disk_status" >Guide to receive data</a>
                            <div v-if="state.show_guide_disk_status" style="  border: 1px solid #ddd; padding: 10px; margin: 10px 0px;border-radius: 10px;" >
                              
                                URL: <span class="text-muted">{{ Config.SERVER }}/disk-status/</span><br/>
                            Method: <span class="text-muted">POST</span><br/>
                            Sample body: <pre class="text-muted">
{
    "schedule_id": "{{ state.formData.id }}",
    "secret": "{{ state.formData.options.secret }}",
    "partitions": [
    {
        "name": "partion name a",
        "size": "77G",
        "used": "23G",
        "available": "50G",
        "used_percentage": 32
    },
    {
        "name": "partition name b",
        "size": "144G",
        "used": "118G",
        "available": "18G",
        "used_percentage": 87
    }
  ]
}
                            </pre>
                            <a class="btn btn-secondary btn-sm w-100" v-on:click="showDiskStatusPythonScript">Download Python script</a>
                            </div>
                          </div>
                        </div>
                        <hr/>
                        <button class="btn btn-block btn-sm  btn-outline-primary font-bold mt-3"  v-on:click="state.show_edit_modal = true" >
                            Edit</button>

                        <button class="btn btn-block btn-sm btn btn-outline-danger font-bold mt-3"  v-on:click="removeItem" >
                            Delete</button>

                        <div v-if="state.quick_start.related_schedules != null"  >
                          <hr>
                          <div v-if="state.quick_start.related_schedules.length > 0" class="mt-4">
                            <h6 class="text-subtitle text-muted" >Related monitors</h6>
                            <ul class="list">
                                <li v-for="schedule in state.quick_start.related_schedules" v-bind:key="schedule.id" >
                                    <router-link class="align-vertical-center" :to="{name: 'ViewSchedule',params: { id: schedule.id }}">
                                      <i class="bi bi-arrow-right-short"></i> {{schedule.name}}
                                    </router-link>
                                </li>
                            </ul>
                          </div>

                          <button  v-if="state.quick_start.show_create_http_check" class="btn btn-block btn-sm  btn-outline-primary"
                           :disabled="state.quick_start.working_create"
                            v-on:click="quickCreateSchedule(Config.HTTP)"  >
                            <Spinner :show="state.quick_start.working_create" ></Spinner> Create HTTP check for this domain</button>
                          <button v-if="state.quick_start.show_create_ssl_check" class="btn btn-block btn-sm  btn-outline-primary"
                           :disabled="state.quick_start.working_create"
                            v-on:click="quickCreateSchedule(Config.CHECK_SSL)" >
                            <Spinner :show="state.quick_start.working_create" ></Spinner> Create SSL check for this domain</button>

                        </div>

                      </div>
                  </div>
                </div>
            </div>

    </div>

    <EditScheduleModal v-if="state.show_edit_modal" :schedule="state.formData" v-on:close-form="state.show_edit_modal= false" v-on:refresh="refresh" ></EditScheduleModal>
  </MainLayout>
</template>

<script>
// import Utils from "@/helpers/Utils";
import { computed, defineComponent, reactive, onMounted, onUnmounted, watch, ref } from 'vue'
import Utils from '@/helpers/Utils'
// import { projectStore } from "@/stores/ProjectStore";
import MainLayout from '@/views/layouts/Main.vue'
import Loading from '@/views/components/Loading.vue'
// import ScheduleForm from "@/views/components/ScheduleForm.vue";
import Spinner from '@/views/components/Spinner.vue'
import { useRoute } from 'vue-router'
import Config from '@/helpers/Config'
import HistoryEmbbed from '@/views/components/HistoryEmbbed/HistoryEmbbed'
import UptimeLogList from '@/views/components/UptimeLogList/UptimeLogList'
import HTTPLoadTimeChart from '@/views/components/HTTPLoadTimeChart'
import DiskStatusChart from '@/views/components/DiskStatusChart'
import { scheduleListViewStore } from '@/stores/ScheduleListViewStore'
import { tagStore } from '@/stores/TagStore'
import Alert from '@/helpers/Alert'
import moment from 'moment'
import router from '@/router'
import EditScheduleModal from '@/views/components/EditScheduleModal.vue'
import Services from '@/helpers/Services'

export default defineComponent({
  page_title: () => {
    return 'View monitor'
  },
  name: 'NewSchedule',
  props: [],
  components: {
    MainLayout,
    EditScheduleModal,
    Loading,
    HistoryEmbbed,
    // ScheduleForm,
    Spinner,
    HTTPLoadTimeChart,
    DiskStatusChart,
    UptimeLogList
  },

  setup (props) {
    const route = useRoute()

    const currentLocation = computed(() => {
      const { ...rest } = route
      return rest
    })

    const nextOccur = computed(() => {
      if (!state.formData.next_occur) {
        return ''
      }
      return Utils.localReadableTime(state.formData.next_occur)
    })

    const pageTitle = computed(() => {
      return 'View monitor'
    })

    watch(
      () => route.path,
      async () => {
        if (['NewSchedule', 'ViewSchedule'].indexOf(currentLocation.value.name) !== -1) {
          state.schedule_id = currentLocation.value.params.id
          fetchSchedule()
        }
      }
    )

    const formData = {
      name: '',
      enabled: true,
      rrule: '',
      started: '',
      category: Config.CHECK_SSL,
      options: {}
    }

    const state = reactive({
      fetching: true,
      working: false,
      show_edit_modal: false,
      show_tab: false,
      active_tab: 'uptime',
      remain_time: '',
      interval: null,
      schedule: null,
      quick_start: {
        working_create: false,
        related_schedules: null,
        show_create_ssl_check: true,
        show_create_http_check: true
      },
      checking: false,
      schedule_id: currentLocation.value.params.id,
      formData: formData,
      initAction: currentLocation.value.params.action
    })

    const fetchSchedule = () => {
      state.fetching = true

      Utils.request()
        .get(`/schedule/${state.schedule_id}/`)
        .then((response) => {
          state.formData = response.data

          state.show_tab = Config.SHOW_UPTIME_LOG_CATEGORY.indexOf(state.formData.category) !== -1

          if ([Config.HTTP, Config.CHECK_SSL].indexOf(state.formData.category) !== -1) {
            fetchRelatedSchedules(response.data)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            router.push({
              name: 'Home'
            })
          }
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.fetching = false
          if (state.initAction === 'edit') {
            state.initAction = ''
            state.show_edit_modal = true
          }
        })
    }

    const fetchRelatedSchedules = (schedule) => {
      return new Promise((resolve, reject) => {
        Utils.request()
          .get('/schedule/?domain=' + schedule.options.domain)
          .then((response) => {
            state.quick_start.related_schedules = response.data.filter(item => item.id !== schedule.id) // Exclude current schedule
            response.data.forEach(item => {
              if (item.category === Config.HTTP) {
                state.quick_start.show_create_http_check = false
              } else if (item.category === Config.CHECK_SSL) {
                state.quick_start.show_create_ssl_check = false
              }
            })
            resolve()
          }).catch(() => {
            reject(new Error('Get data fail'))
          })
      })
    }

    onMounted(() => {
      if (state.schedule_id) {
        fetchSchedule()
      }

      state.interval = setInterval(() => {
        updateRemainTime()
      }, 1000)
    })

    onUnmounted(() => {
      if (state.interval) {
        clearInterval(state.interval)
      }
    })

    const updateRemainTime = () => {
      if (!state.formData || !state.formData.next_occur) {
        state.remain_time = ''
        return
      }

      const occurDate = new Date(state.formData.next_occur)
      if (occurDate < new Date()) {
        state.remain_time = ''
      } else {
        state.remain_time = moment(occurDate).fromNow()
      }
    }

    const runSchedule = () => {
      state.checking = true
      Utils.request()
        .get(`/run-schedule/${state.schedule_id}/`)
        .then(() => {
          Alert.showSuccessToast('Your schedule will be run soon')
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.checking = false
        })
    }

    const refreshHistory = () => {
      historyEmbbed.value.fetchList()
    }

    const refreshUptimeLog = () => {
      uptimeLogList.value.fetchList()
    }

    const quickCreateSchedule = (category) => {
      const formData = {
        id: state.formData.id,
        category: category
      }
      state.quick_start.working_create = true
      Utils.request().post('/quick-schedule-from-id/', formData).then(() => {
        refreshStore()
        fetchRelatedSchedules(state.formData).then(() => {
          state.quick_start.working_create = false
        })
      }).catch((error) => {
        Utils.showErrorMessage(error.response)
      })
    }

    const refreshStore = () => {
      return new Promise((resolve) => {
        Promise.all([tagStore.fetchList(true), scheduleListViewStore.fetchList()]).then((values) => {
          scheduleListViewStore.setList(values[1])
          resolve()
        })
      })
    }

    const historyEmbbed = ref(null)
    const uptimeLogList = ref(null)

    const refresh = (data) => {
      state.formData = data
    }

    const toggleEnable = () => {
      const temp = Utils.clone(state.formData)
      temp.enabled = !temp.enabled
      Services.updateSchedule(temp).then((schedule) => {
        state.formData = schedule
      })
    }

    const removeItem = () => {
      Services.removeSchedule(state.formData).then(() => {
        // refresh schedule lists
        refreshStore().then(() => {
          router.push({
            name: 'Home'

          })
        })
      })
    }
    const downloadFile =(filename, text) => {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }

    const showDiskStatusPythonScript = () =>{
      Utils.request().get('/disk-status-script/?schedule_id=' + state.schedule_id).then((response) => {
        downloadFile("disk_status_script.py", response.data)
      })
    }

    return {
      props,
      state,
      pageTitle,
      runSchedule,
      refreshHistory,
      refreshUptimeLog,
      historyEmbbed,
      uptimeLogList,
      nextOccur,
      quickCreateSchedule,
      Config,
      // remainTime,
      refresh,
      toggleEnable,
      removeItem,
      showDiskStatusPythonScript
    }
  }
})
</script>
