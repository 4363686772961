/* eslint-disable */
import swal from 'sweetalert'
import Toastify from 'toastify-js'  /* eslint-disable */
function myAlert(...args: any): Promise<any>|void {
  if(args){
    return swal(...args)
  }
  
}

function myErrorAlert(title: string, body: any): Promise<any>|void {
  return myAlert(title, body, 'error')
}

function confirm(content: string, title: string): Promise<any> {
  return swal({
    title: title,
    text: content,
    icon: 'warning',
    buttons: ['No', 'Yes']
    // dangerMode: true,
  })
}

function loading(title: string): void {
  swal({
    title: title,
    text: 'Please wait',
    icon: '/images/loading.svg',
    buttons: [false],
    closeOnClickOutside: false,
    closeOnEsc: false
  })
}

function close(): void {
  if (swal && swal.close){
    swal.close()
  }
  
}

function inputText(title: string, default_value = '', type = 'text'): Promise<any> {
  return swal(title, {
    content: {
      element: 'input',
      attributes: {
        placeholder: '',
        type: type,
        value: default_value
      }
    }
  })
}

function success(title: string, body: string): Promise<any> {
  return swal(title, body, 'success')
}

function showSuccessToast(body: string): void {
  Toastify({
    text: body,
    duration: 3000,
    close: true,
    gravity: 'top',
    position: 'center',
    backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)'
  }).showToast()
}

function showErrorToast(body: string):void {
  Toastify({
    text: body,
    duration: 3000,
    close: true,
    gravity: 'top',
    position: 'center',
    backgroundColor: 'linear-gradient(to right, #ff5f6d, #ffc371)'
  }).showToast()
}

export default {
  alert: myAlert,
  error: myErrorAlert,
  confirm: confirm,
  loading: loading,
  close: close,
  inputText: inputText,
  success: success,
  showSuccessToast,
  showErrorToast
}
/* eslint-disable-line */