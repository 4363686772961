<template>
  <form v-on:submit.prevent="submitForm">
    <section class="section">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <h6>General</h6>
            <div class="row">
              <div class="col-12 mb-3">
                <label>Name</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="state.formData.name"
                  placeholder="Name"
                />
              </div>
              <div class="col-12 mb-3">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  required
                  :disabled="state.formData.id"
                  v-model="state.formData.email"
                  placeholder="Email"
                />
              </div>
              <div class="col-12 mb-3">
                <div class="d-flex align-vertical-center">
                  <a
                    class="d-flex pointer"
                    v-on:click="
                      state.formData.enabled = !state.formData.enabled
                    "
                  >
                    <i
                      class="bi bi-toggle-on large enable-on"
                      v-if="state.formData.enabled"
                    ></i>
                    <i class="bi bi-toggle-off large enable-off" v-else></i>
                  </a>
                  &nbsp;&nbsp;
                  <label for="enableSchedule"> Enabled </label>
                </div>

                <div v-if="state.formData.enabled" class="mt-4 alert alert-secondary" >
                  <h6>Detail config notification</h6>
                  <div class="d-flex align-vertical-center">
                    <a
                      class="d-flex pointer"
                      v-on:click="
                        state.formData.notify_via_email = !state.formData.notify_via_email
                      "
                    >
                      <i
                        class="bi bi-toggle-on large enable-on"
                        v-if="state.formData.notify_via_email"
                      ></i>
                      <i class="bi bi-toggle-off large enable-off" v-else></i>
                    </a>
                    &nbsp;&nbsp;
                    <label for="enableSchedule"> Notification via email </label>
                  </div>

                    <div class="mt-2">
                      <div class="d-flex align-vertical-center">
                        <a
                          class="d-flex pointer"
                          v-on:click="state.formData.notify_via_telegram = !state.formData.notify_via_telegram"
                        >
                          <i
                            class="bi bi-toggle-on large enable-on"
                            v-if="state.formData.notify_via_telegram"
                          ></i>
                          <i class="bi bi-toggle-off large enable-off" v-else></i>
                        </a>
                        &nbsp;&nbsp;
                        <label for="enableSchedule"> Notification via telegram </label>
                      </div>
                    </div>
                    <div class="mt-2" v-if="state.formData.notify_via_telegram" >
                      <div class="form-inline" >
                        <label class="mT-15 mb-1">Select channel:</label>

                          <select
                            class="form-select"
                            required
                            v-model="state.formData.telegram_group_id"
                            >
                            <option value="null" disabled>Please select</option>
                            <option
                                v-for="telegram_group in state.telegramGroups"
                                :key="telegram_group.id"
                                v-bind:value="telegram_group.id"
                                >
                                {{ telegram_group.name }}
                            </option>
                          </select>
                    </div>
                  </div>

                </div>

              </div>

              <h6 class="mt-3 mb-3">Config alert</h6>

              <div class="col-12">
                <div class="form-check">
                  <div class="checkbox">
                    <input
                      id="receiveAllAlert"
                      type="checkbox"
                      v-model="state.formData.receive_all_alerts"
                      class="form-check-input"
                    />
                    <label for="receiveAllAlert">Receive all alerts</label>
                  </div>
                </div>
              </div>
              <div v-if="!state.formData.receive_all_alerts">
                <div class="mt-3 mb-3">
                  <label>Tags</label>
                  <SelectTag ref="selectTags" :init_tags="state.initTags" />
                </div>

                <div class="mt-3 mb-3">
                  <label>Monitors</label>
                  <SelectScheduleTag
                    ref="selectSchedules"
                    :placeholder="'Select monitors'"
                    :init_tags="state.initSchedules"
                  />
                </div>
              </div>

              <div class="col-12 mt-2">
                <button
                  type="submit"
                  class="btn btn-primary me-1 mb-1"
                  :disabled="state.working"
                >
                  <Spinner :show="state.working"></Spinner>
                  Save
                </button>
                <a
                  v-if="state.formData.id"
                  v-on:click="removeItem"
                  class="btn btn-danger me-1 mb-1"
                  >Delete</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</template>

<script lang="ts">
import Config from '@/helpers/Config'
import Utils from '@/helpers/Utils'
import Alert from '@/helpers/Alert'
import router from '@/router'
import Services from '@/helpers/Services'
import SelectTag from '@/views/components/SelectTag.vue'
import SelectScheduleTag from '@/views/components/SelectScheduleTag.vue'
import { reactive, defineComponent, onMounted, ref, PropType } from 'vue'
import { Contact, Tag, Schedule, Indexed, TelegramGroup } from '@/models/interfaces'

export default defineComponent({
  name: 'ContactForm',
  components: {
    SelectTag,
    SelectScheduleTag
  },
  emits: ['refresh'],
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true
    }
  },
  setup (props, { emit }) {
    interface State{
      working: boolean,
      formData: Contact,
      initTags: string[],
      initSchedules: string[],
      telegramGroups: TelegramGroup[],
    }

    const state = reactive<State>({
      working: false,
      formData: Utils.clone(props.contact),
      initTags: [],
      initSchedules: [],
      telegramGroups: []
    })

    if (props.contact) {
      if (props.contact.tags) {
        state.initTags = props.contact.tags.map((item:Tag) => {
          return item.name
        })
      }
      if (props.contact.schedules) {
        state.initSchedules = props.contact.schedules.map((item:Schedule) => {
          return item.id
        })
      }
    }

    const fetchTelegramGroup = () => {
      console.log('load group')
      Utils.request()
        .get('/telegram-group/')
        .then((response) => {
          state.telegramGroups = response.data
          console.log('state.telegramGroups', state.telegramGroups)
        })
    }

    onMounted(() => {
      console.log('formData', state.formData)

      fetchTelegramGroup()
    })

    const removeItem = () => {
      Services.removeContact(state.formData).then(() => {
        // fetchList();
        router.push({
          name: 'Contacts'
        })
      })
    }

    const submitForm = async () => {
      if (state.working) {
        return
      }

      let tags = null
      let schedules = null

      if (!state.formData.receive_all_alerts) {
        tags = selectTags.value.state.value.map((item:string) => {
          return {
            name: item
          }
        })
        schedules = selectSchedules.value.state.value.map((item:string) => {
          return {
            id: item
          }
        })
      }

      const formData: Indexed = {
        name: state.formData.name,
        enabled: state.formData.enabled,
        receive_all_alerts: state.formData.receive_all_alerts,
        notify_via_telegram: state.formData.notify_via_telegram,
        notify_via_email: state.formData.notify_via_email,
        telegram_group_id: state.formData.telegram_group_id
      }

      if (tags != null) {
        formData.tags = tags
      }
      if (schedules != null) {
        formData.schedules = schedules
      }

      if (props.contact.id) {
        formData.id = props.contact.id
      } else {
        formData.email = state.formData.email
      }

      const url = formData.id ? `/contact/${formData.id}/` : '/contact/'
      const method = formData.id ? 'put' : 'post'
      state.working = true

      Utils.request()[method](url, formData)
        .then((response) => {
          emit('refresh', response.data)
          if (method === 'post') {
            Alert.showSuccessToast('Your contact have been added')
            router.push({
              name: 'EditContact',
              params: {
                id: response.data.id
              }
            })
          } else {
            Alert.showSuccessToast('Your contact have been updated')
          }
        })
        .catch((error) => {
          Utils.showErrorMessage(error.response)
        })
        .finally(() => {
          state.working = false
        })
    }

    const selectTags = ref()
    const selectSchedules = ref()

    return {
      state,
      props,
      Config,
      submitForm,
      removeItem,
      selectTags,
      selectSchedules
    }
  }
})
</script>
