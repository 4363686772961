<template>
    <div class="mt-3">
      <p>Any partition usage reachs over percentage:</p>
      <input type="number" min="1" max="100" class="form-control" v-model="state.over_usage_percentage" />
    </div>
</template>

<script>
// This is for Daily, Hourly and Minutely
import { defineComponent, reactive, computed } from 'vue'
import Config from '@/helpers/Config'
export default defineComponent({
  name: 'DiskStatusAlertConfig',
  props: {
    data: Object
  },
  setup (props) {
    const state = reactive({
      over_usage_percentage: props.data.over_usage_percentage,
    })

    const getValue = () => {
      return {
        over_usage_percentage: state.over_usage_percentage
      }
    }

    return { props, state, Config, getValue }
  }
})
</script>
