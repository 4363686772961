<template>
  <Multiselect
    v-model="state.value"
    :create-tag="true"
    :placeholder="props.placeholder || 'Select or input new tag'"
    :searchable="true"
    mode="tags"
    :options="state.options"
  />
</template>

<script>

import { reactive, onMounted } from 'vue'
// import Utils from '@/helpers/Utils';
import Config from '@/helpers/Config'
import Multiselect from '@vueform/multiselect'
import Utils from '@/helpers/Utils'
import { tagStore } from '@/stores/TagStore'
export default {
  name: 'SelectScheduleTag',
  components: {
    Multiselect
  },
  props: {
    init_tags: Array,
    placeholder: String,
    options: Array
  },
  setup (props) {
    const state = reactive({
      fetching: false,
      value: Utils.clone(props.init_tags),
      options: [
      ]

    })

    onMounted(() => {
      if (props.options == null) {
        // fetchTags();
        tagStore.fetchList().then((response) => {
          state.options = response.map(item => { return item.name })
        })
      } else {
        state.options = props.options
      }
    })

    return {
      state,
      Config,
      props
    }
  }
}
</script>
